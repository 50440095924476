import React, { useContext, FunctionComponent, useState, useRef, Fragment } from "react";
import { injectIntl } from "react-intl";
import { StoresContext } from "index";
import useHeaderStyles from "./HeaderStyles";
import {
  Container,
  IconButton,
  Link,
  Menu,
  MenuList,
  MenuItem,
  Box,
  Popper,
  Grow,
  ClickAwayListener,
  Paper,
  AppBar,
  Toolbar,
  Typography,
  Icon,
  withStyles,
  TextField,
  Button,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LanguageIcon from "@mui/icons-material/Language";
import {
  ROUTE_BASE,
  ROUTE_DATALAYERS,
  ROUTE_DATAGROUPS,
  ROUTE_IMAGELAYERS,
  ROUTE_DATACSV,
  ROUTE_PUBLICATIONS,
  ROUTE_BASELAYERS,
  ROUTE_WFSWMS,
  ROUTE_FILEMANAGER,
} from "routes/RouteList";
import { observer as hooksObserver } from "mobx-react-lite";
import NotificationView from "./NotificationView";
import { useHistory } from "react-router";
import CustomDialogView from "views/CustomDialog/CustomDialogView";
import { setDbPassword } from "services/geo-core";
import { APP_LOCALES } from "../../constants";
import messages from "./messages";
import { ColumbaLogo } from "@orbit/components";

interface Props {
  classes: any;
  intl: any;
}

const HeaderView: FunctionComponent<Props> = hooksObserver(({ classes, intl: { formatMessage } }) => {
  const {
    authStore: { doLogout, user },
    uiStore: { setLocale, locale },
  } = useContext(StoresContext);

  let { push } = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openLangMenu, setOpenLangMenu] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseLangMenu = () => {
    setOpenLangMenu(null);
  };
  const handleOpenLangMenu = (event) => {
    setOpenLangMenu(event.currentTarget);
  };
  const handleLocaleChange = (lang: string) => {
    setLocale(lang);
    handleCloseLangMenu();
  };
  const [geodataOpen, setGeodataOpen] = useState(false);
  const [afbeeldingenOpen, setAfbeeldingenOpen] = useState(false);
  const [passwordPopupOpen, setPasswordPopupOpen] = useState(false);
  const [dbpassword, setDbpassword] = useState("");
  const geodataRef = useRef<HTMLButtonElement>(null);
  const afbeeldingenRef = useRef<HTMLButtonElement>(null);

  const saveDbpassword = async (password: string) => {
    await setDbPassword(password);
  };

  const handleGeodataToggle = () => {
    setGeodataOpen((prevOpen) => !prevOpen);
  };

  const handleAfbeeldingenToggle = () => {
    setAfbeeldingenOpen((prevOpen) => !prevOpen);
  };

  const handleGeodataClose = (event: React.MouseEvent<EventTarget>) => {
    if (geodataRef.current && geodataRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setGeodataOpen(false);
  };

  const handleAfbeeldingenClose = (event: React.MouseEvent<EventTarget>) => {
    if (afbeeldingenRef.current && afbeeldingenRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setAfbeeldingenOpen(false);
  };

  function handleGeodataListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setGeodataOpen(false);
    }
  }

  function handleAfbeeldingenListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setAfbeeldingenOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevGeodataOpen = useRef(geodataOpen);
  React.useEffect(() => {
    if (prevGeodataOpen.current === true && geodataOpen === false) {
      geodataRef.current!.focus();
    }

    prevGeodataOpen.current = geodataOpen;
  }, [geodataOpen]);

  const prevAfbeeldingenOpen = useRef(afbeeldingenOpen);
  React.useEffect(() => {
    if (prevAfbeeldingenOpen.current === true && afbeeldingenOpen === false) {
      afbeeldingenRef.current!.focus();
    }

    prevAfbeeldingenOpen.current = afbeeldingenOpen;
  }, [afbeeldingenOpen]);
  return (
    <>
      <AppBar className={classes.appBar} elevation={2} position="fixed">
        <Container maxWidth="xl">
          <Toolbar className={classes.toolBar}>
            <Typography
              className={classes.title}
              variant="h6"
              color="inherit"
              noWrap
              onClick={() => push(ROUTE_BASE)}
              style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
            >
              <ColumbaLogo style={{ height: 30, width: 30, marginRight: 10 }} color="#FFFFFF" /> <span>{formatMessage(messages.headerTitle)}</span>
            </Typography>
            <Box className={classes.grow} />
            <Link
              ref={geodataRef}
              aria-controls={geodataOpen ? "menu-list-grow" : undefined}
              className={classes.headerLink}
              aria-haspopup="true"
              onClick={handleGeodataToggle}
              underline="none"
              variant="button"
            >
              {formatMessage(messages.headerGeodata)}
            </Link>
            <Popper open={geodataOpen} anchorEl={geodataRef.current} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleGeodataClose}>
                      <MenuList autoFocusItem={geodataOpen} id="menu-list-grow" onKeyDown={handleGeodataListKeyDown}>
                        <MenuItem
                          onClick={(event: React.MouseEvent<EventTarget>) => {
                            push(ROUTE_DATALAYERS);
                            handleGeodataClose(event);
                          }}
                        >
                          {formatMessage(messages.headerDatalayers)}
                        </MenuItem>
                        <MenuItem
                          onClick={(event: React.MouseEvent<EventTarget>) => {
                            push(ROUTE_FILEMANAGER);
                            handleGeodataClose(event);
                          }}
                        >
                          {formatMessage(messages.headerFilemanagertitle)}
                        </MenuItem>
                        <MenuItem
                          onClick={(event: React.MouseEvent<EventTarget>) => {
                            push(ROUTE_DATACSV);
                            handleGeodataClose(event);
                          }}
                        >
                          {formatMessage(messages.headerTableData)}
                        </MenuItem>
                        <MenuItem
                          onClick={(event: React.MouseEvent<EventTarget>) => {
                            push(ROUTE_DATAGROUPS);
                            handleGeodataClose(event);
                          }}
                        >
                          {formatMessage(messages.headerDatagroups)}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Link
              ref={afbeeldingenRef}
              aria-controls={afbeeldingenOpen ? "menu-list-grow" : undefined}
              className={classes.headerLink}
              aria-haspopup="true"
              onClick={handleAfbeeldingenToggle}
              underline="none"
              variant="button"
            >
              {formatMessage(messages.headerImagesAndWMFWFS)}
            </Link>
            <Popper open={afbeeldingenOpen} anchorEl={afbeeldingenRef.current} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleAfbeeldingenClose}>
                      <MenuList autoFocusItem={afbeeldingenOpen} id="menu-list-grow" onKeyDown={handleAfbeeldingenListKeyDown}>
                        <MenuItem
                          onClick={(event: React.MouseEvent<EventTarget>) => {
                            push(ROUTE_IMAGELAYERS);
                            handleAfbeeldingenClose(event);
                          }}
                        >
                          {formatMessage(messages.headerImages)}
                        </MenuItem>
                        <MenuItem
                          onClick={(event: React.MouseEvent<EventTarget>) => {
                            push(ROUTE_WFSWMS);
                            handleAfbeeldingenClose(event);
                          }}
                        >
                          {formatMessage(messages.headerWMSWFS)}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Link onClick={() => push(ROUTE_BASELAYERS)} className={classes.headerLink} underline="none" variant="button">
              {formatMessage(messages.headerBaselayers)}
            </Link>
            <Link onClick={() => push(ROUTE_PUBLICATIONS)} className={classes.headerLink} underline="none" variant="button">
              {formatMessage(messages.headerPublications)}
            </Link>

            <Box className={classes.grow} />
            <NotificationView />
            <IconButton color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={classes.notificationButton}>
              <AccountCircleIcon />
            </IconButton>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              getContentAnchorEl={null}
            >
              <MenuItem style={{ fontSize: 13 }} disabled>
                {`${user.firstName} ${user.lastName}`}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  doLogout();
                }}
              >
                {formatMessage(messages.headerLogout)}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setPasswordPopupOpen(true);
                }}
              >
                {formatMessage(messages.headerChangeDBPassword)}
              </MenuItem>
            </Menu>
            <IconButton color="primary" aria-controls="lang-menu" aria-haspopup="true" onClick={handleOpenLangMenu} className={classes.notificationButton}>
              <Typography variant="button" style={{ marginRight: "6px", fontWeight: "bold" }}>
                {locale}
              </Typography>
              <LanguageIcon />
            </IconButton>
            <Menu
              id="lang-menu"
              anchorEl={openLangMenu}
              keepMounted
              open={Boolean(openLangMenu)}
              onClose={handleCloseLangMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              getContentAnchorEl={null}
            >
              {Object.values(APP_LOCALES)
                .filter((lang) => lang !== locale)
                .map((lang) => (
                  <MenuItem key={lang} onClick={() => handleLocaleChange(lang)}>
                    {lang.toUpperCase()}
                  </MenuItem>
                ))}
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>
      <CustomDialogView
        open={passwordPopupOpen}
        handleClose={() => {
          setPasswordPopupOpen(false);
        }}
        maxWidth={"sm"}
        dialogTitle={formatMessage(messages.headerChangeDatabasePassword)}
        dialogContent={
          <Fragment>
            <form>
              <TextField
                className={classes.marginBottom}
                autoComplete="off"
                required
                type="password"
                label={formatMessage(messages.headerNewDatabasePassword)}
                value={dbpassword}
                onChange={(e) => setDbpassword(e.target.value)}
                fullWidth
              />
            </form>
          </Fragment>
        }
        dialogActions={
          <Fragment>
            <Button
              id="submit"
              onClick={() => {
                setPasswordPopupOpen(false);
              }}
            >
              {formatMessage(messages.headerCancel)}
            </Button>
            <Button
              id="submit"
              color="primary"
              variant="contained"
              disabled={dbpassword?.length === 0}
              onClick={() => {
                setPasswordPopupOpen(false);
                saveDbpassword(dbpassword);
                setDbpassword("");
              }}
              disableElevation
            >
              {formatMessage(messages.headerSave)}
            </Button>
          </Fragment>
        }
      />
    </>
  );
});

export default withStyles(useHeaderStyles)(injectIntl(HeaderView));
