import { createMuiTheme } from "@material-ui/core/styles";

let light = createMuiTheme({
  palette: {
    primary: {
      main: "#386FA4",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#00ACC1",
    },
    icon: {
      main: "#000",
      sec: "#00ACC1",
    },
  },
  typography: {
    useNextVariants: true,
  },
});

let dark = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#607d8b",
    },
    secondary: {
      main: "#00ACC1",
    },
    icon: {
      main: "#FFF",
      sec: "#00ACC1",
    },
  },
  typography: {
    useNextVariants: true,
  },
});

export { light, dark };
