import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import ReorderIcon from "@material-ui/icons/Reorder";
import LinkIcon from "@material-ui/icons/Link";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, Droppable, DroppableProvided, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { StoresContext } from "../../../../index";
import { InjectedIntl, injectIntl } from "react-intl";
import messages from "./messages";
import { useDebouncedCallback } from "use-debounce";
import { SearchProperty } from "@orbit/geo-core-shared";
import { transferLayerIdRegex } from "../../../../utils/searchfields.utils";

export const ConfigurationTable = observer(({ index, intl: { formatMessage } }: { index: string; intl: InjectedIntl }) => {
  const {
    publicationStore: { activePublication, reorderSearchPropertyItem, updateSearchPropertyItem },
  } = useContext(StoresContext);

  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [activeItem, setActiveItem] = useState<SearchProperty>();
  const [validated, setValidated] = useState(false);
  const isWfsLayer = index.match(transferLayerIdRegex);

  const handleClickOpen = (item) => {
    setActiveItem(item);
    setUrl(item.link || "");
    setOpen(true);
  };

  const handleDeleteClickOpen = () => {
    setOpen(false);
    setDeleteOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleSave = () => {
    if (!activeItem) return;
    updateSearchPropertyItem(activeItem, "link", url);
    setOpen(false);
    setUrl("");
  };

  const handleDelete = () => {
    if (!activeItem) return;
    updateSearchPropertyItem(activeItem, "link", "");
    setDeleteOpen(false);
    setUrl("");
  };

  function checkUrl(url) {
    const regex = /^(https?:\/\/){1}.*([^[]\[[^\[\]]+\][^\[\]]*)+$/g;
    if (!regex.test(url)) return false;
    // add extra checks that vailidate if the parts within the brackets are valid keys
    const regexBrackets = /\[(.*?)\]/g;
    const matches = url.match(regexBrackets);
    if (matches) {
      let ok = true;
      matches.forEach((match) => {
        const key = match.replace("[", "").replace("]", "");
        // if key doesnt match return false
        if (key === "geom") ok = false;
        if (key !== "key" && !activePublication?.searchFields[index]?.items.find((item) => item.key === key)) {
          ok = false;
        }
      });
      return ok;
    }
    return false;
  }

  useEffect(() => {
    if (activeItem && url !== "") {
      setValidated(false);
      if (checkUrl(url)) {
        setValidated(true);
      }
    } else {
      setValidated(false);
    }
  }, [activeItem, url]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{formatMessage(messages.searchField)}</TableCell>
              <TableCell align="center">{formatMessage(messages.searchAlias)}</TableCell>
              <TableCell align="center">{formatMessage(messages.searchHyperlink)}</TableCell>
              {!isWfsLayer && <TableCell align="right">{formatMessage(messages.searchSearchInField)}</TableCell>}
              <TableCell align="right">{formatMessage(messages.searchDisplayInResult)}</TableCell>
              <TableCell align="right">{formatMessage(messages.searchDisplayInPopup)}</TableCell>
            </TableRow>
          </TableHead>
          <DragDropContext
            onDragEnd={(result: DropResult, provided?: ResponderProvided) => {
              // console.log(result);
              if (!result.destination) {
                return;
              }
              // console.log(result.destination.index);
              // console.log(result.source.index);
              if (result.destination.index === result.source.index) {
                return;
              }
              // console.log(activePublication?.searchFields);
              // console.log(activePublication?.searchFields[index]);
              if (!activePublication?.searchFields[index]?.items) {
                return;
              }

              reorderSearchPropertyItem(result.source.index, result.destination.index, result.source.type);
            }}
          >
            <Droppable droppableId="droppable" direction="vertical">
              {(droppableProvided: DroppableProvided) => (
                <TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                  <SelectionTableRow key={index} index={index} isWfsLayer={isWfsLayer} />
                  <TabelRows isWfsLayer={isWfsLayer} btnCallback={(item) => handleClickOpen(item)} btnDeleteCallback={(item) => handleDeleteClickOpen(item)} />
                  {droppableProvided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose} aria-labelledby="link-dialog">
        <DialogTitle id="link-dialog">{formatMessage(messages.linkEditTitle)}</DialogTitle>
        <DialogContent>
          <DialogContentText>{formatMessage(messages.linkEditMessage)}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="url"
            label="Url"
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            fullWidth
            placeholder={`https://www.url.be/[${activeItem?.key}]/[...]`}
          />
        </DialogContent>
        <DialogActions style={{ padding: "16px 24px" }}>
          {activeItem?.link && (
            <>
              <Button variant="outlined" onClick={handleDeleteClickOpen} color="primary" style={{ color: "red", borderColor: "red" }}>
                {formatMessage(messages.linkEditDeleteButton)}
              </Button>
              <div style={{ flexGrow: 1 }}></div>
            </>
          )}
          <Button onClick={handleClose} color="primary">
            {formatMessage(messages.linkEditCancelButton)}
          </Button>
          <Button onClick={handleSave} color="primary" disabled={!validated}>
            {formatMessage(messages.linkEditSaveButton)}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteOpen} onClose={handleDeleteClose} aria-labelledby="dialog-delete">
        <DialogTitle id="dialog-delete">{formatMessage(messages.linkDeleteTitle)}</DialogTitle>
        <DialogContent>
          <DialogContentText>{formatMessage(messages.linkDeleteMessage)}</DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "16px 24px" }}>
          <Button onClick={handleDeleteClose} color="primary">
            {formatMessage(messages.linkDeleteCancelButton)}
          </Button>
          <Button variant="contained" onClick={handleDelete} color="primary" style={{ backgroundColor: "red" }}>
            {formatMessage(messages.linkDeleteButton)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

injectIntl(ConfigurationTable);

export const TabelRows = observer(({ btnCallback, btnDeleteCallback, isWfsLayer }: { btnCallback: (item) => void; btnDeleteCallback: (item) => void; isWfsLayer }) => {
  const {
    publicationStore: { activePublication, activeSearchField },
  } = useContext(StoresContext);

  if (!activeSearchField) {
    return null;
  }

  return activeSearchField.items.map((item, index) => (
    <Draggable key={item.key} draggableId={item.key.toString()} index={index}>
      {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
        return (
          <TableRow
            key={item.key}
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            style={{
              ...draggableProvided.draggableProps.style,
              width: snapshot.isDragging ? 800 : null,
              background: snapshot.isDragging ? "rgba(245,245,245, 0.75)" : "none",
            }}
          >
            <TableCells
              isWfsLayer={isWfsLayer}
              draggableProvided={draggableProvided}
              item={item}
              btnCallback={(item) => btnCallback(item)}
              btnDeleteCallback={(item) => btnDeleteCallback(item)}
            />
          </TableRow>
        );
      }}
    </Draggable>
  ));
});

const TableCells = observer(
  ({
    draggableProvided,
    item,
    btnCallback,
    btnDeleteCallback,
    isWfsLayer,
  }: { isWfsLayer: boolean; draggableProvided: DraggableProvided; item: any; btnCallback: (item) => void; btnDeleteCallback: (item) => void }) => {
    const {
      publicationStore: { toggleSearchPropertyItem, updateSearchPropertyItem },
    } = useContext(StoresContext);

    const [alias, setAlias] = useState(item.label || "");
    const debouncedSave = useDebouncedCallback(
      async (item, key, value) => {
        updateSearchPropertyItem(item, key, value);
      },
      500,
      { leading: false },
    );

    return (
      <>
        <TableCell align="right" style={{ width: 50 }}>
          <div {...draggableProvided.dragHandleProps}>
            <ReorderIcon />
          </div>
        </TableCell>
        <TableCell align="left">{item.key}</TableCell>
        <TableCell align="right">
          <TextField
            fullWidth
            InputProps={{ style: { fontSize: 13, width: 120 } }}
            onChange={(e) => {
              setAlias(e.target.value);
              debouncedSave(item, "label", e.target.value);
            }}
            value={alias}
          />
        </TableCell>
        <TableCell align="center">
          {item.key !== "geom" && (
            <Button
              style={item.link ? { backgroundColor: "green", color: "white" } : { backgroundColor: "white" }}
              variant="contained"
              size="small"
              startIcon={<LinkIcon />}
              endIcon={item.link ? <EditIcon /> : <AddIcon />}
              onClick={() => btnCallback(item)}
            />
          )}
        </TableCell>
        {!isWfsLayer && (
          <TableCell align="right">
            <Checkbox edge="end" onChange={() => toggleSearchPropertyItem(item, "search")} checked={item.search} />
          </TableCell>
        )}
        <TableCell align="right">
          <Checkbox edge="end" onChange={() => toggleSearchPropertyItem(item, "result")} checked={item.result} />
        </TableCell>
        <TableCell align="right">
          <Checkbox edge="end" onChange={() => toggleSearchPropertyItem(item, "popup")} checked={item.popup || false} />
        </TableCell>
      </>
    );
  },
);

const SelectionTableRow = injectIntl(
  observer(({ isWfsLayer, index, intl: { formatMessage } }: { isWfsLayer: boolean; index: string; intl: InjectedIntl }) => {
    const {
      publicationStore: { toggleAllSearchFieldsForIndex, toggleAllResultFieldsForIndex, allSearchFieldsSelected, allResultFieldsSelected },
    } = useContext(StoresContext);

    return (
      <TableRow>
        <TableCell align="right"></TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right"></TableCell>
        {!isWfsLayer && (
          <TableCell align="right">
            <FormControlLabel
              control={
                <Switch
                  checked={allSearchFieldsSelected}
                  onClick={(event) => {
                    toggleAllSearchFieldsForIndex(index, event.target.checked);
                  }}
                  name="checkedA"
                />
              }
              label={formatMessage(messages.searchSelectAll)}
              labelPlacement="start"
            />
          </TableCell>
        )}
        <TableCell align="right">
          <FormControlLabel
            control={<Switch checked={allResultFieldsSelected} onClick={(event) => toggleAllResultFieldsForIndex(index, event.target.checked)} name="checkedA" />}
            label={formatMessage(messages.searchSelectAll)}
            labelPlacement="start"
          />
        </TableCell>
        <TableCell align="right"></TableCell>
      </TableRow>
    );
  }),
);
