import { Container } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/styles";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useContext, useEffect } from "react";
import { Route, Switch } from "react-router";
import {
  ROUTE_BASE,
  ROUTE_DATACSV,
  ROUTE_DATAGROUPS,
  ROUTE_DATAGROUP_DETAIL,
  ROUTE_DATAGROUP_STYLE,
  ROUTE_DATALAYERS,
  ROUTE_DATALAYER_DETAIL,
  ROUTE_DATALAYER_DETAIL_UPLOAD,
  ROUTE_DATALAYER_STYLE,
  ROUTE_DATALAYER_TABLE_DETAIL,
  ROUTE_IMAGELAYERS,
  ROUTE_PUBLICATIONS,
  ROUTE_PUBLICATION_DETAIL,
  ROUTE_BASELAYERS,
  ROUTE_BASELAYER_DETAIL,
  ROUTE_WFSWMS,
  ROUTE_WFSWMS_DETAIL,
  ROUTE_FILEMANAGER,
} from "routes/RouteList";
import HeaderView from "views/Header/HeaderView";
import HeaderViewMaputnik from "views/Header/HeaderViewMaputnik";
import DatagroupDetail from "../partials/datagroup/DatagroupDetail";
import Datagroups from "../partials/datagroup/Datagroups";
import DatalayerDetail from "../partials/datalayer/DatalayerDetail";
import DatalayerUpload from "../partials/datalayer/DatalayerUpload";
import DatalayerDetailTable from "../partials/datalayer/DatalayerDetailTable";
import FileManager from "../partials/files/FileManager";
import Datalayers from "../partials/datalayer/Datalayers";
import { DataLayerType } from "../../stores/DataLayerStore";
import DataTablelayers from "../partials/datalayer/DataTablelayers";
import Publications from "../partials/publication/Publications";
import PublicationDetail from "../partials/publication/PublicationDetail";
import BaseLayers from "../partials/baselayer/BaseLayers";
import BaseLayerDetail from "../partials/baselayer/BaseLayerDetail";
import TransferLayers from "../partials/transferlayer/TransferLayers";
import TransferLayerDetail from "../partials/transferlayer/TransferLayerDetail";
import DatalayerMaputnik from "../partials/datalayer/DatalayerMaputnik";
import DatagroupMaputnik from "../partials/datagroup/DatagroupMaputnik";
import useBasePageStyles from "./BasePageStyles";
import { MapContext } from "react-map-gl";
import { InjectedIntl } from "react-intl";
interface Props {
  classes: any;
  intl: InjectedIntl;
}

const BasePage: FunctionComponent<Props> = observer(({ classes, intl }) => {
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Switch>
        <Route
          exact
          path={[
            ROUTE_BASE,
            ROUTE_DATALAYERS,
            ROUTE_FILEMANAGER,
            ROUTE_DATACSV,
            ROUTE_IMAGELAYERS,
            ROUTE_DATALAYER_DETAIL,
            ROUTE_DATALAYER_TABLE_DETAIL,
            ROUTE_DATAGROUPS,
            ROUTE_DATAGROUP_DETAIL,
            ROUTE_DATALAYER_DETAIL_UPLOAD,
            ROUTE_PUBLICATIONS,
            ROUTE_PUBLICATION_DETAIL,
            ROUTE_BASELAYERS,
            ROUTE_BASELAYER_DETAIL,
            ROUTE_WFSWMS,
            ROUTE_WFSWMS_DETAIL,
          ]}
        >
          <Container maxWidth="xl">
            <HeaderView />
            <div className={classes.drawerHeader} style={{ marginBottom: 25 }} />
            <div style={{ height: "100%" }}>
              <Switch>
                <Route exact path={[ROUTE_BASE, ROUTE_DATALAYERS]}>
                  <Datalayers type={DataLayerType.VECTOR} />
                </Route>
                <Route exact path={[ROUTE_FILEMANAGER]}>
                  <FileManager />
                </Route>
                <Route exact path={[ROUTE_DATACSV]}>
                  <DataTablelayers />
                </Route>
                <Route exact path={[ROUTE_IMAGELAYERS]}>
                  <Datalayers type={DataLayerType.BITMAP} />
                </Route>
                <Route exact path={[ROUTE_DATAGROUPS]}>
                  <Datagroups />
                </Route>
                <Route exact path={[ROUTE_DATALAYER_DETAIL]}>
                  <DatalayerDetail />
                </Route>
                <Route exact path={[ROUTE_DATALAYER_DETAIL_UPLOAD]}>
                  <DatalayerUpload />
                </Route>
                <Route exact path={[ROUTE_DATALAYER_TABLE_DETAIL]}>
                  <DatalayerDetailTable />
                </Route>
                <Route exact path={[ROUTE_DATAGROUP_DETAIL]}>
                  <DatagroupDetail />
                </Route>
                <Route exact path={[ROUTE_PUBLICATIONS]}>
                  <Publications />
                </Route>
                <Route exact path={[ROUTE_PUBLICATION_DETAIL]}>
                  <PublicationDetail />
                </Route>
                <Route exact path={[ROUTE_BASELAYERS]}>
                  <BaseLayers />
                </Route>
                <Route exact path={[ROUTE_BASELAYER_DETAIL]}>
                  <BaseLayerDetail />
                </Route>
                <Route exact path={[ROUTE_WFSWMS]}>
                  <TransferLayers />
                </Route>
                <Route exact path={[ROUTE_WFSWMS_DETAIL]}>
                  <MapContext.Provider>
                    <TransferLayerDetail />
                  </MapContext.Provider>
                </Route>
              </Switch>
            </div>
          </Container>
        </Route>
        <Route exact path={[ROUTE_DATALAYER_STYLE]}>
          <HeaderViewMaputnik />
          <DatalayerMaputnik />
        </Route>
        <Route exact path={[ROUTE_DATAGROUP_STYLE]}>
          <HeaderViewMaputnik />
          <DatagroupMaputnik />
        </Route>
      </Switch>
    </div>
  );
});

export default withStyles(useBasePageStyles)(BasePage);
