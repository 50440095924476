import { Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText } from "@material-ui/core";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import React, { useContext, FunctionComponent } from "react";
import { injectIntl } from "react-intl";
import { ROUTE_BASE } from "routes/RouteList";
import { firstLetterUpperCase } from "../../utils";
import messages from "./messages";

import { withStyles } from "@material-ui/core/styles";
import useSelectProfile from "./SelectProfileStyles";
import { useHistory } from "react-router";

interface Props {
  open: boolean;
  canClose: boolean;
  classes: any;
  intl: any;
}

const SelectProfile: FunctionComponent<Props> = observer(({ classes, open, canClose, intl: { formatMessage } }) => {
  const {
    uiStore: { setDialogSelectProfileOpen, dialogSelectProfileOpen, locale },
    authStore: { availableProfiles, doSelectProfile, forceLogout, roleId },
  } = useContext(StoresContext);

  let { push } = useHistory();

  const selectProfile = async (profileId) => {
    try {
      await doSelectProfile(profileId);
      setDialogSelectProfileOpen(false);
      push(ROUTE_BASE);
    } catch (e) {
      console.log("Error::select profile: ", e.toString());
      switch (e.response.status) {
        case 401:
          forceLogout();
          break;
        default:
          console.log("TODO::select profile: We still need to catch the following error: ", e.response.status);
          break;
      }
    }
  };

  return (
    <Dialog open={open || dialogSelectProfileOpen} onClose={(e) => (canClose ? setDialogSelectProfileOpen(false) : "")}>
      <DialogTitle className={classes.dialogTitle}>{formatMessage(messages.title)}</DialogTitle>
      <DialogContent>
        <List>
          {availableProfiles.map((profile: any) => (
            <ListItem
              style={{ fontWeight: 800 }}
              selected={roleId === profile.id}
              key={profile.id}
              button
              onClick={(e) => {
                selectProfile(profile.id);
              }}
            >
              <ListItemText primary={firstLetterUpperCase(profile["name_" + locale + "_BE"] ?? "not found")} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
});
export default withStyles(useSelectProfile)(injectIntl(SelectProfile));
