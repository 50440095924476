import { Button, Card, CardActions, CardContent, CardHeader, Grid, Icon, InputAdornment, TextField } from "@material-ui/core";
import Send from "@material-ui/icons/Send";
import VpnKey from "@material-ui/icons/VpnKey";
import { withStyles } from "@material-ui/styles";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useState } from "react";
import { useHistory } from "react-router";
import { ROUTE_PUBLICATION_BASE } from "routes/RouteList";
import usePublicationDetailStyles from "./PublicationViewerStyles";
import { InjectedIntl, injectIntl } from "react-intl";
import messages from "./messages";

const PublicationMenu: FunctionComponent<{
  classes: any;
  intl: InjectedIntl;
}> = observer(({ classes, intl: { formatMessage } }) => {
  let { push } = useHistory();
  const [publicationCode, setPublicationCode] = useState<string>("");

  const handlePublicationCodeChange = (e) => {
    setPublicationCode(e.target.value);
  };

  return (
    <Grid container spacing={0} alignItems="center" justify="center" style={{ minHeight: "90vh", minWidth: "215px" }}>
      <Card className={classes.card}>
        <CardHeader avatar={<Icon className={"icon-logo-orbit-black"} />} title={formatMessage(messages.publicationsOrbitViewer)} />
        <CardContent>
          <TextField
            required
            id="email"
            inputProps={{
              "data-test-id": "email",
            }}
            label={formatMessage(messages.publicationsEnterPublicationCode)}
            //helperText={"helperText"}
            value={publicationCode}
            onChange={handlePublicationCodeChange}
            margin="normal"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKey />
                </InputAdornment>
              ),
            }}
          />
        </CardContent>
        <CardActions className={classes.action}>
          <Button
            id="btn_login"
            data-test-id="submit"
            onClick={() => {
              push(ROUTE_PUBLICATION_BASE + publicationCode);
            }}
            color="primary"
            className={classes.button}
            variant="contained"
          >
            <Send className={classes.extendedIcon} />
            {formatMessage(messages.publicationsSend)}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
});

export default withStyles(usePublicationDetailStyles)(injectIntl(PublicationMenu));

/*

  TODO:
  - bij mapStyle moet nog een merge gebeuren met de images layers styles
  - beveiligen van api calls?


*/
