import { Button, FormControl, FormControlLabel, Grid, Input, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import BaseMap from "views/Map/BaseMap";
import useBaseLayerDetailStyles from "./BaseLayerDetailStyles";
import { injectIntl } from "react-intl";
import messages from "./messages";
import { AdornedButton } from "../datalayer/DatalayerDetail";
import { BASELAYER_TYPES } from "../../../constants";
import { BootstrapInput } from "../datalayer/Datalayers";
import { Alert, AlertTitle } from "@material-ui/lab";
import BaselayerPopup from "./BaselayerPopup";

const BaseLayerDetail: FunctionComponent<{
  classes: any;
  intl: any;
}> = observer(({ classes, intl: { formatMessage } }) => {
  const {
    baseLayerStore: { activeBaseLayer },
  } = useContext(StoresContext);

  return (
    <Fragment>
      <Grid container spacing={6}>
        <Grid item xs={8}>
          <Typography variant="h4" gutterBottom>
            {formatMessage(messages.baselayersDetails)}
            {activeBaseLayer?.title}
          </Typography>
          <div style={{ width: "100%", height: 500 }}>
            <BaseMap
              latLng={{
                lat: 50.98935,
                lng: 3.87118,
              }}
              baseLayers={activeBaseLayer ? [activeBaseLayer] : []}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h5" gutterBottom>
            {formatMessage(messages.baselayersDetailsData)}
          </Typography>
          <BaselayerPopup classes={classes} intl={formatMessage} action="update" />
        </Grid>
      </Grid>
    </Fragment>
  );
});

export default withStyles(useBaseLayerDetailStyles)(injectIntl(BaseLayerDetail));
