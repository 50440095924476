import { Theme, createStyles } from "@material-ui/core/styles";

const useSelectProfileStyles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12)",
      width: "500px",
      "& h2 ": {
        color: "white",
      },
    },
  });

export default useSelectProfileStyles;
