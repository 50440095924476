/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";
export default defineMessages({
  baselayersDetails: {
    id: "baselayers.details",
    defaultMessage: "Details basiskaart: ",
  },
  baselayersDetailsData: {
    id: "baselayers.details.data",
    defaultMessage: "Basiskaart gegevens",
  },
  baselayersDetailsSave: {
    id: "baselayers.details.save",
    defaultMessage: "Opslaan",
  },
  baselayersCreate: {
    id: "baselayers.save",
    defaultMessage: "Aanmaken",
  },
  baselayersDetailsSaving: {
    id: "baselayers.details.saving",
    defaultMessage: "Opslaan...",
  },
  baselayersDetailsNoSave: {
    id: "baselayers.details.nosave",
    defaultMessage: "Geen wijzigingen om op te slaan",
  },
  baselayersDetailsErrorTitle: {
    id: "baselayers.details.error.title",
    defaultMessage: "Ongeldige titel",
  },
  baselayersDetailsErrorAvatarURL: {
    id: "baselayers.details.error.avatarURL",
    defaultMessage: "Ongeldige avatar URL",
  },
  baselayersDetailsErrorStyleURL: {
    id: "baselayers.details.error.styleURL",
    defaultMessage: "Ongeldige stijl URL",
  },
  baselayersDetailsTitle: {
    id: "baselayers.details.data.title",
    defaultMessage: "Titel",
  },
  baselayersDetailsAvatarURL: {
    id: "baselayers.details.data.avatarURL",
    defaultMessage: "Avatar URL",
  },
  baselayersDetailsStyleURL: {
    id: "baselayers.details.data.styleURL",
    defaultMessage: "Stijl URL",
  },
  baselayersBaselayers: {
    id: "baselayers.baselayers",
    defaultMessage: "Basiskaarten",
  },
  baselayersBaselayer: {
    id: "baselayers.baselayer",
    defaultMessage: "Basiskaart",
  },
  baselayersBaselayerExplanationQuestion: {
    id: "baselayers.baselayer.explanation.question",
    defaultMessage: "Wat is een basiskaart?",
  },
  baselayersBaselayerExplanation: {
    id: "baselayers.baselayer.explanation",
    defaultMessage:
      "Een basiskaart is de grondlaag die voor elke kaart gebruikt kan worden. Bij het aanmaken van een publicatie zal je kunnen kiezen uit een lijst van deze kaarten om als grondlaag in te stellen voor jouw publicatie.",
  },
  baselayersBroughtBy: {
    id: "baselayers.brought.by",
    defaultMessage: "Deze kaarten worden aangeboden door Orbit.",
  },
  new: {
    id: "baselayers.new",
    defaultMessage: "Nieuw",
  },
  cancel: {
    id: "baselayers.cancel",
    defaultMessage: "Annuleren",
  },
  process: {
    id: "baselayers.process",
    defaultMessage: "Verwerken",
  },
  newBaselayer: {
    id: "baselayers.new.baselayer",
    defaultMessage: "Nieuwe basiskaart",
  },
});
