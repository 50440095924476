import { Button, FormControl, Grid, InputLabel, List, ListItem, ListItemText, ListSubheader, MenuItem, Select, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/styles";
import { GridApiRef, GridColumns, GridRowsProp, GridSortModel, GridSortModelParams, useGridApiRef, XGrid } from "@material-ui/x-grid";
import { ColumnsInfoIncompatibleError, ConvertionTaskStatus, ConvertionTaskActionTypes, isColumnInfoCompatible, PagingType } from "@orbit/geo-core-shared";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { fetchTableData, updateDataLayer } from "services/geo-core";
import useDatalayerDetailStyles from "./DatalayerDetailStyles";
import EditDialog from "./EditTitleDialog";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import CustomDialogView from "views/CustomDialog/CustomDialogView";
import { errorsType, groupedFilesType } from "utils/file.utils";
import FileUploader from "views/upload/FileUploader";
import SimpleTable from "views/Table/SimpleTable";
import Papa from "papaparse";
import { InjectedIntl, injectIntl } from "react-intl";
import messages from "./messages";

const ACTION_TYPES = [
  { value: ConvertionTaskActionTypes.APPEND, label: "Toevoegen" },
  { value: ConvertionTaskActionTypes.OVERWRITE, label: "Overschrijven" },
];

const DatalayerDetailTable: FunctionComponent<{
  classes: any;
  intl: InjectedIntl;
}> = observer(({ classes, intl: { formatMessage } }) => {
  const {
    dataLayerStore: { layers, getActiveLayer, clearActiveLayer, activeLayer, table },
    uploadStore,
  } = useContext(StoresContext);

  const apiRef: GridApiRef = useGridApiRef();
  const [rowCount, setRowCount] = React.useState<number>(0);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [groupedFiles, setGroupedFiles] = useState<groupedFilesType>({});
  const [csvData, setCsvData] = useState<{ meta: Object; data: any[] }>({ meta: {}, data: [] });
  const [errors, setErrors] = useState<errorsType>([]);
  const [actionType, setActionType] = useState<ConvertionTaskActionTypes>(ACTION_TYPES[0].value);

  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [columns, setColumns] = React.useState<GridColumns>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [paging, setPaging] = React.useState<PagingType>({ page: 1, pageSize: 100 });
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);

  const defaultPageSize = 100;
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    async function loadData() {
      if (!id) {
        return;
      }
      await getActiveLayer(id);
      setLoading(false);
    }
    loadData();
    return () => {
      clearActiveLayer();
    };
  }, [layers, id]);

  useEffect(() => {
    if (!files[0]) {
      return;
    }
    setCsvData({ meta: {}, data: [] });
    Papa.parse(files[0], {
      dynamicTyping: true,
      chunkSize: 3,
      header: true,
      endoding: "utf-8",
      complete: function (responses) {
        const compatibleColumnErrors: ColumnsInfoIncompatibleError[] = isColumnInfoCompatible(activeLayer?.columnInfo, responses.meta.fields as string[]);
        if (compatibleColumnErrors.length) {
          setErrors(compatibleColumnErrors.map((er) => ({ name: er.type as string, reason: er.message })) as errorsType);
        } else {
          setErrors([]);
          setCsvData(responses);
        }
      },
    });
  }, [files]);

  const loadRows = React.useCallback(
    (params: PagingType) => {
      setPaging(params);
      setLoading(true);
      fetchTableData(id, params, sortModel).then((newData) => {
        setRowCount(newData.table.count);
        setRows(newData.table.rows);
        setColumns(newData.table.columns);
        setLoading(false);
      });
    },
    [table],
  );

  const handleOnPageChange = React.useCallback(
    (params) => {
      loadRows(params);
    },
    [loadRows],
  );

  const onSortModelChange = React.useCallback(
    async (params: GridSortModelParams) => {
      setSortModel(params.sortModel);
      fetchTableData(id, paging, params.sortModel).then((newData) => {
        setRows(newData.table.rows);
        setColumns(newData.table.columns);
        setLoading(false);
      });
    },
    [rows],
  );

  React.useEffect(() => {
    loadRows({ page: 0, pageSize: defaultPageSize });
  }, [apiRef, loadRows]);

  const changeLayerName = async (newName) => {
    await updateDataLayer(id, { displayName: newName });
    getActiveLayer(id);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const clearFiles = async () => {
    setFiles([]);
    setErrors([]);
    setCsvData({ meta: {}, data: [] });
    setGroupedFiles({});
    setActionType(ConvertionTaskActionTypes.APPEND);
  };

  const uploadFiles = async () => {
    setLoading(true);
    await uploadStore.upload(groupedFiles, undefined, actionType, id);
    clearFiles();
    await getActiveLayer(id);
    setLoading(false);
  };

  const processing = activeLayer && activeLayer?.status !== ConvertionTaskStatus.ERROR && activeLayer?.status !== ConvertionTaskStatus.FINISHED;

  return (
    <Fragment>
      <Grid container spacing={6} style={{ height: "100%" }}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom style={{ display: "flex" }}>
            {formatMessage(messages.datalayersTableDetails)} {activeLayer?.displayName}
            <EditDialog
              key={activeLayer?.displayName}
              label={formatMessage(messages.datalayersTableName)}
              defaultValue={activeLayer?.displayName}
              onSave={(newName) => changeLayerName(newName)}
              disabled={loading || !!processing}
              title={formatMessage(messages.datalayersTableUpdateTitle)}
            />
            <div style={{ flexGrow: 1, textAlign: "right" }}>
              <Button disabled={loading || !!processing} variant="contained" color="primary" size="large" startIcon={<PostAddOutlinedIcon />} onClick={openDialog}>
                {formatMessage(messages.datalayersUpdate)}
              </Button>
            </div>
          </Typography>

          {processing && (
            <Alert style={{ marginBottom: 20 }} severity="warning">
              {formatMessage(messages.datalayersTableDataStillProcessing)}
            </Alert>
          )}
          {activeLayer && activeLayer?.status === ConvertionTaskStatus.ERROR && (
            <Alert style={{ marginBottom: 20 }} severity="error">
              {formatMessage(messages.datalayersSomethingWrong)}
            </Alert>
          )}

          <div style={{ width: "100%", height: "calc(100% - 100px)", opacity: loading || processing ? 0.5 : 1 }}>
            <XGrid
              rows={rows}
              columns={columns}
              apiRef={apiRef}
              pagination
              onPageChange={handleOnPageChange}
              pageSize={defaultPageSize}
              rowCount={rowCount}
              paginationMode={"server"}
              sortingMode="server"
              onSortModelChange={onSortModelChange}
              loading={loading}
            />
          </div>
        </Grid>
      </Grid>
      <CustomDialogView
        open={dialogOpen}
        handleClose={() => {
          clearFiles();
          closeDialog();
        }}
        fullScreenSize="xl"
        dialogTitle={formatMessage(messages.datalayersUpdateTable)}
        dialogContent={
          <Fragment>
            {csvData.data.length === 0 && (
              <>
                <FormControl className={classes.formControl} style={{ marginBottom: 20 }} fullWidth>
                  <InputLabel id="csv-update-label">{formatMessage(messages.datalayersDataAction)}</InputLabel>
                  <Select value={actionType} fullWidth onChange={(e) => setActionType(e.target.value as ConvertionTaskActionTypes)}>
                    {ACTION_TYPES.map((at) => (
                      <MenuItem key={at.value} value={at.value}>
                        {at.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FileUploader
                  accept={".csv"}
                  onChange={(csvFiles: File[], groupedFiles: groupedFilesType, errors: errorsType) => {
                    if (errors?.length) {
                      setErrors(errors);
                    } else {
                      setFiles(csvFiles);
                      setGroupedFiles(groupedFiles);
                    }
                  }}
                  multiple={false}
                />
                {errors && errors.length > 0 && (
                  <List subheader={<ListSubheader>{formatMessage(messages.datalayersErrors)}</ListSubheader>}>
                    {errors.map((error) => (
                      <ListItem>
                        <ListItemText primary={error.name} secondary={error.reason} />
                      </ListItem>
                    ))}
                  </List>
                )}
              </>
            )}
            {csvData.data.length > 0 && <SimpleTable data={csvData.data} autoHeight={false} pagination pageSize={100} />}
          </Fragment>
        }
        dialogActions={
          <Fragment>
            <Button
              id="submit"
              onClick={() => {
                clearFiles();
                closeDialog();
              }}
            >
              {formatMessage(messages.cancel)}
            </Button>
            <Button
              id="submit"
              color="primary"
              variant={"contained"}
              onClick={() => {
                uploadFiles();
                closeDialog();
              }}
              disabled={!!errors.length || !files.length}
            >
              {actionType === ConvertionTaskActionTypes.APPEND ? formatMessage(messages.datalayersAddRows) : formatMessage(messages.datalayersOverwriteActiveTable)}
            </Button>
          </Fragment>
        }
      />
    </Fragment>
  );
});

export default withStyles(useDatalayerDetailStyles)(injectIntl(DatalayerDetailTable));
