import useQuery from "./useQuery";
import { useEffect, useState } from "react";

const useMapLocation = () => {
  const [bbox, setBbox] = useState<null | number[]>(null);
  const queryParams = useQuery();
  const bboxRaw = queryParams.get("bbox");
  useEffect(() => {
    setBbox(bboxRaw?.split(",").map((v) => Number(v)) || null);
  }, [bboxRaw]);
  return { bbox };
};
export default useMapLocation;
