import { Theme, createStyles } from "@material-ui/core/styles";

const useBasePageStyles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#ffffff",
    },
    content: {
      flexGrow: 1,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    container: {
      padding: 15,
      height: "calc(100% - 64px)",
    },
  });

export default useBasePageStyles;
