/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  uploadClickDrag: {
    id: "upload.click.drag",
    defaultMessage: "Klik op de onderstaande knop of sleep bestanden naar hier om te beginnen met verwerken. Ondersteunde extensie:",
  },
  uploadSelectFiles: {
    id: "upload.select.files",
    defaultMessage: "Selecteer bestanden",
  },
});
