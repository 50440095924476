import { Theme, createStyles } from "@material-ui/core/styles";

const useDatagroupsStyles = (theme: Theme) =>
  createStyles({
    floatingButton: {
      float: "right",
    },
    searchField: {
      marginTop: 10,
    },
    modalList: {
      marginLeft: -15,
      marginRight: -15,
    },
    nameField: {
      marginBottom: 15,
    },
  });

export default useDatagroupsStyles;
