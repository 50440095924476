import React, { Fragment, useContext, useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router";
//import { withErrorBoundary } from "containers/partials/error-boundary/ErrorBoundary";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import {
  ROUTE_LOGIN,
  ROUTE_BASE,
  ROUTE_DATALAYERS,
  ROUTE_FILEMANAGER,
  ROUTE_DATALAYER_DETAIL,
  ROUTE_DATALAYER_DETAIL_UPLOAD,
  ROUTE_DATALAYER_STYLE,
  ROUTE_SELECT_PROFILE,
  ROUTE_DATAGROUPS,
  ROUTE_DATAGROUP_DETAIL,
  ROUTE_DATAGROUP_STYLE,
  ROUTE_DATAGROUP_MAP,
  ROUTE_IMAGELAYERS,
  ROUTE_DATACSV,
  ROUTE_DATALAYER_TABLE_DETAIL,
  ROUTE_PUBLICATIONS,
  ROUTE_PUBLICATION_DETAIL,
  ROUTE_BASELAYERS,
  ROUTE_BASELAYER_DETAIL,
  ROUTE_WFSWMS,
  ROUTE_PUBLICATION_BASE,
  ROUTE_PUBLICATION_VIEW,
  ROUTE_PUBLICATION_VIEW_SEARCH,
  ROUTE_PUBLICATION_VIEW_LAYERS,
  ROUTE_WFSWMS_DETAIL,
  ROUTE_SSO_SESSION_CALLBACK,
} from "./RouteList";
import BasePage from "../containers/base/BasePage";
import PublicationPage from "../containers/publication/PublicationPage";
import SelectProfile from "containers/users/SelectProfile";
import SsoStartSession from "containers/login/SsoStartSession";
import LoginView from "../views/Login/LoginView";
import { observer } from "mobx-react-lite";
import { StoresContext } from "index";
import { ROUTE_PUBLICATION_VIEW_RELATIONS } from "./RouteList";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey(window.env.MUI_LICENSE_KEY);

const AppRoute = observer(() => {
  const {
    authStore: { profile },
  } = useContext(StoresContext);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Fragment>
      <PublicRoutes>
        <Route exact path={ROUTE_LOGIN} component={LoginView} />
        <Route exact path={ROUTE_SSO_SESSION_CALLBACK} component={SsoStartSession} />
        <Route exact path={[ROUTE_PUBLICATION_BASE, ROUTE_PUBLICATION_VIEW, ROUTE_PUBLICATION_VIEW_SEARCH, ROUTE_PUBLICATION_VIEW_RELATIONS]} component={PublicationPage} />
        <Redirect to={ROUTE_LOGIN} />
      </PublicRoutes>
      <PrivateRoutes>
        <Route exact path={ROUTE_SELECT_PROFILE}>
          <SelectProfile open={true} canClose={false} />
        </Route>
        <Route
          exact
          path={[
            ROUTE_BASE,
            ROUTE_DATALAYERS,
            ROUTE_FILEMANAGER,
            ROUTE_DATACSV,
            ROUTE_IMAGELAYERS,
            ROUTE_DATALAYER_DETAIL,
            ROUTE_DATALAYER_DETAIL_UPLOAD,
            ROUTE_DATALAYER_STYLE,
            ROUTE_DATAGROUPS,
            ROUTE_DATAGROUP_DETAIL,
            ROUTE_DATAGROUP_STYLE,
            ROUTE_DATALAYER_TABLE_DETAIL,
            ROUTE_DATAGROUP_MAP,
            ROUTE_PUBLICATIONS,
            ROUTE_PUBLICATION_DETAIL,
            ROUTE_BASELAYERS,
            ROUTE_BASELAYER_DETAIL,
            ROUTE_WFSWMS,
            ROUTE_WFSWMS_DETAIL,
          ]}
          component={BasePage}
        />
        <Route
          exact
          path={[ROUTE_PUBLICATION_BASE, ROUTE_PUBLICATION_VIEW, ROUTE_PUBLICATION_VIEW_SEARCH, ROUTE_PUBLICATION_VIEW_LAYERS, ROUTE_PUBLICATION_VIEW_RELATIONS]}
          component={PublicationPage}
        />
        {profile ? <Redirect to={ROUTE_BASE} /> : <Redirect to={ROUTE_SELECT_PROFILE} />}
      </PrivateRoutes>
    </Fragment>
  );
});

export default /* withErrorBoundary */ AppRoute;
