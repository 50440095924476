import { observable, action, makeObservable } from "mobx";
import { fetchLayergroupsData, createNewLayergroup, fetchLayergroupData } from "../services/geo-core";
import { BaseLayerListObjectType, LayergroupListObjectType, LayerGroupStatus, isLayerGroupGeoJsonActive, LayerGroup } from "@orbit/geo-core-shared";

export default class DataGroupStore {
  layergroups: LayergroupListObjectType[] = [];
  baseLayers: BaseLayerListObjectType[] | null;
  activeLayergroup: LayergroupListObjectType | null;

  getActiveLayergroup = async (id: string) => {
    const { layerGroup, baseLayers } = await fetchLayergroupData(id);
    if (layerGroup) this.activeLayergroup = layerGroup;
    if (baseLayers) this.baseLayers = baseLayers;
  };

  clearActiveLayergroup = () => {
    this.activeLayergroup = null;
  };

  constructor() {
    makeObservable(this, {
      layergroups: observable,
      baseLayers: observable,
      activeLayergroup: observable,
      clearActiveLayergroup: action.bound,
      getActiveLayergroup: action.bound,
      loadLayergroupData: action.bound,
      createLayergroup: action.bound,
      updateLayergroup: action.bound,
      getLayerGroupActiveStyle: action.bound,
    });
  }

  updateLayergroup(id, updateProperties) {
    const layerGroup = this.layergroups.find((lg) => lg.id === id);
    if (layerGroup) {
      Object.entries(updateProperties || {}).forEach(([key, value]) => {
        layerGroup[key] = value;
      });
    }

    if (this.activeLayergroup && this.activeLayergroup.id === id) {
      if (updateProperties?.status) {
        this.activeLayergroup.status = updateProperties.status;
      }

      // Process after geosjon is finished (so reload data)
      if (updateProperties?.status === LayerGroupStatus.SYNCING_ELASTICSEARCH) {
        this.loadLayergroupData();
      }

      // End of process
      if (updateProperties?.status === LayerGroupStatus.FINISHED) {
        this.loadLayergroupData();
      }
    }
  }

  async loadLayergroupData() {
    const layergroupsData: LayergroupListObjectType[] = await fetchLayergroupsData();
    this.layergroups = layergroupsData;
  }

  async createLayergroup(layerIds, layerName) {
    const newLayergroup: LayergroupListObjectType = await createNewLayergroup(layerIds, layerName);
    newLayergroup.dateCreated = new Date();
    this.layergroups.push(newLayergroup);
  }

  getLayerGroupActiveStyle() {
    if (this.activeLayergroup?.status === LayerGroupStatus.FINISHED) {
      return this.activeLayergroup.style;
    } else if (isLayerGroupGeoJsonActive(this.activeLayergroup?.status)) {
      return this.activeLayergroup?.geoJsonStyle || this.activeLayergroup?.style || null;
    }
    return null;
  }
}
