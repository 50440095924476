import fetchJsonp from "fetch-jsonp";

export const getStreetSuggestions = async ({
  value,
  muncipality = "",
  count = 15,
}: {
  value: string;
  muncipality: string;
  count: number;
}) => {
  try {
    const response = await fetchJsonp(`https://geo.api.vlaanderen.be/geolocation/v4/Suggestion?q=${value}%20${muncipality}&c=${count}`);
    const result = await response.json();
    return result.SuggestionResult.map((item: string, index: number) => ({
      value: item,
      label: item,
    }));
  } catch (error) {
    console.log("error", error.toString());
    return [error.toString()];
  }
};

export const getLocation = async (value: {
  value: string;
  municipality?: string;
  count?: number;
}): Promise<{ lat: number; lng: number } | string[]> => {
  try {
    const response = await fetchJsonp(`https://geo.api.vlaanderen.be/geolocation/v4/Location?q=${value}`);
    const result = await response.json();
    return {
      lat: result.LocationResult["0"].Location.Lat_WGS84,
      lng: result.LocationResult["0"].Location.Lon_WGS84,
    };
  } catch (error) {
    console.log("error", error.toString());
    return [error.toString()];
  }
};
