import React, { FunctionComponent, useContext } from "react";
import { injectIntl } from "react-intl";
import messages from "./messages";
import { Login } from "@orbit/components";
import { StoresContext } from "index";
import { DEFAULT_LOCALE } from "../../constants";
import { withStyles } from "@material-ui/core";
import { observer as hooksObserver } from "mobx-react-lite";
import loginStyles from "./LoginViewStyles";
import { AuthType } from "@orbit/geo-core-shared";

interface Props {
  classes: any;
  intl: any;
}

const LoginView: FunctionComponent<Props> = hooksObserver(({ intl: { formatMessage } }: any) => {
  const {
    authStore: { doLogin, startSSO, authStrategies },
  } = useContext(StoresContext);

  const { CLIENT_PORTAL_URL } = window.env;

  return (
    <>
      <Login
        messages={{
          appTitle: formatMessage(messages.appName),
          loginTitle: formatMessage(messages.loginDescription),
          loginSSOTitle: formatMessage(messages.loginDescriptionSso),
          loginSSOSeperator: formatMessage(messages.loginDescriptionOptionalSeperator),
          labelEmail: formatMessage(messages.labelEmail),
          labelEmailHelper: formatMessage(messages.labelEmailHelper),
          labelPassword: formatMessage(messages.labelPassword),
          button: formatMessage(messages.button),
          forgotPassword: formatMessage(messages.forgotPassword),
        }}
        doLogin={doLogin}
        doLoginSSO={(type) => startSSO(type as AuthType)}
        urlForgotPassword={`${CLIENT_PORTAL_URL}${DEFAULT_LOCALE}/forgotpassword`}
        authStrategies={authStrategies}
      />
    </>
  );
});

export default withStyles(loginStyles)(injectIntl(LoginView));
