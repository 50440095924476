import React, { FunctionComponent } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { MapLayerOrderItem, MapLayerType, TransferLayerListObjectType } from "@orbit/geo-core-shared";
import { observer } from "mobx-react-lite";
import { baseLayerModel, LayerControl } from "./BaseMap";
declare module "csstype" {
  interface Properties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  iconClickHandler: (id: string, visible: boolean) => void;
  bgColor?: string;
  color?: string;
  visible: boolean;
  labelInfo?: string;
  labelText: string;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      "&:hover > $content": {
        backgroundColor: theme.palette.action.hover,
      },
      "&:focus > $content, &$selected > $content": {
        backgroundColor: "transparent",
      },
      "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
        backgroundColor: "transparent",
      },
    },
    content: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      "$expanded > &": {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
      "& $content": {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: "inherit",
      color: "inherit",
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: "inherit",
      flexGrow: 1,
    },
  }),
);

export function StyledTreeItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles();
  const { labelText, visible, iconClickHandler, labelInfo, color, bgColor, ...other } = props;

  const LabelIcon = visible ? VisibilityIcon : VisibilityOffIcon;
  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              iconClickHandler(labelText, visible);
            }}
            color="inherit"
            className={classes.labelIcon}
          />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

const useStyles = makeStyles(
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 400,
    },
  }),
);

export interface Item {
  id: string;
  visible: boolean;
}

export interface LayerRoot {
  id: string;
  items: Item[];
}

export const TransferLayerController = observer(({ layers }: { layers: MapLayerOrderItem[] }) => {
  const classes = useStyles();
  const { activeStyle, setActiveStyle } = baseLayerModel;

  if (!layers) {
    return null;
  }
  return (
    <TreeView className={classes.root} defaultCollapseIcon={<ArrowDropDownIcon />} defaultExpandIcon={<ArrowRightIcon />} defaultEndIcon={<div style={{ width: 24 }} />}>
      {layers?.map?.((mapLayerOrderItem) => {
        /**
         * if the layer type is layergroup
         * we need to render the more complicated nested renderer
         * we need to keep the grouping of maputnik
         */
        if (mapLayerOrderItem.type === MapLayerType.LAYER_GROUP) {
          return <LayerControl style={activeStyle} updateStyle={setActiveStyle} />;
        }
        return (
          <StyledTreeItem
            key={mapLayerOrderItem.id}
            nodeId={mapLayerOrderItem.id}
            iconClickHandler={() => {
              mapLayerOrderItem.visible = !mapLayerOrderItem.visible;
            }}
            labelText={mapLayerOrderItem.label}
            visible={mapLayerOrderItem?.visible}
            color="#1a73e8"
            bgColor="#e8f0fe"
          ></StyledTreeItem>
        );
      })}
    </TreeView>
  );
});

export default function LayerController({
  data,
  toggleLayer,
  toggleGroup,
}: {
  data: LayerRoot[];
  toggleLayer: (id: string, visible: boolean) => void;
  toggleGroup: (id: string, visible: boolean) => void;
}) {
  if (data.length === 0) {
    return null;
  }
  return (
    <>
      {data
        .slice()
        .reverse()
        .map((root, index) => {
          const hasChildren = root.items.length > 1;
          const [child] = root.items;

          return (
            <StyledTreeItem
              nodeId={index.toString()}
              key={root.id}
              labelText={hasChildren ? root.id : child.id}
              visible={hasChildren ? !!root.items.find((item) => item.visible === true) : child.visible}
              labelInfo={hasChildren ? String(root.items.length) : ""}
              iconClickHandler={hasChildren ? toggleGroup : toggleLayer}
            >
              {hasChildren &&
                root.items.map((layer, layerIndex) => {
                  return (
                    <>
                      <StyledTreeItem
                        key={`${index}-${layerIndex}`}
                        nodeId={`${index}-${layerIndex}`}
                        iconClickHandler={toggleLayer}
                        labelText={layer.id}
                        visible={layer.visible}
                        color="#1a73e8"
                        bgColor="#e8f0fe"
                      />
                    </>
                  );
                })}
            </StyledTreeItem>
          );
        })}
    </>
  );
}
