import { PublicationListObjectType, SearchDateRange, PagingType, SearchFields, SpatialAnalysisRelations } from "@orbit/geo-core-shared";
import { action, autorun, computed, observable, toJS, makeObservable } from "mobx";
import { searchEnrichment, searchPublication } from "services/geo-core";

const defaultPaging = { pageSize: 100, page: 0 };
class SearchModel {
  publication: PublicationListObjectType | null;
  bufferValue: string = "0";
  exactQuery: boolean = false;
  value: string = "";
  features: GeoJSON.Feature[] = [];
  relation: SpatialAnalysisRelations = SpatialAnalysisRelations.intersects;
  searchResult: any[] = [];
  dateRanges: SearchDateRange[] = [];
  filterIndex: number = 0;
  total: number = 0;
  paging: PagingType = defaultPaging;

  search = async () => {
    console.log("do search");
    if (!this.publication) {
      return;
    }
    const { id } = this.publication;

    if (!this.publication) {
      return;
    }

    const index = Object.keys(this.publication.searchFields)[this.filterIndex];

    const {
      data: { items, total },
    } = await searchPublication(id, index, this.value, this.features, this.relation, this.dateRanges, this.paging, this.bufferValue, this.exactQuery);

    this.total = total;

    const filteredData = items?.filter((value, index, self) => self.findIndex((v) => v._id === value._id) === index) || [];
    console.log(filteredData);
    this.searchResult = filteredData;
  };

  searchEnrichment = async (id: string | null, resetPaging: boolean = false) => {
    console.log("do enrichmentsearch");
    if (!this.publication || !id) {
      return;
    }
    if (resetPaging) {
      this.paging = defaultPaging;
    }

    const {
      data: { items, total },
    } = await searchEnrichment(id, id, this.value);

    this.total = total;
    this.searchResult = items;
  };
  constructor() {
    makeObservable(this, {
      value: observable,
      bufferValue: observable,
      exactQuery: observable,
      searchResult: observable,
      dateRanges: observable,
      filterIndex: observable,
      paging: observable,
      total: observable,
      features: observable,
      relation: observable,
      setFeatures: action.bound,
      setRelation: action.bound,
      setPublication: action.bound,
      setValue: action.bound,
      setBufferValue: action.bound,
      setExactQuery: action.bound,
      setDateRange: action.bound,
      setFilterIndex: action.bound,
      setPaging: action.bound,
      searchableIndexes: computed,
    });
  }

  setDateRange(item: SearchDateRange) {
    const items = this.dateRanges.filter((dateRange) => item.range.key !== dateRange.range.key) ?? [];
    items.push(item);
    this.dateRanges = items;
  }

  setValue(value: string) {
    this.value = value;
  }

  setBufferValue(bufferValue: string) {
    this.bufferValue = bufferValue;
  }

  setExactQuery(exactQuery: boolean) {
    this.exactQuery = exactQuery;
  }

  setFeatures(features: GeoJSON.Feature[]) {
    this.features = features;
  }

  setRelation(relation: SpatialAnalysisRelations) {
    this.relation = relation;
  }

  setFilterIndex(filterIndex: number) {
    this.filterIndex = filterIndex;
  }

  setPublication(publication: PublicationListObjectType | null) {
    this.publication = publication;
  }

  setPaging(paging: PagingType) {
    this.paging = paging;
    this.search();
  }

  get searchableIndexes(): SearchFields {
    console.log("searchableIndexes", this.publication?.searchFields);
    if (!this.publication?.searchFields) {
      return {};
    }

    return Object.entries(this.publication?.searchFields)
      .filter(([key, item]) => item.included)
      .reduce((acc, [key, item]) => {
        acc[key] = item;
        return acc;
      }, {});
  }
}

export default SearchModel;
