import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  LinearProgress,
  Tab,
  Tabs,
  TextField,
  Typography,
  Slider,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import HelpIcon from "@mui/icons-material/Help";
import { withStyles } from "@material-ui/styles";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import { describeWfsFeatureType, fetchWfsCapabilities, fetchWfsFeatureInfo, fetchWmsCapabilities } from "../../../services/transfer";
import { FeatureTypeList, LayerTypeList } from "./TransferLayerClasses";
import useTransferLayerStyles from "./TransferLayerStyles";
import { FeatureTypeListView, LayerTypeListView } from "./TransferLayerViews";
import { getPathFromUrl } from "utils/functions";
import ListView from "containers/ListView/ListView";
import { ROUTE_WFSWMS } from "routes/RouteList";
import { green } from "@material-ui/core/colors";
import { InjectedIntl, injectIntl } from "react-intl";
import messages from "./messages";

let wfsCapabilities = new FeatureTypeList();
let wmsCapabilities = new LayerTypeList();

const TransferLayers: FunctionComponent<{
  classes: any;
}> = observer(({ classes, intl: { formatMessage }, intl }: { classes: any; intl: InjectedIntl }) => {
  const {
    transferLayerStore: { transferLayers, loadTransferLayers, addTransferLayer, deleteLayerWithId },
  } = useContext(StoresContext);

  const [error, setError] = useState(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [wfsName, setWfsName] = useState<string>("");
  const [wfsURL, setWfsURL] = useState<string>("");
  const [wfsUsername, setWfsUsername] = useState<string>("");
  const [wfsPassword, setWfsPassword] = useState<string>("");
  const [wfsOutputFormat, setWfsOutputFormat] = useState<string>("");
  const [wfsDefaultCrs, setWfsDefaultCrs] = useState<number>(31370);
  const [wfsFlip, setWfsFlip] = useState<boolean>(false);
  const [zoomLevel, setZoomLevel] = useState<number>(15);
  const [previousWfsURL, setPreviousWfsURL] = useState<string>("");

  const [wmsName, setWmsName] = useState<string>("");
  const [wmsURL, setWmsURL] = useState<string>("");
  const [wmsUsername, setWmsUsername] = useState<string>("");
  const [wmsPassword, setWmsPassword] = useState<string>("");
  const [previousWmsURL, setPreviousWmsURL] = useState<string>("");

  const resetDialog = () => {
    setWfsName("");
    setWfsURL("");
    setWfsUsername("");
    setWfsPassword("");
    setZoomLevel(15);
    setWmsName("");
    setWmsURL("");
    setWmsUsername("");
    setWmsPassword("");
    setPreviousWfsURL("");
    setPreviousWmsURL("");
    wfsCapabilities = new FeatureTypeList();
    wmsCapabilities = new LayerTypeList();
  };

  const validateWfs = async () => {
    const rootUrl = getPathFromUrl(wfsURL);
    const decodedRootUrl = decodeURIComponent(rootUrl); // add decode because encoded chars fail later on
    if (decodedRootUrl !== previousWfsURL) {
      wfsCapabilities = new FeatureTypeList();
    }
    setPreviousWfsURL(decodedRootUrl);
    setWfsURL(decodedRootUrl);
    setLoading(true);
    // if the url contains "arcgis" we need to enable wfsFlip
    if (decodedRootUrl.includes("arcgis")) {
      setWfsFlip(true);
    }
    const data = await fetchWfsCapabilities(decodedRootUrl, wfsUsername, wfsPassword);
    if (!data) {
      setError(true);
      setLoading(false);
      return;
    }
    setError(false);
    let { FeatureType, ComparisonOperator, outputFormat } = data;
    setWfsDefaultCrs(Number(Array.isArray(FeatureType) ? FeatureType[0].DefaultCRS.split(":").pop() : FeatureType.DefaultCRS.split(":").pop()) || 31370);

    if (outputFormat === "NOT SUPPORTED") {
      setError(true);
      setLoading(false);
      console.log("Output format not supported");
      return;
    }

    FeatureType = Array.isArray(FeatureType) ? FeatureType : [FeatureType];

    if (FeatureType) {
      // ook al geeft hij hieronder een error, het werkt wel: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled
      //@ts-ignore
      await Promise.allSettled(
        FeatureType.map((featureType) => {
          return new Promise(async (resolve, reject) => {
            const featureInfo = await fetchWfsFeatureInfo(featureType.Name, rootUrl, wfsUsername, wfsPassword, outputFormat);
            if (featureInfo) {
              resolve(featureInfo);
            } else {
              reject(featureType.Name);
            }
          });
        }),
      ).then((results) => {
        results.forEach((result, index) => {
          if (result.value === undefined) {
            // No idea if we come here
            console.log("Can't decide what geometry it is");
            FeatureType[index].Type = "no-type";
          } else {
            const { value } = result;
            FeatureType[index].Type = value?.geometry?.type || "no-type";
            FeatureType[index].Properties = Object.keys(value?.properties || {});
            FeatureType[index].GeometryName = value?.geometry_name || "no-name";
            FeatureType[index].ComparisonOperator = ComparisonOperator;
          }
        });
      });
      setWfsOutputFormat(outputFormat);
      // remove Type 'no-type' from FeatureType
      FeatureType = FeatureType.filter((feature) => feature.Type !== "no-type");
      wfsCapabilities.addFeatures(FeatureType);
      setLoading(false);
    }
  };

  const validateWms = async () => {
    const rootUrl = getPathFromUrl(wmsURL);
    if (rootUrl !== previousWmsURL) {
      wmsCapabilities = new LayerTypeList();
    }
    setPreviousWmsURL(rootUrl);
    setLoading(true);
    wmsCapabilities = new LayerTypeList();
    const capabilities = await fetchWmsCapabilities(rootUrl, wmsUsername, wmsPassword);

    if (!capabilities) {
      setLoading(false);
      setError(true);
      return;
    }

    setError(false);
    if (capabilities) {
      let newCapabilities: any[] = [];
      (Array.isArray(capabilities) ? capabilities : [capabilities]).forEach((capability) => {
        if (capability?.Layer && Array.isArray(capability?.Layer)) {
          newCapabilities = [...newCapabilities, ...capability.Layer];
        } else if (capability?.Layer) {
          newCapabilities = [...newCapabilities, capability.Layer];
        } else {
          newCapabilities = [...newCapabilities, capability];
        }
      });
      wmsCapabilities.addLayers(newCapabilities);
      setLoading(false);
    }
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    resetDialog();
  };

  const handleTabChange = (event, newValue) => {
    setError(false);
    setTabValue(newValue);
  };

  const handleWfsFlipChange = (event) => {
    setWfsFlip(event.target.checked);
  };

  const triggerSave = async () => {
    tabValue === 0
      ? await addTransferLayer({
          type: "wfs",
          wfsName,
          wfsURL: getPathFromUrl(wfsURL),
          featureType: wfsCapabilities.featureTypes,
          wfsUsername,
          wfsPassword,
          wfsOutputFormat,
          wfsDefaultCrs,
          wfsFlip,
          zoomLevel,
        })
      : await addTransferLayer({
          type: "wms",
          wfsName: wmsName,
          wfsURL: getPathFromUrl(wmsURL),
          featureType: wmsCapabilities.layerTypes,
          wfsUsername: wmsUsername,
          wfsPassword: wmsPassword,
        });
    closeDialog();
  };

  async function handleDelete(activeLayerIdMenu: string) {
    await deleteLayerWithId(activeLayerIdMenu);
  }

  const StyledTabs = withStyles({
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        width: "100%",
        backgroundColor: "#ffffff",
      },
    },
  })((props: any) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

  useEffect(() => {
    loadTransferLayers();
  }, []);

  const handleValidation = () => {
    if (tabValue === 0) {
      return wfsName.length > 0 && wfsURL.length > 0 && wfsCapabilities.hasFeaturesEnabled;
    } else {
      return wmsName.length > 0 && wmsURL.length > 0 && wmsCapabilities.hasLayersEnabled;
    }
  };

  return (
    <Fragment>
      <Grid container spacing={6}>
        <Grid item xs={8} spacing={6}>
          <Button
            className={classes.floatingButton}
            variant="contained"
            color="primary"
            disableElevation
            size="large"
            onClick={() => {
              openDialog();
            }}
            startIcon={<AddIcon />}
          >
            {formatMessage(messages.transferlayerNew)}
          </Button>
          <Typography variant="h4" gutterBottom>
            {formatMessage(messages.transferlayerWMSWFS)}
          </Typography>
          <ListView
            hasStatus={false}
            hasDateModified={false}
            singleItemName={formatMessage(messages.transferlayerWMSWFS)}
            canDelete={true}
            title={formatMessage(messages.transferlayerWMSWFS)}
            searchprompt={formatMessage(messages.transferlayerWMSWFS)}
            path={ROUTE_WFSWMS}
            items={transferLayers.map((transferLayer) => ({
              id: transferLayer.id,
              dateCreated: new Date(transferLayer.dateCreated),
              name: transferLayer.name,
              status: "",
              statusColor: green[500],
              user: transferLayer.user,
              fileType: transferLayer.type,
              type: transferLayer.type,
            }))}
            handleDelete={handleDelete}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" gutterBottom>
            {formatMessage(messages.transferlayerWFS)}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {formatMessage(messages.transferlayerWFSExplanation)}
          </Typography>
          <Typography variant="body2" gutterBottom paragraph>
            {formatMessage(messages.transferlayerGMLExplanation)}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            {formatMessage(messages.transferlayerWMS)}
          </Typography>
          <Typography variant="body2" gutterBottom paragraph>
            {formatMessage(messages.transferlayerWMSExplanation)}
          </Typography>
        </Grid>
      </Grid>

      <Dialog open={dialogOpen} onClose={closeDialog} disableBackdropClick disableEscapeKeyDown>
        <DialogContent className={classes.dialogContent}>
          <AppBar position="static">
            <StyledTabs value={tabValue} onChange={handleTabChange} variant="fullWidth" className={classes.tabs}>
              <Tab label={formatMessage(messages.transferlayerAddWFS)} />
              <Tab label={formatMessage(messages.transferlayerAddWMS)} />
            </StyledTabs>
          </AppBar>
          {tabValue === 0 && (
            <Fragment>
              <Grid className={classes.dialogPaper} container>
                <Grid item xs={12}>
                  <TextField
                    className={classes.dialogTextField}
                    required
                    id="wfsName"
                    label={formatMessage(messages.transferlayerName)}
                    value={wfsName}
                    onChange={(e) => setWfsName(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.dialogTextField}
                    error={error}
                    required
                    id="wfsUrl"
                    label={formatMessage(messages.transferlayerURL)}
                    value={wfsURL}
                    onChange={(e) => setWfsURL(e.target.value)}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {/*@ts-ignore*/}
                          <Button onClick={() => validateWfs()}>{formatMessage(messages.transferlayerValidate)}</Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.dialogTextField}
                    id="wfsUsername"
                    label={formatMessage(messages.transferlayerUsername)}
                    value={wfsUsername}
                    onChange={(e) => setWfsUsername(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    className={classes.dialogTextField}
                    id="wfsPassword"
                    label={formatMessage(messages.transferlayerPassword)}
                    value={wfsPassword}
                    onChange={(e) => setWfsPassword(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={wfsFlip} onChange={handleWfsFlipChange} name="wfsFlip" />}
                    label={
                      <Typography>
                        Flip coördinaten{" "}
                        <Tooltip title="Voor sommige WFS'en moeten de coördinaten omgedraaid worden om te laten werken." aria-label="add">
                          <HelpIcon fontSize="small" />
                        </Tooltip>
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">{formatMessage(messages.transferlayerMinZoom)}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Slider aria-label="Zoom-level" defaultValue={zoomLevel} valueLabelDisplay="auto" onChange={(_, value) => setZoomLevel(value)} step={1} marks min={6} max={24} />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  {wfsCapabilities.hasFeatures && <FeatureTypeListView featureTypeList={wfsCapabilities} intl={intl} />}
                </Grid>
              </Grid>
            </Fragment>
          )}
          {tabValue === 1 && (
            <Fragment>
              <Grid className={classes.dialogPaper} container>
                <Grid item xs={12}>
                  <TextField
                    className={classes.dialogTextField}
                    required
                    id="wmsName"
                    label={formatMessage(messages.transferlayerName)}
                    value={wmsName}
                    onChange={(e) => setWmsName(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.dialogTextField}
                    required
                    error={error}
                    id="wmsUrl"
                    label={formatMessage(messages.transferlayerURL)}
                    value={wmsURL}
                    onChange={(e) => setWmsURL(e.target.value)}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {/*@ts-ignore*/}
                          <Button edge="end" onClick={() => validateWms()}>
                            {formatMessage(messages.transferlayerValidate)}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.dialogTextField}
                    id="wmsUsername"
                    label={formatMessage(messages.transferlayerUsername)}
                    value={wmsUsername}
                    onChange={(e) => setWmsUsername(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.dialogTextField}
                    id="wmsPassword"
                    label={formatMessage(messages.transferlayerPassword)}
                    value={wmsPassword}
                    onChange={(e) => setWmsPassword(e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  {wmsCapabilities.hasLayers && <LayerTypeListView intl={intl} layerTypeList={wmsCapabilities} />}
                </Grid>
              </Grid>
            </Fragment>
          )}
        </DialogContent>
        {loading && <LinearProgress />}
        <DialogActions>
          <Button onClick={closeDialog}>{formatMessage(messages.transferlayerCancel)}</Button>
          <Button variant="contained" color="primary" onClick={triggerSave} disabled={!handleValidation()}>
            {formatMessage(messages.transferlayerAdd)}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
});

export default withStyles(useTransferLayerStyles)(injectIntl(TransferLayers));
