import { Theme, createStyles } from "@material-ui/core/styles";

const useCustomDialogStyles = (theme: Theme) =>
  createStyles({
    customDialog: {
      minWidth: 500,
    },
    root: {
      margin: 0,
      padding: theme.spacing(2),
      marginRight: 46,
      cursor: "move",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    test: {
      overflow: "hidden",
    },
  });

export default useCustomDialogStyles;
