import React, { useContext, useState } from "react";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { useEffect } from "react";
import useQuery from "../../hooks/useQuery";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "../../index";
import { useParams } from "react-router";
import { ROUTE_LOGIN, ROUTE_SSO_SESSION_CALLBACK } from "routes/RouteList";
import { AuthType } from "@orbit/geo-core-shared";
import { InjectedIntl, injectIntl } from "react-intl";
import messages from "./messages";

const SsoStartSession = hooksObserver(({ intl }: { intl: InjectedIntl }) => {
  const {
    authStore: { doLoginSso, forceLogout },
  } = useContext(StoresContext);
  const [error, setError] = useState<boolean>(false);
  const { authType = "" as AuthType } = useParams() as { authType: AuthType };
  const queryParams = useQuery();
  const sessionId = queryParams.get("sessionId") || "";
  useEffect(() => {
    const loginHandler = async () => {
      try {
        await doLoginSso({ sessionId, authType });
      } catch (er) {
        forceLogout(`${window.location.origin}${ROUTE_SSO_SESSION_CALLBACK}`);
      }
    };

    if (sessionId && authType) {
      loginHandler();
    } else {
      setError(true);
    }
  }, []);

  const onLoginAgain = () => {
    window.location.href = ROUTE_LOGIN;
  };

  return (
    <Grid container spacing={0} alignItems="center" justify="center" style={{ minHeight: "90vh" }}>
      {!error ? (
        <>
          <CircularProgress size={20} style={{ marginRight: 15 }} />
          <Typography>{intl.formatMessage(messages.loading)}</Typography>
        </>
      ) : (
        <Grid item style={{ textAlign: "center" }}>
          <Typography>{intl.formatMessage(messages.error)}</Typography>
          <br />
          <Button color="primary" onClick={onLoginAgain} variant="contained">
            {intl.formatMessage(messages.btnAgain)}
          </Button>
        </Grid>
      )}
    </Grid>
  );
});

export default injectIntl(SsoStartSession);
