import { Button, Grid, TextField, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/styles";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ROUTE_PUBLICATIONS } from "routes/RouteList";
import { deletePublication } from "services/geo-core";
import CustomDialogView from "../../../views/CustomDialog/CustomDialogView";
import usePublicationsStyles from "./PublicationsStyles";
import ListView, {} from "containers/ListView/ListView";
import { LayerGroupStatus } from "@orbit/geo-core-shared";
import { getPublicationStatusLabel } from "../../../constants";
import { getLayerGroupStatusColor } from "../../../constants";
import { InjectedIntl, injectIntl } from "react-intl";
import messages from "./messages";

const Publications: FunctionComponent<{
  classes: any;
  intl: InjectedIntl;
}> = observer(({ classes, intl: { formatMessage } }) => {
  const {
    publicationStore: { publications, loadPublications, createPublication },
  } = useContext(StoresContext);

  let { push } = useHistory();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [naam, setNaam] = useState("");
  const [showError, setShowError] = useState<boolean>(false);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    loadPublications();
  }, []);

  async function handleDelete(activeLayerIdMenu: string) {
    await deletePublication(activeLayerIdMenu);
    await loadPublications();
  }
  const AllowedCharacters = /^[a-zA-Z0-9\s\-_]*$/;
  const handleNameChange = (event: { target: { value: string } }) => {
    const { value } = event.target;
    if (!AllowedCharacters.test(value)) {
      setShowError(true);
    } else {
      setShowError(false);
    }
    setNaam(value);
  };
  return (
    <Fragment>
      <Grid container spacing={6}>
        <Grid item xs={8} spacing={6}>
          <Button
            className={classes.floatingButton}
            variant="contained"
            color="primary"
            disableElevation
            size="large"
            onClick={() => {
              openDialog();
            }}
            startIcon={<AddIcon />}
          >
            {formatMessage(messages.publicationsNew)}
          </Button>
          <Typography variant="h4" gutterBottom>
            {formatMessage(messages.publicationsPublications)}
          </Typography>

          <ListView
            hasStatus={true}
            singleItemName={formatMessage(messages.publicationsPublication)}
            hasDateModified={true}
            canDelete={true}
            path={ROUTE_PUBLICATIONS}
            title={formatMessage(messages.publicationsPublications)}
            searchprompt={formatMessage(messages.publicationsPublication)}
            items={publications.map((publication) => ({
              // user: {firstName: "Ruben", lastName: "De Smet"},
              id: publication.id,
              dateCreated: publication.dateCreated,
              dateModified: publication.dateModified,
              name: publication.name,
              avatarUrl: publication.avatarUrl,
              status: getPublicationStatusLabel(publication?.layerGroup?.status || LayerGroupStatus.FINISHED),
              statusColor: getLayerGroupStatusColor(publication?.layerGroup?.status || LayerGroupStatus.FINISHED),
              user: publication.user,
            }))}
            handleDelete={handleDelete}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" gutterBottom>
            {formatMessage(messages.publicationsPublicationExplanation)}
          </Typography>
          <Typography variant="body2">{formatMessage(messages.publicationsPublicationExplanationInfo)}</Typography>
        </Grid>
      </Grid>

      <CustomDialogView
        open={dialogOpen}
        handleClose={() => {
          closeDialog();
        }}
        dialogTitle={formatMessage(messages.publicationsNewPublication)}
        dialogContent={
          <Fragment>
            <TextField
              error={showError}
              className={classes.marginBottom}
              required
              id="publicatiename"
              label={formatMessage(messages.publicationsPublicationName)}
              value={naam}
              onChange={handleNameChange}
              fullWidth
              helperText={showError ? formatMessage(messages.nameError) : ""}
            />
          </Fragment>
        }
        dialogActions={
          <Fragment>
            <Button
              id="submit"
              onClick={() => {
                closeDialog();
              }}
            >
              {formatMessage(messages.publicationsCancel)}
            </Button>
            <Button
              id="submit"
              color="primary"
              variant="contained"
              disabled={naam.length === 0 || showError}
              onClick={async () => {
                const id = await createPublication(naam);
                setNaam("");
                closeDialog();
                push(ROUTE_PUBLICATIONS + "/" + id);
              }}
              disableElevation
            >
              {formatMessage(messages.publicationsCreate)}
            </Button>
          </Fragment>
        }
      />
    </Fragment>
  );
});

export default withStyles(usePublicationsStyles)(injectIntl(Publications));
