import { Theme, createStyles } from "@material-ui/core/styles";

const useDatalayerDetailStyles = (theme: Theme) =>
  createStyles({
    urlContainer: {
      marginBottom: theme.spacing(3),
    },
    urlButton: {
      float: "right",
      marginLeft: "5px",
    },
  });

export default useDatalayerDetailStyles;
