import { Theme, createStyles } from "@material-ui/core/styles";

const useMapStyles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      maxWidth: 500,
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    mapControls: {
      display: "flex",
      flexDirection: "column",
    },
  });

export default useMapStyles;
