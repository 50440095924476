import { withStyles } from "@material-ui/styles";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import useFileManagerStyles from "./FileManagerStyles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Hidden,
  IconButton,
  MenuItem,
  Snackbar,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  DataGridPro,
  GridActionsCellItem,
  GridRowParams,
  GridColDef,
  GridColumnHeaderParams,
  GridRowsProp,
  GridRowSelectionModel,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { toJS } from "mobx";
import { SelectChangeEvent } from "@mui/material/Select";
import Select from "@material-ui/core/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory, useLocation } from "react-router";
import { ROUTE_DATALAYERS } from "routes/RouteList";
import { DataLayerType } from "stores/DataLayerStore";
import SvelteJSONEditor from "views/Generic/json.editor";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import { UPLOAD_TYPE } from "stores/UploadStore";
import { Alert } from "@material-ui/lab";
import { GeoFile } from "@orbit/geo-core-shared";
import { loadFileCount } from "services/geo-core";
import { InjectedIntl, injectIntl } from "react-intl";
import messages from "./messages";

export const mapValueSeperator = "#_#";

export const generateFormattedValue = (mapValue, fileMapping) => {
  const formattedValue = {};
  const splittedMapValue = mapValue.split(mapValueSeperator);
  fileMapping.forEach((mapKey, index) => {
    formattedValue[mapKey] = splittedMapValue[index];
  });
  return formattedValue;
};

export const getHtmlValue = (d: { json?: object; text?: string }) => {
  try {
    return JSON.stringify(d.json || JSON.parse(d.text || "[]"));
  } catch (er) {}
  return "[]";
};

const generateColumns = ({ data, deleteOneCb, editOneCb, formatMessage }): GridColDef[] => {
  if (data.length === 0) {
    return [];
  }

  const keys = Object.keys(data[0]).filter((item) => item !== "convertionTask");
  const result: GridColDef[] = keys.map((key) => ({
    field: key,
    width: 350,
    renderHeader: (params: GridColumnHeaderParams) => <strong>{formatMessage(messages[`translateKey_${key}`])}</strong>,
    renderCell: (params: GridRenderCellParams) => {
      if (params.field === "mapValue") {
        const formattedValue = generateFormattedValue(params.formattedValue, data.filter((item) => item.id === (params.id as string))[0].fileMapping);
        return JSON.stringify(formattedValue);
      }
      return params.formattedValue;
    },
  }));

  result.push({
    field: "Acties",
    type: "actions",
    width: 100,
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem key={params.id + "-edit"} icon={<EditIcon />} onClick={() => editOneCb(data.filter((item) => item.id === params.id))} label="Edit" />,
      <GridActionsCellItem key={params.id + "-delete"} icon={<DeleteIcon />} onClick={() => deleteOneCb(data.filter((item) => item.id === params.id))} label="Delete" />,
    ],
    renderHeader: (params: GridColumnHeaderParams) => <strong>{formatMessage(messages.filemanagertableactions)}</strong>,
  });
  return result;
};

const FileManager: FunctionComponent<{
  classes: any;
  intl: InjectedIntl;
}> = observer(({ classes, intl, intl: { formatMessage } }) => {
  const {
    dataLayerStore: { loadData, layers, loadFiles, files, amountOfFiles, deleteFiles },
    uploadStore: { uploadFiles },
  } = useContext(StoresContext);

  let { push } = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const [currentConvertionTaskId, setCurrentConvertionTaskId] = useState<string>(id || "");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [loading, setLoading] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [fileDialogOpen, setFileDialogOpen] = React.useState(false);
  const [fileDeleteDialogOpen, setFileDeleteDialogOpen] = React.useState(false);
  const [fileMapping, setFileMapping] = useState<{ json?: object; text?: string }>({ json: [] });
  const [hasError, setHasError] = useState<boolean>(false);
  const [activeFile, setActiveFile] = useState<GeoFile>();
  const [activeDelete, setActiveDelete] = useState<object[]>([]);
  const [message, setMessage] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(true);
  const [fileCount, setFileCount] = useState<object>({});

  const handleFileDialogOpen = (item: object) => {
    const formattedValue = generateFormattedValue(item[0].mapValue, item[0].fileMapping);
    setActiveFile(item[0]);
    setFileMapping({ json: formattedValue });
    setFileDialogOpen(true);
  };

  const handleFileDeleteDialogOpen = (item: object[]) => {
    setActiveDelete(item);
    setFileDeleteDialogOpen(true);
  };

  const handleFileDialogClose = () => {
    setFileDialogOpen(false);
  };

  const handleFileDeleteDialogClose = () => {
    setFileDeleteDialogOpen(false);
  };

  const handleFileDialogSave = async () => {
    const { success, message } = await uploadFiles(
      [],
      getHtmlValue({ json: [{ fileName: activeFile?.fileName || "", id: activeFile?.id, fields: fileMapping.json || JSON.parse(fileMapping.text || "[]") }] }),
      activeFile?.convertionTask.id || "",
      UPLOAD_TYPE.REINDEX,
    );
    setSuccess(success);
    if (success) {
      setFileDialogOpen(false);
      setOpen(true);
      setMessage(formatMessage(messages.filemanagerfilesaved));
      setFileMapping({ json: [] });
      await loadFiles(paginationModel.page, paginationModel.pageSize, currentConvertionTaskId === "" ? "all" : currentConvertionTaskId);
    } else {
      setOpen(true);
      setMessage(message);
    }
  };

  const handleFileDeleteDialogSave = async () => {
    await handleDeleteCb(activeDelete.map((item: GeoFile) => item.id));
    handleFileDeleteDialogClose();
  };

  const handleConvertionTaskChange = (event: SelectChangeEvent) => {
    setCurrentConvertionTaskId(event.target.value);
    setRowSelectionModel([]);
  };

  useEffect(() => {
    loadData(DataLayerType.VECTOR);
    const fetchCount = async () => {
      const fileCount = await loadFileCount();
      setFileCount(fileCount);
    };
    fetchCount();
  }, []);

  useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      await loadFiles(paginationModel.page, paginationModel.pageSize, currentConvertionTaskId === "" ? "all" : currentConvertionTaskId);

      if (!active) {
        return;
      }

      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [paginationModel.page, paginationModel.pageSize, currentConvertionTaskId]);

  const handleDeleteCb = async (items: string[]) => {
    setLoading(true);
    await deleteFiles(items);
    await loadFiles(paginationModel.page, paginationModel.pageSize, currentConvertionTaskId === "" ? "all" : currentConvertionTaskId);
    const fileCount = await loadFileCount();
    setFileCount(fileCount);
    setRowSelectionModel([]);
    setLoading(false);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Fragment>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={success ? "success" : "error"}>
          {message}
        </Alert>
      </Snackbar>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            {formatMessage(messages.filemanagertitle)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Select displayEmpty fullWidth value={currentConvertionTaskId} onChange={handleConvertionTaskChange}>
            <MenuItem value="">{formatMessage(messages.filemanageralldatalayers)}</MenuItem>
            {layers &&
              layers.map((layer) => (
                <MenuItem key={layer.id} value={layer.id}>
                  {layer.displayName} ({fileCount[layer.id]})
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => push(`${ROUTE_DATALAYERS}/${currentConvertionTaskId.split(" ")[0]}/upload`)}
            disabled={currentConvertionTaskId === ""}
          >
            {formatMessage(messages.filemanageraddnewfiles)}
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant="outlined" onClick={() => push(`${ROUTE_DATALAYERS}/${currentConvertionTaskId.split(" ")[0]}`)} disabled={currentConvertionTaskId === ""}>
            {formatMessage(messages.filemanagergotodatalayer)}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {files && (
            <>
              <EnhancedTableToolbar
                numSelected={rowSelectionModel.length}
                buttonClick={() => handleFileDeleteDialogOpen(files.filter((file) => rowSelectionModel.includes(file.id)))}
                notSelectedTooltip={formatMessage(messages.filemanagerdeleteingroup)}
              />
              <DataGridPro
                rows={files.map((file) => {
                  file.convertionTaskId = file.convertionTask.id;
                  file.layerName = file.convertionTask.layerName;
                  file.fileMapping = file.convertionTask.fileMapping;
                  return file;
                })}
                columns={generateColumns({
                  data: files,
                  deleteOneCb: (item: object) => handleFileDeleteDialogOpen([item[0]]),
                  editOneCb: (item: object) => handleFileDialogOpen(item),
                  formatMessage,
                })}
                loading={loading}
                rowHeight={38}
                checkboxSelection
                disableRowSelectionOnClick
                pagination
                paginationModel={paginationModel}
                pageSizeOptions={[10, 25, 50, 100]}
                paginationMode="server"
                rowCount={amountOfFiles}
                rowSelectionModel={rowSelectionModel}
                onPaginationModelChange={setPaginationModel}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                keepNonExistentRowsSelected
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      dateCreated: false,
                      dateModified: false,
                      dateDeleted: false,
                      id: false,
                      location: false,
                      convertionTaskId: false,
                      fileMapping: false,
                      fileNameOnStorage: false,
                    },
                  },
                  pinnedColumns: { right: ["Acties"] },
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Dialog open={fileDialogOpen} onClose={handleFileDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{formatMessage(messages.filemanagerpopuptitle)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ height: "300px" }}>
              <SvelteJSONEditor
                key="alerts"
                content={fileMapping}
                // @ts-ignore
                onChange={(d, _, { contentErrors }) => {
                  setFileMapping(d);
                  setHasError(!(contentErrors?.validationErrors?.length === 0));
                }}
                mode="text"
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFileDialogClose}>{formatMessage(messages.filemanagerpopupclose)}</Button>
          <Button onClick={handleFileDialogSave} autoFocus>
            {formatMessage(messages.filemanagerpopupsave)}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={fileDeleteDialogOpen} onClose={handleFileDeleteDialogClose} aria-labelledby="alert-dialog-title-delete" aria-describedby="alert-dialog-description-delete">
        <DialogTitle id="alert-dialog-title-delete">{formatMessage(messages.filemanagerdeletetitle)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-delete">
            <Typography>{formatMessage(messages.filemanagerdeletemessage)}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFileDeleteDialogClose}>{formatMessage(messages.filemanagerdeleteclose)}</Button>
          <Button onClick={handleFileDeleteDialogSave} autoFocus>
            {formatMessage(messages.filemanagerdeleteconfirm)}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
});

export default withStyles(useFileManagerStyles)(injectIntl(FileManager));
