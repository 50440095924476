import { createStyles, Fade, makeStyles, Slide, Theme, Tooltip } from "@material-ui/core";
import React from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { BaseLayerListObjectType } from "@orbit/geo-core-shared";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      left: 10,
      bottom: 10,
    },
    container: {
      display: "flex",
    },
    img: {
      width: 80,
      height: 80,
      margin: 5,
      border: "2px solid white",
      borderRadius: "10%",
      boxShadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048),
      0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072),
      0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12)`,
    },
    imgActive: {
      border: "3px solid white",
    },
  }),
);

export default function BaseLayerController({
  maps,
  activeMap,
  setActiveMap,
}: {
  maps: BaseLayerListObjectType[];
  activeMap: BaseLayerListObjectType | null;
  setActiveMap: (map: BaseLayerListObjectType) => void;
}) {
  const ref = useOnclickOutside(() => {
    setChecked(false);
  });

  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);

  if (!activeMap || maps.length <= 1) {
    return <div></div>;
  }

  return (
    <div ref={ref} className={classes.root} style={{ pointerEvents: "none" }}>
      <div className={classes.container}>
        {!checked && (
          <Fade in={!checked}>
            <Tooltip title={activeMap.title}>
              <img
                className={`${classes.img} ${classes.imgActive}`}
                aria-controls="customized-menu"
                aria-haspopup="true"
                aria-label="add"
                onClick={() => setChecked(!checked)}
                style={{ pointerEvents: "initial" }}
                src={activeMap?.avatarUrl}
              />
            </Tooltip>
          </Fade>
        )}
        {maps.map((map, index) => (
          <Slide key={map.title + index} direction="right" in={checked} {...(checked ? { timeout: 200 } : {})}>
            <Tooltip title={map.title}>
              <img
                className={`${classes.img} ${JSON.stringify(map) === JSON.stringify(activeMap) && classes.imgActive}`}
                aria-controls="customized-menu"
                aria-haspopup="true"
                aria-label="add"
                onClick={() => setActiveMap(map)}
                style={{ pointerEvents: "initial" }}
                src={map.avatarUrl}
              />
            </Tooltip>
          </Slide>
        ))}
      </div>
    </div>
  );
}
