import { IconButton, Button, Typography, Grid } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import TocIcon from "@material-ui/icons/Toc";
import PrevButton from "@material-ui/icons/NavigateBefore";
import NextButton from "@material-ui/icons/NavigateNext";
import { withStyles } from "@material-ui/styles";
import { MapPopup } from "@orbit/components";
import React, { useState, useContext } from "react";
import { Layer, MapContext, Source } from "react-map-gl";
import styles from "./PopupStyles";
import { BaseMapActionTypes, useBaseMap } from "./BaseMapContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { observer } from "mobx-react-lite";
import { injectIntl } from "react-intl";
import messages from "./messages";

const addornedStyles = {
  root: {
    marginLeft: 5,
  },
};

const SpinnerAdornment = withStyles(addornedStyles)((props) => <CircularProgress size={20} style={{ marginLeft: 10 }} />);
const AdornedButton = (props) => {
  const { children, loading, ...rest } = props;
  return (
    <Button {...rest} disabled={!!loading}>
      {children}
      {loading && <SpinnerAdornment {...rest} />}
    </Button>
  );
};

const DefaultPopup = observer(({ classes, data, onClose, lat, lng, intl: { formatMessage } }) => {
  const { map } = useContext(MapContext);
  const [loadingGeom, setLoadingGeom] = useState(false);
  const { state, dispatch } = useBaseMap();
  // when data array is empty no need to render the popup
  if (data.length === 0) {
    return null;
  }

  const onDispatch = (newIndex: number) => {
    data[newIndex]?.setSelectedGeom?.();
    dispatch({ type: BaseMapActionTypes.SET_ACTIVE_INDEX, activeIndex: newIndex });
  };

  const onDispatchSelectedGeom = async (feat: any) => {
    setLoadingGeom(true);
    const { _ne, _sw } = map.getBounds();
    const dataToSend = !feat.source.includes("jsx-source")
      ? {
          layerName: feat.properties.wfsType,
          id: feat.source,
          type: "wfs",
          bbox: `${_sw.lng},${_sw.lat},${_ne.lng},${_ne.lat},EPSG:4326`,
          geom: feat.geom,
        }
      : {
          table: feat.sourceLayer.replace("public.", ""),
          id: feat.properties?.ogc_fid || feat.properties?.id,
          type: "datalaag",
          key: feat.properties?.ogc_fid ? "ogc_fid" : "id",
        };
    console.log(dataToSend);
    try {
      const {
        data: { data },
      } = await axios.post(`${window.env.API_URL}geo-core/getgeojson/${lat}/${lng}`, [dataToSend]);
      console.log(data);
      const geom = data?.[0]?.geometry;
      if (geom) dispatch({ type: BaseMapActionTypes.SET_GEOM, geom });
    } catch (error) {
      console.log("ERROR: Could not fetch geojson:", error);
    }
    setLoadingGeom(false);
  };

  const useTitle = data[state.activeIndex].title;
  const contentData = data[state.activeIndex].data;

  return (
    <MapPopup
      onClose={(evt) => {
        onClose(evt);
      }}
      canCloseTooltip={false}
      lat={lat}
      lng={lng}
      captureScroll={true}
      className={classes.popupZindex}
    >
      <div style={{ maxWidth: 350 }}>
        <MapPopup.Header>
          <Tooltip title={useTitle}>
            <div style={{ maxWidth: 300, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{useTitle}</div>
          </Tooltip>
        </MapPopup.Header>
        <MapPopup.Content>
          {data[state.activeIndex]?.data["data"]?.length > 4 && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                dispatch({
                  type: BaseMapActionTypes.SET_RELATIONAL_ITEMS,
                  relationalItems: JSON.parse(data[state.activeIndex]?.data["data"]),
                });
              }}
              startIcon={<TocIcon />}
            >
              {formatMessage(messages.mapViewRelationalData)} ({JSON.parse(data[state.activeIndex]?.data["data"]).length})
            </Button>
          )}
          <ul className={classes.popup}>
            {Object.entries(contentData).map(([key, value]) => {
              if (["data", "relation_count"].includes(key)) {
                return null;
              }
              if (value === null || value === "") {
                return null;
              }
              return (
                <li key={key} className={classes.li}>
                  <strong>{key}</strong>: {value}
                </li>
              );
            })}
          </ul>
        </MapPopup.Content>
        <MapPopup.Footer>
          {data[state.activeIndex]?.geom && (
            <AdornedButton
              className={classes.button}
              fullWidth
              variant="outlined"
              type="submit"
              size="small"
              color="primary"
              loading={loadingGeom}
              onClick={() => onDispatchSelectedGeom(data[state.activeIndex])}
            >
              {formatMessage(messages.mapAddGeometry)}
            </AdornedButton>
          )}
          {data?.length > 1 && (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
              <IconButton className={classes.nextprevButton} disabled={state.activeIndex === 0} onClick={() => onDispatch(state.activeIndex - 1)}>
                <PrevButton />
              </IconButton>
              <Typography variant="subtitle1">
                {state.activeIndex + 1}/{data.length}
              </Typography>
              <IconButton className={classes.nextprevButton} disabled={state.activeIndex === data.length - 1} onClick={() => onDispatch(state.activeIndex + 1)}>
                <NextButton />
              </IconButton>
            </div>
          )}
        </MapPopup.Footer>
      </div>
    </MapPopup>
  );
});

export default withStyles(styles)(injectIntl(DefaultPopup));
