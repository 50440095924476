import { ConvertionTaskStatus, LayerGroupStatus } from "@orbit/geo-core-shared";
import { red, orange, grey, green, blue } from "@material-ui/core/colors";

declare global {
  interface Window {
    env: any;
  }
}

window.env = window.env || {};

export enum APP_LOCALES {
  NL = "nl",
  FR = "fr",
  EN = "en",
  DE = "de",
}

export const DEFAULT_LOCALE = window.env.DEFAULT_LANGUAGE || APP_LOCALES.NL;

export const MAP_TYPES = {
  WMS: "wmt",
  WMTS: "wmts",
  TILE: "tile",
};
export enum BASELAYER_TYPES {
  WMTS = "wmts",
  VECTOR = "vector",
}
export const MAP_TILE_MATRIX = {
  GOOGLE: "GoogleMapsVL",
  G20: "g20",
};

export const MAP_TILE_FORMAT = {
  PNG: "image/png",
};

export const MAP_CONFIG = {
  zoom: window.env.MAP_WMTS_TILE_LAYER_DEFAULT_ZOOM || 15,
};

export const HEADER_CONFIG = {
  SEARCH_ENABLED: window.env.SEARCH_ENABLED || true,
  SEARCH_RESULT_COUNT: 8,
};

export const SIDEBAR_CONFIG = {
  width: 350,
};

export const Z_INDEXES = {
  MAP_PUBLIC_WMTS_TILE_LAYER: 100,
  MAP_PUBLIC_TILE_LAYER: 101,
  MAP_PUBLIC_WMTS_TILE_LAYER_ORG: 102,
  MAP_ORBIT_LOGO: 1000,
};

export const getPublicationStatusLabel = (status: LayerGroupStatus) => {
  switch (status) {
    case LayerGroupStatus.FINISHED:
      return "Gekoppelde datagroep is klaar";
    case LayerGroupStatus.ERROR:
      return "Gekoppelde datagroep is gefaald";
    case undefined:
      return "Klaar voor gebruik";
    default:
      return "Gekoppelde datagroep is in verwerking";
  }
};

export const getLayerGroupStatusLabel = (status: LayerGroupStatus) => {
  switch (status) {
    case LayerGroupStatus.CREATING:
      return "Aanmaken van datagroep";
    case LayerGroupStatus.READY:
      return "Datagroep klaar voor verwerking";
    case LayerGroupStatus.QUEUED:
      return "Datagroep staat in wachtrij";
    case LayerGroupStatus.SYNCING_LAYER_GROUP_DATA:
      return "Synchroniseren van datagroep data";
    case LayerGroupStatus.CREATING_GEOJSON:
      return "Aanmaken van GeoJSON";
    case LayerGroupStatus.SYNCING_ELASTICSEARCH:
      return "Synchroniseren met elasticsearch";
    case LayerGroupStatus.CREATING_MBTILES:
      return "Aanmaken van MBTiles";
    case LayerGroupStatus.SYNCING_TILESERVER:
      return "Synchroniseren van tileserver";
    case LayerGroupStatus.CREATING_THUMBNAIL:
      return "Aanmaken van thumbnail";
    case LayerGroupStatus.FINISHED:
      return "Datagroep is klaar";
    case LayerGroupStatus.ERROR:
      return "Er is iets fout gegaan";
    default:
      return "Aan het verwerken...";
  }
};

export const getLayerGroupStatusColor = (status: LayerGroupStatus) => {
  switch (status) {
    case LayerGroupStatus.QUEUED:
      return blue[500];
    case LayerGroupStatus.CREATING:
    case LayerGroupStatus.READY:
      return grey[500];
    case LayerGroupStatus.FINISHED:
      return green[500];
    case LayerGroupStatus.ERROR:
      return red[500];
    default:
      return orange[500];
  }
};

export const getConvertionTaskStatusColor = (status: ConvertionTaskStatus) => {
  switch (status) {
    case ConvertionTaskStatus.CREATING:
      return grey[500];
    case ConvertionTaskStatus.READY:
      return blue[500];
    case ConvertionTaskStatus.FINISHED:
      return green[500];
    case ConvertionTaskStatus.ERROR:
      return red[500];
    default:
      return orange[500];
  }
};

export const getConvertionTaskStatusLabel = (status: ConvertionTaskStatus) => {
  switch (status) {
    case ConvertionTaskStatus.CREATING:
      return "Aanmaken van datalaag";
    case ConvertionTaskStatus.READY:
      return "Datalaag klaar voor verwerking";
    case ConvertionTaskStatus.BACKUPING_DATA:
      return "Back-uppen van vorige data";
    case ConvertionTaskStatus.IMPORTING_GEOMETRY:
      return "Importeren van geometrie";
    case ConvertionTaskStatus.IMPORTING_CSV:
      return "Importeren van CSV";
    case ConvertionTaskStatus.IMPORTING_IMAGE:
      return "Importeren van image";
    case ConvertionTaskStatus.REINDEXING_MARTIN:
      return "Indexering van Martin";
    case ConvertionTaskStatus.SYNCING_CONVERTION_TASK_DATA:
      return "Synchroniseren van datalaag data";
    case ConvertionTaskStatus.CREATING_THUMBNAIL:
      return "Aanmaken van thumbnail";
    case ConvertionTaskStatus.SYNCING_LINKED_DB_VIEW:
      return "Synchroniseren van database view";
    case ConvertionTaskStatus.FINISHED:
      return "Datalaag is klaar";
    case ConvertionTaskStatus.ERROR:
      return "Er is iets fout gegaan";
    default:
      return "Aan het verwerken...";
  }
};
