import {
  Button,
  Checkbox,
  createStyles,
  FormControlLabel,
  FormGroup,
  Grid,
  InputBase,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import { SpatialAnalysisRelations } from "@orbit/geo-core-shared";
import { setUseProxies } from "immer";
import { StoresContext } from "index";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import SearchModel from "stores/models/SearchModel";
import { baseLayerModel } from "views/Map/BaseMap";
import SimpleTable from "../../../../views/Table/SimpleTable";
import { SearchDatePicker } from "./DatePicker";
import { InjectedIntl, injectIntl } from "react-intl";
import messages from "./messages";

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }),
)(InputBase);

export const useContentStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: "grid",
      gridTemplateRows: "auto minmax(0, 1fr)",
      height: "95vh",
    },
  }),
);

export const searchModel = new SearchModel();
export const SidebarSearch = observer(({ intl: { formatMessage } }: { intl: InjectedIntl }) => {
  const contentClasses = useContentStyles();
  const [drawCheckbox, setDrawCheckbox] = React.useState(false);

  const {
    publicationStore: { activeSearchFields: searchFields, activePublication },
    enrichmentStore: { isSearchable: enrichmentSearchEnabled, name, id: enrichmentId },
    mapStore: { editableGeojson },
    publicationStore,
  } = useContext(StoresContext);

  const {
    search,
    searchEnrichment,
    paging,
    value,
    bufferValue,
    setBufferValue,
    exactQuery,
    setExactQuery,
    setValue,
    setFeatures,
    relation,
    setRelation,
    searchResult,
    filterIndex,
    setFilterIndex,
    setPublication,
  } = searchModel;

  const publicationSearchEnabled = Object.keys(activePublication?.searchFields || {}).length > 0;

  const searchEnabled = publicationSearchEnabled || enrichmentSearchEnabled;

  useEffect(() => {
    setPublication(activePublication);
  }, [activePublication]);

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFeatures([]);

    if (drawCheckbox && editableGeojson?.features?.length) {
      setFeatures(toJS(editableGeojson).features);
    }

    /**
     * filterindex of an enrichment is always -1
     */
    if ((enrichmentId !== null && filterIndex === -1) || !publicationSearchEnabled) {
      searchEnrichment(enrichmentId, true);
    } else {
      search();
    }
  };

  if (!searchEnabled) {
    return (
      <Grid container direction="row" justify="flex-start" alignItems="center" spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            {formatMessage(messages.searchSearchInMap)}
          </Typography>
        </Grid>
        <Typography gutterBottom>
          <Grid item xs={12}>
            {formatMessage(messages.searchNoSearchableMapLayers)}
          </Grid>
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      <form className={contentClasses.form} onSubmit={handleSearch}>
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              {formatMessage(messages.searchSearchInMap)}
            </Typography>
          </Grid>
          <>
            <Grid item xs={12}>
              <TextField
                required={!drawCheckbox}
                fullWidth
                value={value}
                id="outlined-basic"
                label={formatMessage(messages.searchSearchEnglish)}
                variant="outlined"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setValue(event.target.value);
                }}
              />
              <FormControlLabel control={<Checkbox checked={exactQuery} onChange={() => setExactQuery(!exactQuery)} />} label={formatMessage(messages.searchExactSearch)} />
            </Grid>

            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={drawCheckbox} onChange={() => setDrawCheckbox(!drawCheckbox)} />}
                  label={formatMessage(messages.searchSpatialAnalyse)}
                />
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Select
                      name="relatie"
                      fullWidth
                      variant="outlined"
                      value={relation.toLowerCase()}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setRelation(event.target.value as SpatialAnalysisRelations);
                      }}
                      disabled={!drawCheckbox}
                      style={{ marginBottom: 10 }}
                    >
                      {Object.keys(SpatialAnalysisRelations).map((sr) => (
                        <MenuItem key={sr} value={sr}>
                          {SpatialAnalysisRelations[sr]}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      inputProps={{
                        step: "0.1",
                      }}
                      fullWidth
                      type="number"
                      value={bufferValue}
                      label={formatMessage(messages.searchBuffer)}
                      variant="outlined"
                      disabled={!drawCheckbox}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setBufferValue(event.target.value as string);
                      }}
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                value={publicationSearchEnabled ? filterIndex : -1}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setFilterIndex(event.target.value as number);
                }}
                input={<BootstrapInput />}
              >
                {searchFields.map((item, index) => (
                  <MenuItem value={index}>{item.name}</MenuItem>
                ))}
                {enrichmentSearchEnabled && <MenuItem value={-1}>{name}</MenuItem>}
              </Select>
            </Grid>

            {/* {publicationStore?.searchDateFields[filterIndex]?.length > 0 && (
              <Grid item xs={12}>
                {publicationStore.searchDateFields[filterIndex].map(item => (
                  <SearchDatePicker key={item.key} item={item} />
                ))}
              </Grid>
            )} */}

            <Grid item xs={12}>
              <Button disabled={drawCheckbox ? !editableGeojson?.features?.length : !value} color="primary" type="submit" fullWidth variant="outlined">
                {formatMessage(messages.searchSearch)}
              </Button>
            </Grid>
          </>
        </Grid>
        <Grid item xs={12} style={{ overflow: "scroll" }}>
          {searchResult && (
            <SimpleTable
              action={({ bbox, ogc_fid }) => {
                baseLayerModel.setBBox(bbox);
                baseLayerModel.setSelectedGeomId(ogc_fid);
              }}
              pagination
              pageSize={paging.pageSize}
              autoHeight={false}
              hideFooterRowCount
              data={searchResult}
            />
          )}
        </Grid>
      </form>
    </>
  );
});

injectIntl(SidebarSearch);
