import { makeAutoObservable, toJS } from "mobx";

export class FilterType {
  property: string = "";
  operator: string = "";
  value1: string = "";
  value2: string = "";

  constructor(property, operator, value1 = "", value2 = "") {
    makeAutoObservable(this);
    this.property = property;
    this.operator = operator;
    this.value1 = value1;
    this.value2 = value2;
  }

  setProperty(property) {
    this.property = property;
  }

  setOperator(operator) {
    this.operator = operator;
  }

  setValue1(value) {
    this.value1 = value;
  }

  setValue2(value) {
    this.value2 = value;
  }
}

export class FilterListType {
  filters: FilterType[] = [];

  get hasFilters() {
    return this.filters.length > 0;
  }

  addFilter(property, operator) {
    this.filters.push(new FilterType(property, operator));
  }

  addFilters(filters) {
    this.filters = filters.map(([property, operator]) => new FilterType(property, operator));
  }

  removeFilter(index) {
    this.filters.splice(index, 1);
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export class FeatureType {
  featureType: {
    abstract: string;
    comparisonOperator: string[];
    defaultCRS: string;
    geometryName: string;
    keywords: { keyword: string[] };
    metadataURL: { "@_href": string }[];
    name: string;
    otherCRS: string[];
    properties: string[];
    title: string;
    type: string;
    WGS84BoundingBox: {
      lowerCorner: string;
      upperCorner: string;
    };
  }[] = [];
  borderColor: string = "#000000";
  fillColor: string = "#ffffff";
  opacity: number = 100;
  editStyle: boolean = false;
  editFilter: boolean = false;
  enabled: boolean = false;
  filterList: FilterListType = new FilterListType();

  constructor(feature: any) {
    makeAutoObservable(this);
    this.featureType = feature;
  }

  toggleEditStyle() {
    this.editStyle = !this.editStyle;
    if (this.editFilter) this.editFilter = false;
  }

  toggleEditFilter() {
    this.editFilter = !this.editFilter;
    if (this.editStyle) this.editStyle = false;
  }

  toggleEnabled() {
    this.enabled = !this.enabled;
  }

  setEnabled(value) {
    this.enabled = value;
  }

  setFillColor(color) {
    this.fillColor = color;
  }

  setBorderColor(color) {
    this.borderColor = color;
  }

  setOpacity(newValue) {
    this.opacity = newValue;
  }
}

export class FeatureTypeList {
  featureTypes: FeatureType[] = [];

  get hasFeatures() {
    return this.featureTypes.length > 0;
  }

  get allEnabled() {
    return this.featureTypes.filter((featureType) => featureType.enabled).length === this.featureTypes.length;
  }

  get hasFeaturesEnabled() {
    return this.featureTypes.filter((ft) => ft.enabled).length > 0;
  }

  addFeatures(features) {
    this.featureTypes = features.map((feature) => new FeatureType(feature));
    //console.log(this.featureTypes);
  }

  addExistingFeatures(features) {
    features.forEach((f) => {
      const existingFeature = this.featureTypes.find((ft) => ft.featureType.Name === f.featureType.Name);
      if (existingFeature) {
        existingFeature.enabled = true;
        existingFeature.borderColor = f.borderColor;
        existingFeature.fillColor = f.fillColor;
        existingFeature.opacity = f.opacity;
        existingFeature.filterList.filters = f.filterList.filters.map(({ property, operator, value1, value2 }) => new FilterType(property, operator, value1, value2));
      }
    });
  }

  toggleAll() {
    if (!this.allEnabled) {
      this.featureTypes.forEach((featureType) => {
        //console.log("setting enabled true for ", featureType);
        featureType.setEnabled(true);
      });
    } else {
      this.featureTypes.forEach((featureType) => featureType.setEnabled(false));
    }
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export class LayerType {
  layerType: any = {};
  enabled: boolean = false;

  constructor(layer: any) {
    makeAutoObservable(this);
    this.layerType = layer;
  }

  toggleEnabled() {
    this.enabled = !this.enabled;
  }

  setEnabled(value) {
    this.enabled = value;
  }
}

export class LayerTypeList {
  layerTypes: LayerType[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get hasLayers() {
    return this.layerTypes.length > 0;
  }

  get allEnabled() {
    return this.layerTypes.filter((layerType) => layerType.enabled).length === this.layerTypes.length;
  }

  get hasLayersEnabled() {
    return this.layerTypes.filter((lt) => lt.enabled).length > 0;
  }

  addLayers(layers) {
    this.layerTypes = layers.map((layer) => new LayerType(layer));
    //console.log(this.layerTypes);
  }

  addExistingLayers(layers) {
    layers.map((l) => {
      const existingLayer = this.layerTypes.filter((lt) => lt.layerType.Name === l.layerType.Name);
      if (existingLayer.length > 0) {
        existingLayer[0].enabled = true;
      }
    });
  }

  toggleAll() {
    if (!this.allEnabled) {
      this.layerTypes.forEach((layerType) => {
        //console.log("setting enabled true for ", featureType);
        layerType.setEnabled(true);
      });
    } else {
      this.layerTypes.forEach((layerType) => layerType.setEnabled(false));
    }
  }
}
