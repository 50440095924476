import { Theme, createStyles } from "@material-ui/core/styles";

const useDatasetsStyles = (theme: Theme) =>
  createStyles({
    floatingButton: {
      float: "right",
    },
    searchField: {
      marginTop: 10,
    },
  });

export default useDatasetsStyles;
