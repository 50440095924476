/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  loading: {
    id: "sso.session.loading",
    defaultMessage: "Aan het laden...",
  },
  error: {
    id: "sso.session.error",
    defaultMessage: "Er liep iets mis bij het aanmelden, probeer het later opnieuw.",
  },
  btnAgain: {
    id: "sso.session.btn.again",
    defaultMessage: "Probeer opnieuw",
  },
});
