export const heatmapLayer = {
  id: "heatmap-enrichment",
  source: "orbit-source-enrichment",
  maxzoom: 16,
  type: "heatmap",
  paint: {
    // Increase the heatmap weight based on frequency and property magnitude
    "heatmap-weight": ["interpolate", ["linear"], ["zoom"], 11, 1, 18, 10],
    // Increase the heatmap color weight weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparancy color
    // to create a blur-like effect.
    "heatmap-color": [
      "interpolate",
      ["linear"],
      ["heatmap-density"],
      0,
      "rgba(33,102,172,0)",
      0.2,
      "rgb(103,169,207)",
      0.4,
      "rgb(209,229,240)",
      0.6,
      "rgb(253,219,199)",
      0.8,
      "rgb(239,138,98)",
      0.9,
      "rgb(255,201,101)",
    ],
    // Adjust the heatmap radius by zoom level
    "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 11, 90, 18, 5],
    // Transition from heatmap to circle layer by zoom level
    "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 11, 0.7, 18, 0.4],
  },
};

export const clusterLayer = {
  id: "clusters",
  type: "circle",
  source: "orbit-source-enrichment",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": ["step", ["get", "point_count"], "#51bbd6", 100, "#f1f075", 750, "#f28cb1"],
    "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
  },
};

export const clusterCountLayer = {
  id: "cluster-count",
  type: "symbol",
  source: "orbit-source-enrichment",
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count}",
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 12,
  },
};

export const defaultLayerStyle = {
  id: "unclustered-point",
  type: "symbol",
  source: "orbit-source-enrichment",
  minZoom: 15,
  maxzoom: 24,
  paint: {
    "icon-color": "#ff6600",
  },
  layout: {
    visibility: "visible",
    "icon-image": "information_11",
    "icon-allow-overlap": true,
    "icon-size": 1.5,
  },
};

export const createCombinedEnrichmentStyle = (enrichmentGeoJson, enrichmentName, publicationStyle) => {
  const source = {
    "orbit-source-enrichment": {
      type: "geojson",
      data: enrichmentGeoJson,
    },
  };

  const layerStyles = [
    {
      id: enrichmentName,
      type: "symbol",
      paint: {
        "icon-color": "#ff6600",
      },
      source: "orbit-source-enrichment",
      layout: {
        visibility: "visible",
        "icon-image": "information_11",
        "icon-allow-overlap": true,
        "icon-size": 1.5,
      },
    },
  ];
  return {
    ...publicationStyle,
    sources: {
      ...publicationStyle?.sources,
      ...source,
    },
    layers: [...(publicationStyle?.layers || []), ...layerStyles],
  };
};
