/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  searchSearchInMap: {
    id: "search.search.in.map",
    defaultMessage: "Zoeken in de map",
  },
  searchNoSearchableMapLayers: {
    id: "search.no.searchable.map.layers",
    defaultMessage: "Er zijn geen kaartlagen zoekbaar",
  },
  searchSearchEnglish: {
    id: "search.search.english",
    defaultMessage: "Search",
  },
  searchExactSearch: {
    id: "search.exact.search",
    defaultMessage: "Exact zoeken",
  },
  searchSpatialAnalyse: {
    id: "search.spatial.analyse",
    defaultMessage: "Ruimtelijke analyse (o.b.v. intekening)",
  },
  searchBuffer: {
    id: "search.buffer",
    defaultMessage: "Buffer (m)",
  },
  searchSearch: {
    id: "search.search",
    defaultMessage: "Zoek...",
  },
  searchField: {
    id: "search.field",
    defaultMessage: "Veld",
  },
  searchSearchInField: {
    id: "search.search.in.field",
    defaultMessage: "Zoeken in veld",
  },
  searchAlias: {
    id: "search.alias",
    defaultMessage: "Alias",
  },
  searchHyperlink: {
    id: "search.hyperlink",
    defaultMessage: "Hyperlink",
  },
  searchDisplayInResult: {
    id: "search.display.in.result",
    defaultMessage: "Weergeven in resultaat",
  },
  searchDisplayInPopup: {
    id: "search.display.in.popup",
    defaultMessage: "Weergeven in popup",
  },
  searchThan: {
    id: "search.than",
    defaultMessage: "dan",
  },
  searchReset: {
    id: "search.reset",
    defaultMessage: "Reset",
  },
  searchDescription: {
    id: "search.description",
    defaultMessage: "Description",
  },
  searchUnitCost: {
    id: "search.unit.cost",
    defaultMessage: "Unit Cost",
  },
  searchQtyRate: {
    id: "search.qty.rate",
    defaultMessage: "Qty/Rate",
  },
  searchAmount: {
    id: "search.amount",
    defaultMessage: "Amount",
  },
  searchSelectAll: {
    id: "search.select.all",
    defaultMessage: "Alles selecteren",
  },
  linkEditTitle: {
    id: "link.edit.title",
    defaultMessage: "Link bewerken",
  },
  linkEditMessage: {
    id: "link.edit.message",
    defaultMessage: "Vervang in de URL de delen die je wilt vervangen met [veldNaam] om het dynamisch te maken.",
  },
  linkEditDeleteButton: {
    id: "link.edit.delete.button",
    defaultMessage: "Link verwijderen",
  },
  linkEditCancelButton: {
    id: "link.edit.cancel.button",
    defaultMessage: "Annuleren",
  },
  linkEditSaveButton: {
    id: "link.edit.save.button",
    defaultMessage: "Link opslaan",
  },
  linkDeleteTitle: {
    id: "link.delete.title",
    defaultMessage: "Link verwijderen",
  },
  linkDeleteMessage: {
    id: "link.delete.message",
    defaultMessage: "Weet u zeker dat u de link wilt verwijderen?",
  },
  linkDeleteCancelButton: {
    id: "link.delete.cancel.button",
    defaultMessage: "Annuleren",
  },
  linkDeleteButton: {
    id: "link.delete.button",
    defaultMessage: "Link verwijderen",
  },
});
