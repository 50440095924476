import { EnrichmentActionType, EnrichmentDataItem, EnrichmentMessageObject, EnrichmentMessageType, PublicationEnrichment } from "@orbit/geo-core-shared";
import GeoJSON from "geojson";
import { action, makeObservable, observable } from "mobx";
import io from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import { BBox } from "@turf/helpers";
import { fetchEnrichments } from "services/geo-core";

const { API_ENRICHMENT_SOCKET = "http://localhost:3024" } = window.env;

export type enrichmentGeoJson = GeoJSON.Feature<GeoJSON.Geometry> | GeoJSON.FeatureCollection<GeoJSON.Geometry> | null;
export default class EnrichmentStore {
  id: string | null = null;
  name: string | null = null;
  clientId: string | null = null;
  socket: SocketIOClient.Socket | null = null;
  geoJson: enrichmentGeoJson;
  bbox: BBox;
  lastAdditions: EnrichmentDataItem[] | null | undefined = null;
  lastRemovals: string[] | null | undefined = null;
  isSearchable: boolean = false;
  enrichments: PublicationEnrichment[] = [];

  constructor() {
    makeObservable(this, {
      id: observable,
      name: observable,
      geoJson: observable,
      lastAdditions: observable,
      isSearchable: observable,
      bbox: observable,
      enrichments: observable,
      connectToSocket: action.bound,
      setGeoJson: action.bound,
      setLastAdditions: action.bound,
      setLastRemovals: action.bound,
      loadEnrichments: action.bound,
    });
  }

  loadEnrichments = async () => {
    const _enrichments = await fetchEnrichments();
    this.enrichments = _enrichments;
  };

  connectToSocket = (enrichmentId: string) => {
    console.log("try to connect to ", enrichmentId, API_ENRICHMENT_SOCKET);
    this.clientId = uuidv4();
    this.id = enrichmentId;

    this.socket = io(API_ENRICHMENT_SOCKET, {
      query: `id=${enrichmentId}&clientId=${this.clientId}`,
    });

    // Connection failed
    this.socket.on(EnrichmentMessageType.ERROR, (err) => {
      console.log(err.toString());
    });

    // Connection succeeded
    this.socket.on(EnrichmentMessageType.INIT, (rawData: EnrichmentMessageObject) => {
      console.log(`[Enrichment socket connected id=${enrichmentId}]`);
      console.log("INIT", rawData);
      this.setGeoJson(rawData?.data?.geojson);
      this.isSearchable = Object.keys(rawData?.data?.ESMapping || {}).length > 0;
      this.name = rawData?.name || "Verrijkingslaag";
    });

    // Set updates active
    this.socket.on(EnrichmentMessageType.UPDATE, (rawData: EnrichmentMessageObject) => {
      console.log("UPDATE", rawData);
      if (rawData?.geojson) {
        this.setGeoJson(rawData?.geojson);
      }

      if (rawData.bbox) {
        this.bbox = rawData.bbox;
      }

      if (rawData.action === EnrichmentActionType.CLEAR) {
        return; // should we do something here?
      }

      if (rawData.action === EnrichmentActionType.ADD) {
        return this.setLastAdditions(rawData.additions);
      }

      if (rawData.action === EnrichmentActionType.REMOVE) {
        return this.setLastRemovals(rawData.removals);
      }

      if (rawData.action === EnrichmentActionType.ZOOM) {
        // @todo: do something with actionItems (maybe show popup when length === 1)
      }
    });
  };

  setGeoJson = (geojson: enrichmentGeoJson) => {
    this.geoJson = geojson;
  };

  setLastAdditions = (update: EnrichmentDataItem[] | null | undefined) => {
    this.lastAdditions = update;
  };

  setLastRemovals = (update: string[] | null | undefined) => {
    this.lastRemovals = update;
  };
}
