import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useTransferLayerStyles = (theme: Theme) =>
  createStyles({
    floatingButton: {
      float: "right",
    },
    searchField: {
      marginTop: 10,
    },
    marginBottom: {
      marginBottom: 10,
    },
    dialogContent: {
      padding: "0 !important",
    },
    tabs: {
      color: "#ffffff",
    },
    dialogPaper: {
      padding: theme.spacing(2),
    },
    dialogTextField: {
      marginBottom: theme.spacing(1),
    },
  });

export default useTransferLayerStyles;

export const useFeatureTypeStyles = makeStyles((theme) => ({
  abstractHelp: {
    display: "inline-flex",
    fontSize: "1.1rem",
    boxSizing: "border-box",
    alignItems: "center",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    justifyContent: "center",
    textDecoration: "none",
    color: "#616161",
  },
  styleTable: {
    padding: "0px 12px 0px 12px !important",
  },
  test: {
    "& div:first-child": {
      width: "100% !important",
    },
  },
}));
