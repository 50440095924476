import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { withStyles } from "@material-ui/styles";
import HelpIcon from "@mui/icons-material/Help";
import clsx from "clsx";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import { useLocation, useParams } from "react-router";
import { useClipboard } from "use-clipboard-copy";
import { getPathFromUrl } from "utils/functions";
import BaseMap from "views/Map/BaseMap";
import { fetchWfsCapabilities, fetchWfsFeatureInfo, fetchWmsCapabilities } from "../../../services/transfer";
import messages from "./messages";
import { style } from "./style";
import { FeatureTypeList, LayerTypeList } from "./TransferLayerClasses";
import useTransferLayerDetailStyles from "./TransferLayerDetailStyles";
import { FeatureTypeListView, LayerTypeListView } from "./TransferLayerViews";
let wfsCapabilities = new FeatureTypeList();
let wmsCapabilities = new LayerTypeList();

const TransferLayerDetail: FunctionComponent<{
  classes: any;
  intl: InjectedIntl;
}> = observer(({ classes, intl: { formatMessage }, intl }) => {
  const {
    baseLayerStore: { baseLayers, loadBaseLayers },
    transferLayerStore: { transferLayers, activeTransferLayer, fetchActiveTransferLayer, clearActiveTransferLayer, updateTransferLayer, updateTransferLayerShareConfig },
    authStore: { canReadAdminSettings, canEditAdminSettings },
  } = useContext(StoresContext);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);
  const [shareConfigConfirmDialogOpen, setShareConfigConfirmDialogOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [URL, setURL] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [outputFormat, setOutputFormat] = useState<string>("");
  const [wfsFlip, setWfsFlip] = useState<boolean>(false);
  const [zoomlevel, setZoomlevel] = useState<number>();
  const [previousURL, setPreviousURL] = useState<string>("");
  const { search } = useLocation();
  const wfsId = new URLSearchParams(search).get("wfsId");
  const { id } = useParams<{ id: string }>();
  const clipboard = useClipboard();
  const linkedPublicationsOtherOrgs = activeTransferLayer?.linkedPublications?.filter?.((lp) => lp.organizationId !== activeTransferLayer?.user?.organization?.id) || [];

  useEffect(() => {
    if (!id) {
      return;
    }
    if (!baseLayers.length) loadBaseLayers();
    fetchActiveTransferLayer(id);
    return () => {
      clearActiveTransferLayer();
    };
  }, [transferLayers, id]);

  useEffect(() => {
    if (activeTransferLayer) {
      setName(activeTransferLayer.name);
      setURL(activeTransferLayer.url);
      setPassword(activeTransferLayer.password);
      setUsername(activeTransferLayer.username);
      setOutputFormat(activeTransferLayer.outputFormat || "");
      setZoomlevel(activeTransferLayer.zoomlevel);
      setWfsFlip(activeTransferLayer.flipCoordinates || false);
      validate(activeTransferLayer.type, activeTransferLayer.url, activeTransferLayer.username, activeTransferLayer.password);
    }
  }, [activeTransferLayer]);

  const validate = async (type, url, username, password) => {
    const rootUrl = getPathFromUrl(url);
    if (type === "wfs") {
      if (rootUrl !== previousURL) {
        wfsCapabilities = new FeatureTypeList();
      }
      setPreviousURL(rootUrl);
      setLoading(true);
      let { FeatureType, ComparisonOperator, outputFormat } = await fetchWfsCapabilities(rootUrl, username, password);

      if (outputFormat === "NOT SUPPORTED") {
        setLoading(false);
        console.log("Output format not supported");
        return;
      }

      FeatureType = Array.isArray(FeatureType) ? FeatureType : [FeatureType];

      if (FeatureType) {
        // ook al geeft hij hieronder een error, het werkt wel: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled
        //@ts-ignore
        await Promise.allSettled(
          FeatureType.map((featureType) => {
            return new Promise(async (resolve, reject) => {
              const featureInfo = await fetchWfsFeatureInfo(featureType.Name, rootUrl, username, password, outputFormat);
              if (featureInfo) {
                resolve(featureInfo);
              } else {
                reject(featureType.Name);
              }
            });
          }),
        ).then((results) => {
          results.forEach((result, index) => {
            if (result.value === undefined) {
              // No idea if we come here
              console.log("Can't decide what geometry it is");
              FeatureType[index].Type = "no-type";
            } else {
              const { value } = result;
              FeatureType[index].Type = value?.geometry?.type || "no-type";
              FeatureType[index].Properties = Object.keys(value?.properties || {});
              FeatureType[index].GeometryName = value?.geometry_name || "no-name";
              FeatureType[index].ComparisonOperator = ComparisonOperator;
            }
          });
        });
        setOutputFormat(outputFormat);
        // remove Type 'no-type' from FeatureType
        FeatureType = FeatureType.filter((feature) => feature.Type !== "no-type");
        wfsCapabilities.addFeatures(FeatureType);
        wfsCapabilities.addExistingFeatures(activeTransferLayer?.featureTypes);
        setLoading(false);
      }
    } else {
      console.log("validating wms");
      if (rootUrl !== previousURL) {
        wmsCapabilities = new LayerTypeList();
      }
      setPreviousURL(rootUrl);
      setLoading(true);
      const capabilities = await fetchWmsCapabilities(rootUrl, username, password);
      if (capabilities) {
        let newCapabilities: any[] = [];
        capabilities.forEach((capability) => {
          if (capability?.Layer && Array.isArray(capability?.Layer)) {
            newCapabilities = [...newCapabilities, ...capability.Layer];
          } else if (capability?.Layer) {
            newCapabilities = [...newCapabilities, capability.Layer];
          } else {
            newCapabilities = [...newCapabilities, capability];
          }
        });
        wmsCapabilities.addLayers(newCapabilities);
        wmsCapabilities.addExistingLayers(activeTransferLayer?.featureTypes);
        setLoading(false);
      }
    }
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const openFilterDialog = () => {
    setFilterDialogOpen(true);
  };

  const closeFilterDialog = () => {
    setFilterDialogOpen(false);
  };

  const handleWfsFlipChange = (event) => {
    setWfsFlip(event.target.checked);
  };

  const handleShareConfigConfirmDialogClose = () => {
    setShareConfigConfirmDialogOpen(false);
  };

  const handleShareConfigConfirmDialogSave = () => {
    setShareConfigConfirmDialogOpen(false);
    handleShareConfigChange({ enabled: false });
  };

  const triggerSave = async () => {
    await updateTransferLayer(id, {
      name,
      url: URL,
      featureTypes: activeTransferLayer?.type === "wfs" ? wfsCapabilities.featureTypes : wmsCapabilities.layerTypes,
      username,
      password,
      zoomlevel,
      flipCoordinates: wfsFlip,
    });
    closeDialog();
  };

  const handleValidation = () => {
    return name.length > 0 && URL.length > 0 && (activeTransferLayer?.type === "wfs" ? wfsCapabilities.hasFeaturesEnabled : wmsCapabilities.hasLayersEnabled);
  };

  const handleShareConfigChange = async (shareConfig: { enabled: boolean }) => {
    await updateTransferLayerShareConfig(id, shareConfig);
  };

  return (
    <Fragment>
      <Grid container spacing={6}>
        <Grid item xs={8}>
          <Typography variant="h4" gutterBottom>
            {formatMessage(messages.transferlayerDetails)} {activeTransferLayer?.type?.toUpperCase()}: {activeTransferLayer?.name}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {formatMessage(messages.transferlayerPublishedOn)} {activeTransferLayer && new Date(activeTransferLayer.dateCreated).toLocaleDateString("nl-BE")}
          </Typography>

          <div style={{ width: "100%", height: 500 }}>
            {wfsId ? (
              <BaseMap
                latLng={{
                  lat: 50.98935,
                  lng: 3.87118,
                }}
                mapStyle={style}
                // baseLayers={baseLayers}
                // wmsLayers={(activeTransferLayer?.type === "wms" && [activeTransferLayer]) || []}
                // wfsLayers={(activeTransferLayer?.type === "wfs" && [activeTransferLayer]) || []}
              />
            ) : (
              <BaseMap
                latLng={{
                  lat: 50.98935,
                  lng: 3.87118,
                }}
                baseLayers={baseLayers}
                wmsLayers={(activeTransferLayer?.type === "wms" && [activeTransferLayer]) || []}
                wfsLayers={(activeTransferLayer?.type === "wfs" && [activeTransferLayer]) || []}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.urlContainer}>
            <Typography variant="h5" gutterBottom>
              {activeTransferLayer?.type?.toUpperCase()} url
            </Typography>
            <FormControl className={clsx(classes.margin, classes.textField)} fullWidth>
              <Input
                id="url"
                type="text"
                fullWidth
                value={activeTransferLayer?.url || ""}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label={formatMessage(messages.transferlayerCopyURL)} onClick={() => clipboard.copy(activeTransferLayer?.url)}>
                      <FileCopyIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          {canReadAdminSettings && (
            <>
              <Typography variant="h5" gutterBottom>
                {formatMessage(messages.transferlayerShareConfig)}
              </Typography>
              <Grid container spacing={2} className={classes.urlContainer}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ marginLeft: 10 }}
                      checked={activeTransferLayer?.shareConfig?.enabled || false}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.checked || linkedPublicationsOtherOrgs.length === 0) {
                          handleShareConfigChange({ enabled: e.target.checked });
                        } else {
                          console.table(JSON.parse(JSON.stringify(linkedPublicationsOtherOrgs)));
                          setShareConfigConfirmDialogOpen(true);
                        }
                      }}
                      disabled={!canEditAdminSettings}
                    />
                  }
                  label={<Typography>{formatMessage(messages.transferlayerShareConfigCheckbox)}</Typography>}
                />
              </Grid>
            </>
          )}
          <div className={classes.urlContainer}>
            <Button className={classes.urlButton} onClick={openDialog} variant="outlined" size="small" startIcon={<EditIcon />}>
              {formatMessage(messages.transferlayerModify)}
            </Button>
            <Typography variant="h5" gutterBottom>
              {formatMessage(messages.transferlayerActiveLayers)}
            </Typography>
            <List dense disablePadding>
              {activeTransferLayer?.featureTypes?.map((ft, index) => (
                <ListItem dense key={index} style={{ padding: 0 }}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" style={{ fontSize: "0.813rem" }}>
                        {activeTransferLayer?.type === "wfs" ? ft.featureType.Name : ft.layerType.Name}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="caption" style={{ fontSize: "0.688rem" }}>
                        {activeTransferLayer?.type === "wfs" ? ft.featureType.Title : ft.layerType.Title}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>

      <Dialog open={dialogOpen} onClose={closeDialog} disableBackdropClick disableEscapeKeyDown>
        <DialogTitle>
          <Typography>
            {formatMessage(messages.transferlayerEdit)} {activeTransferLayer?.type?.toUpperCase()}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid className={classes.dialogPaper} container>
            <Grid item xs={12}>
              <TextField
                className={classes.dialogTextField}
                required
                id="wfsName"
                label={formatMessage(messages.transferlayerName)}
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField disabled className={classes.dialogTextField} required id="wfsUrl" label={formatMessage(messages.transferlayerURL)} value={URL} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField disabled className={classes.dialogTextField} id="wfsUsername" label={formatMessage(messages.transferlayerUsername)} value={username} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField disabled className={classes.dialogTextField} id="wfsPassword" label={formatMessage(messages.transferlayerPassword)} value={password} fullWidth />
            </Grid>
            {activeTransferLayer?.type === "wfs" && (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={wfsFlip} onChange={handleWfsFlipChange} name="wfsFlip" />}
                    label={
                      <Typography>
                        Flip coördinaten{" "}
                        <Tooltip title="Voor sommige WFS'en moeten de coördinaten omgedraaid worden om te laten werken." aria-label="add">
                          <HelpIcon fontSize="small" />
                        </Tooltip>
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">{formatMessage(messages.transferlayerMinZoom)}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Slider aria-label="Zoom-level" defaultValue={zoomlevel} valueLabelDisplay="auto" onChange={(_, value) => setZoomlevel(value)} step={1} marks min={6} max={24} />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              {activeTransferLayer?.type === "wfs" && wfsCapabilities.hasFeatures && <FeatureTypeListView featureTypeList={wfsCapabilities} intl={intl} />}
              {activeTransferLayer?.type === "wms" && wmsCapabilities.hasLayers && <LayerTypeListView intl={intl} layerTypeList={wmsCapabilities} />}
            </Grid>
          </Grid>
        </DialogContent>
        {loading && <LinearProgress />}
        <DialogActions>
          <Button onClick={closeDialog}>{formatMessage(messages.transferlayerCancel)}</Button>
          <Button variant="contained" color="primary" onClick={triggerSave} disabled={!handleValidation()}>
            {formatMessage(messages.transferlayerModify)}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={filterDialogOpen} onClose={closeFilterDialog} disableBackdropClick disableEscapeKeyDown>
        <DialogTitle>
          <Typography>{formatMessage(messages.transferlayerFilterBuilder)}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <FormControl fullWidth>
                    <InputLabel id="mylabel">{formatMessage(messages.transferlayerProperty)}</InputLabel>
                    <Select labelId="mylabel" id="myselect" label={formatMessage(messages.transferlayerProperty)}>
                      <MenuItem value={10}>{formatMessage(messages.transferlayerTen)}</MenuItem>
                      <MenuItem value={20}>{formatMessage(messages.transferlayerTwenty)}</MenuItem>
                      <MenuItem value={30}>{formatMessage(messages.transferlayerThirty)}</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right" />
                <TableCell align="right" />
                <TableCell align="right" />
              </TableRow>
            </TableBody>
          </Table>
          {formatMessage(messages.transferlayerMultiple)}
        </DialogContent>
        {loading && <LinearProgress />}
        <DialogActions>
          <Button onClick={closeFilterDialog}>{formatMessage(messages.transferlayerCancel)}</Button>
          <Button variant="contained" color="primary" onClick={triggerSave} disabled={!handleValidation()}>
            {formatMessage(messages.transferlayerModify)}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={shareConfigConfirmDialogOpen}
        onClose={handleShareConfigConfirmDialogClose}
        aria-labelledby="alert-dialog-title-delete"
        aria-describedby="alert-dialog-description-delete"
      >
        <DialogTitle id="alert-dialog-confirm-share-config-title">{formatMessage(messages.transferlayerShareConfigConfirmTitle)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-confirm-share-config-description">
            <Typography>
              {formatMessage(messages.transferlayerShareConfigConfirmBody, { count: linkedPublicationsOtherOrgs.length, type: activeTransferLayer?.type?.toUpperCase() })}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShareConfigConfirmDialogClose}>{formatMessage(messages.transferlayerShareConfigConfirmBtnCancel)}</Button>
          <Button variant="contained" color="primary" onClick={handleShareConfigConfirmDialogSave} autoFocus>
            {formatMessage(messages.transferlayerShareConfigConfirmBtnAccept)}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
});

export default withStyles(useTransferLayerDetailStyles)(injectIntl(TransferLayerDetail));

/*

<ogc:Filter_Capabilities>
  <ogc:Spatial_Capabilities>
    <ogc:SpatialOperators>
      <ogc:SpatialOperator name="Disjoint"/>
      <ogc:SpatialOperator name="Equals"/>
      <ogc:SpatialOperator name="DWithin"/>
      <ogc:SpatialOperator name="Beyond"/>
      <ogc:SpatialOperator name="Intersects"/>
      <ogc:SpatialOperator name="Touches"/>
      <ogc:SpatialOperator name="Crosses"/>
      <ogc:SpatialOperator name="Within"/>
      <ogc:SpatialOperator name="Contains"/>
      <ogc:SpatialOperator name="Overlaps"/>
      <ogc:SpatialOperator name="BBOX"/>
    </ogc:SpatialOperators>
  </ogc:Spatial_Capabilities>
  <ogc:Scalar_Capabilities>
    <ogc:ComparisonOperators>
      <ogc:ComparisonOperator>LessThan</ogc:ComparisonOperator>
      <ogc:ComparisonOperator>GreaterThan</ogc:ComparisonOperator>
      <ogc:ComparisonOperator>LessThanEqualTo</ogc:ComparisonOperator>
      <ogc:ComparisonOperator>GreaterThanEqualTo</ogc:ComparisonOperator>
      <ogc:ComparisonOperator>EqualTo</ogc:ComparisonOperator>
      <ogc:ComparisonOperator>NotEqualTo</ogc:ComparisonOperator>
      <ogc:ComparisonOperator>Like</ogc:ComparisonOperator>
      <ogc:ComparisonOperator>Between</ogc:ComparisonOperator>
      <ogc:ComparisonOperator>NullCheck</ogc:ComparisonOperator>
    </ogc:ComparisonOperators>
  </ogc:Scalar_Capabilities>
  <ogc:Id_Capabilities>
    <ogc:FID/>
    <ogc:EID/>
  </ogc:Id_Capabilities>
</ogc:Filter_Capabilities>

<ogc:Filter_Capabilities>
  <ogc:Spatial_Capabilities>
    <ogc:SpatialOperators>
      <ogc:SpatialOperator name="Equals"/>
      <ogc:SpatialOperator name="Disjoint"/>
      <ogc:SpatialOperator name="Touches"/>
      <ogc:SpatialOperator name="Within"/>
      <ogc:SpatialOperator name="Overlaps"/>
      <ogc:SpatialOperator name="Crosses"/>
      <ogc:SpatialOperator name="Intersects"/>
      <ogc:SpatialOperator name="Contains"/>
      <ogc:SpatialOperator name="DWithin"/>
      <ogc:SpatialOperator name="Beyond"/>
      <ogc:SpatialOperator name="BBOX"/>
    </ogc:SpatialOperators>
  </ogc:Spatial_Capabilities>
  <ogc:Scalar_Capabilities>
    <ogc:ComparisonOperators>
      <ogc:ComparisonOperator>LessThan</ogc:ComparisonOperator>
      <ogc:ComparisonOperator>GreaterThan</ogc:ComparisonOperator>
      <ogc:ComparisonOperator>LessThanEqualTo</ogc:ComparisonOperator>
      <ogc:ComparisonOperator>GreaterThanEqualTo</ogc:ComparisonOperator>
      <ogc:ComparisonOperator>EqualTo</ogc:ComparisonOperator>
      <ogc:ComparisonOperator>NotEqualTo</ogc:ComparisonOperator>
      <ogc:ComparisonOperator>Like</ogc:ComparisonOperator>
      <ogc:ComparisonOperator>Between</ogc:ComparisonOperator>
    </ogc:ComparisonOperators>
  </ogc:Scalar_Capabilities>
  <ogc:Id_Capabilities>
  <ogc:EID/>
  <ogc:FID/>
  </ogc:Id_Capabilities>
</ogc:Filter_Capabilities>

*/
