// root auth
export const ROUTE_BASE = "/";
export const ROUTE_SSO_SESSION_CALLBACK = ROUTE_BASE + "sso-session/:authType";
export const ROUTE_LOGIN = ROUTE_BASE + "login";
export const ROUTE_DATALAYERS = ROUTE_BASE + "datalayers";
export const ROUTE_FILEMANAGER = ROUTE_BASE + "filemanager";
export const ROUTE_DATACSV = ROUTE_BASE + "datacsv";
export const ROUTE_IMAGELAYERS = ROUTE_BASE + "imagelayers";
export const ROUTE_DATALAYER_DETAIL = ROUTE_DATALAYERS + "/:id";
export const ROUTE_DATALAYER_DETAIL_UPLOAD = ROUTE_DATALAYERS + "/:id/upload";
export const ROUTE_DATALAYER_TABLE_DETAIL = ROUTE_DATALAYERS + "table/:id";
export const ROUTE_DATALAYER_STYLE = ROUTE_DATALAYERS + "/style/:id";
export const ROUTE_DATAGROUPS = ROUTE_BASE + "datagroups";
export const ROUTE_DATAGROUP_DETAIL = ROUTE_DATAGROUPS + "/:id";
export const ROUTE_DATAGROUP_STYLE = ROUTE_DATAGROUPS + "/style/:id";
export const ROUTE_DATAGROUP_MAP = ROUTE_DATAGROUPS + "/map/:id";
export const ROUTE_SELECT_PROFILE = ROUTE_BASE + "selecteer";
export const ROUTE_PUBLICATIONS = ROUTE_BASE + "publications";
export const ROUTE_PUBLICATION_DETAIL = ROUTE_PUBLICATIONS + "/:id";
export const ROUTE_BASELAYERS = ROUTE_BASE + "baselayers";
export const ROUTE_BASELAYER_DETAIL = ROUTE_BASELAYERS + "/:id";
export const ROUTE_WFSWMS = ROUTE_BASE + "transferlayers";
export const ROUTE_WFSWMS_DETAIL = ROUTE_BASE + "transferlayers/:id";

// root publication view
export const ROUTE_PUBLICATION_BASE = "/viewer/";
export const ROUTE_PUBLICATION_VIEW = "/viewer/:id";
export const ROUTE_PUBLICATION_VIEW_SEARCH = ROUTE_PUBLICATION_VIEW + "/search";
export const ROUTE_PUBLICATION_VIEW_LAYERS = ROUTE_PUBLICATION_VIEW + "/layers";
export const ROUTE_PUBLICATION_VIEW_RELATIONS = ROUTE_PUBLICATION_VIEW + "/relations";
