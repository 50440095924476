/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */

const { addLocaleData } = require("react-intl");
const nlLocaleData = require("react-intl/locale-data/nl");
const frLocaleData = require("react-intl/locale-data/fr");
const enLocaleData = require("react-intl/locale-data/en");
const deLocaleData = require("react-intl/locale-data/de");

const nlTranslationMessages = require("./translations/nl.json");
const frTranslationMessages = require("./translations/fr.json");
const enTranslationMessages = require("./translations/en.json");
const deTranslationMessages = require("./translations/de.json");

const { DEFAULT_LOCALE, APP_LOCALES } = require("./constants");

const appLocales = Object.values(APP_LOCALES);

addLocaleData(nlLocaleData);
addLocaleData(frLocaleData);
addLocaleData(enLocaleData);
addLocaleData(deLocaleData);

const formatTranslationMessages = (locale: string, messages: any) => {
  const defaultFormattedMessages: any = locale !== DEFAULT_LOCALE ? formatTranslationMessages(DEFAULT_LOCALE, nlTranslationMessages) : {};
  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage = !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  }, {});
};

const translationMessages = {
  nl: formatTranslationMessages("nl", nlTranslationMessages),
  fr: formatTranslationMessages("fr", frTranslationMessages),
  en: formatTranslationMessages("en", enTranslationMessages),
  de: formatTranslationMessages("de", deTranslationMessages),
};

export { appLocales, formatTranslationMessages, translationMessages };
