import { LicenseInfo } from "@material-ui/x-grid";
import axios from "axios";
import "mapbox-gl/dist/mapbox-gl.css";
import { configure } from "mobx";
import React, { createContext } from "react";
import { render } from "react-dom";
import { IntlProvider } from "react-intl";
import { BaseMapContextProvider } from "views/Map/BaseMapContext";
import App from "./App";
import { translationMessages } from "./i18n";
import { ROUTE_LOGIN, ROUTE_WFSWMS } from "./routes/RouteList";
import * as serviceWorker from "./serviceWorker";
import AnalyseStore from "./stores/AnalyseStore";
import AuthStore from "./stores/AuthStore";
import BaseLayerStore from "./stores/BaseLayerStore";
import DataGroupStore from "./stores/DataGroupStore";
import DataLayerStore from "./stores/DataLayerStore";
import EnrichmentStore from "./stores/EnrichmentStore";
import MapStore from "./stores/MapStore";
import PublicationStore from "./stores/PublicationStore";
import TransferLayerStore from "./stores/TransferLayerStore";
import UiStore from "./stores/UiStore";
import UploadStore from "./stores/UploadStore";
import WebSocketStore from "./stores/WebSocketStore";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "index.css";

LicenseInfo.setLicenseKey("4fb3dfe58637d237981c3bbd38679e43T1JERVI6MjIzMDcsRVhQSVJZPTE2NDY5MjM3MTQwMDAsS0VZVkVSU0lPTj0x");
configure({ enforceActions: "never" });

// setup all stores
const uiStore = new UiStore();
const mapStore = new MapStore();
const dataLayerStore = new DataLayerStore();
const publicationStore = new PublicationStore();
const analyseStore = new AnalyseStore();
const baseLayerStore = new BaseLayerStore();
const transferLayerStore = new TransferLayerStore();
const dataGroupStore = new DataGroupStore();
const enrichmentStore = new EnrichmentStore();
const authStore = new AuthStore(mapStore, uiStore);
const uploadStore = new UploadStore(dataLayerStore);
const webSocketStore = new WebSocketStore(mapStore, dataLayerStore, dataGroupStore, uiStore, uploadStore, authStore, publicationStore);

const stores = {
  uiStore,
  authStore,
  mapStore,
  dataLayerStore,
  publicationStore,
  analyseStore,
  baseLayerStore,
  transferLayerStore,
  dataGroupStore,
  uploadStore,
  webSocketStore,
  enrichmentStore,
};

export const StoresContext = createContext(stores);

// Add a 401 response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (window.location.href.includes(ROUTE_LOGIN)) return;
    if (window.location.href.includes(ROUTE_WFSWMS)) return; // add wfs/wms route in case you add a wfs/wms that is missing username password
    if (error.response.status === 401) {
      authStore.forceLogout();
      return (window.location.href = ROUTE_LOGIN);
    }
    if (error.response.status === 500) {
      if (error.response?.data?.message === "user needs role to get permissions") {
        return (window.location.href = ROUTE_LOGIN);
      }
    }
    return;
  },
);

const pause = () => new Promise((resolve) => setTimeout(resolve, 100));

const waitForHydration = async () => {
  return new Promise(async (resolve) => {
    do {
      await pause();
    } while (!authStore.isHydrated);
    resolve(true);
  });
};

const renderApp = async () => {
  render(
    <div className="spinner-container" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
      <div className="spinner"></div>
    </div>,
    document.getElementById("root"),
  );
  await waitForHydration();
  render(
    <StoresContext.Provider value={stores}>
      <IntlProvider locale={uiStore.locale} messages={translationMessages[uiStore.locale]}>
        <BaseMapContextProvider>
          <App />
        </BaseMapContextProvider>
      </IntlProvider>
    </StoresContext.Provider>,
    document.getElementById("root"),
  );
};

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
