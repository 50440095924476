import React, { useContext, FunctionComponent, useEffect, Fragment } from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import { StoresContext } from "index";
import useHeaderStyles from "./HeaderStyles";
import { IconButton, Link, Menu, MenuItem, Box } from "@material-ui/core";
import { Route, useLocation, useParams, Switch } from "react-router";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  AppBar,
  Toolbar,
  withStyles,
  //InputBase
} from "@material-ui/core";
import { ROUTE_DATAGROUPS, ROUTE_DATAGROUP_STYLE, ROUTE_DATALAYERS, ROUTE_DATALAYER_STYLE } from "routes/RouteList";
import { observer as hooksObserver } from "mobx-react-lite";
import NotificationView from "./NotificationView";
import messages from "./messages";

interface Props {
  classes: any;
  intl: InjectedIntl;
}

interface CustomProps {
  type: HeaderTypes;
}

enum HeaderTypes {
  DATALAYER = "datalayer",
  DATAGROUP = "datagroup",
}

const HeaderViewMaputnik: FunctionComponent<Props> = hooksObserver(({ classes, intl: { formatMessage } }) => {
  const {
    dataGroupStore: { activeLayergroup },
    dataLayerStore: { activeLayer },
    authStore: { doLogout },
  } = useContext(StoresContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const myLocation = useLocation();

  const { id } = useParams();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const CustomHeader: FunctionComponent<CustomProps> = ({ type }) => (
    <Fragment>
      <Link href={type === HeaderTypes.DATALAYER ? ROUTE_DATALAYERS + "/" + id : ROUTE_DATAGROUPS + "/" + id} className={classes.headerLink} underline="none" variant="button">
        <span className={classes.headerLinkSpan}>
          <span className={classes.headerLinkIconSpan}>
            <ArrowBackIcon className={classes.headerLinkIcon} />
          </span>
          {formatMessage(messages.headerBackToDetail)}
        </span>
      </Link>
      <Box className={classes.grow} />
      <span>
        {type === HeaderTypes.DATALAYER
          ? formatMessage(messages.headerStyleDatalayer) + " " + activeLayer?.displayName
          : formatMessage(messages.headerStyleDatagroup) + " " + activeLayergroup?.name}
      </span>
      <Box className={classes.grow} />
      <NotificationView />
      <IconButton color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={classes.notificationButton}>
        <AccountCircleIcon />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
            doLogout();
          }}
        >
          {formatMessage(messages.headerLogout)}
        </MenuItem>
      </Menu>
    </Fragment>
  );

  return (
    <AppBar className={classes.appBar} elevation={2} position="fixed">
      <Toolbar className={classes.toolBar}>
        <Switch>
          <Route exact path={ROUTE_DATALAYER_STYLE}>
            <CustomHeader type={HeaderTypes.DATALAYER} />
          </Route>
          <Route exact path={ROUTE_DATAGROUP_STYLE}>
            <CustomHeader type={HeaderTypes.DATAGROUP} />
          </Route>
        </Switch>
      </Toolbar>
    </AppBar>
  );
});

export default withStyles(useHeaderStyles)(injectIntl(HeaderViewMaputnik));
