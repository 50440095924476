import { Typography } from "@material-ui/core";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import { TransferLayerController } from "views/Map/LayerController";
import messages from "./messages";

export const SidebarLayers = observer(({ intl: { formatMessage } }: { intl: InjectedIntl }) => {
  const {
    publicationStore: { activePublication, sortedLayers },
  } = useContext(StoresContext);

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {formatMessage(messages.layerLayers)}
      </Typography>
      {activePublication?.transferLayer && <TransferLayerController layers={sortedLayers} />}
    </>
  );
});

injectIntl(SidebarLayers);
