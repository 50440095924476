import React, { useRef } from "react";
import { Layer } from "react-map-gl";
/**
 * do not remove this completely stupid component
 * it's needed to do  layer z-indexes in reactmapgl
 */
export const HIDDEN_GROUP_PREFIX = "GROUP_";
export const TOTAL_HIDDEN_LAYERS = 100;
export const LOWEST_LAYER = TOTAL_HIDDEN_LAYERS - 1;

export const HiddenOrderLayers = React.memo(() => {
  const layerItems = useRef(Array(TOTAL_HIDDEN_LAYERS).fill(0));
  return (
    <>
      {layerItems.current.map((_, index) => (
        <Layer key={index} id={HIDDEN_GROUP_PREFIX + Number(LOWEST_LAYER - index)} type="background" layout={{ visibility: "none" }} paint={{}}>
          {}
        </Layer>
      ))}
    </>
  );
});
