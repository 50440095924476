import useQuery from "./useQuery";

const useEnrichment = () => {
  try {
    const queryParams = useQuery();
    const enrichmentId = queryParams.get("enrichmentId");
    return { enrichmentId };
  } catch (e) {}
  return {};
};

export default useEnrichment;
