import { Theme, createStyles, fade, makeStyles } from "@material-ui/core/styles";

const useTransferLayerDetailStyles = (theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    },
    urlContainer: {
      marginBottom: theme.spacing(3),
    },
    urlButton: {
      float: "right",
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
    floatingButton: {
      float: "right",
    },
    searchField: {
      marginTop: 10,
    },
    dialogContent: {
      padding: "0 !important",
    },
    tabs: {
      color: "#ffffff",
    },
    dialogPaper: {
      padding: theme.spacing(2),
    },
    dialogTextField: {
      marginBottom: theme.spacing(1),
    },
  });

export default useTransferLayerDetailStyles;

export const useFeatureTypeStyles = makeStyles((theme) => ({
  abstractHelp: {
    display: "inline-flex",
    fontSize: "1.1rem",
    boxSizing: "border-box",
    alignItems: "center",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    justifyContent: "center",
    textDecoration: "none",
    color: "#616161",
  },
  styleTable: {
    padding: "0px 12px 0px 12px !important",
  },
  test: {
    "& div:first-child": {
      width: "100% !important",
    },
  },
}));
