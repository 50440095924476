import { Theme, createStyles } from "@material-ui/core/styles";

const usePublicationsStyles = (theme: Theme) =>
  createStyles({
    floatingButton: {
      float: "right",
    },
    searchField: {
      marginTop: 10,
    },
    marginBottom: {
      marginBottom: 10,
    },
  });

export default usePublicationsStyles;
