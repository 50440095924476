/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  relationsRelationalData: {
    id: "relations.relational.data",
    defaultMessage: "Relationele data",
  },
});
