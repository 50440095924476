import React, { useContext, FunctionComponent } from "react";
import { Switch } from "react-router";
import { observer } from "mobx-react-lite";
import { StoresContext } from "index";

const PublicRoutes: FunctionComponent<{}> = observer(({ children }) => {
  const {
    authStore: { isLoggedIn },
  } = useContext(StoresContext);
  if (!isLoggedIn) return <Switch>{children}</Switch>;
  return null;
});

export default PublicRoutes;
