import { action, makeObservable, observable } from "mobx";
import { APP_LOCALES, DEFAULT_LOCALE } from "../constants";
import NotificationModel from "./models/NotificationModel";
import { makePersistable } from "mobx-persist-store";

export default class UiStore {
  locale: APP_LOCALES = DEFAULT_LOCALE;

  sideBarOpen: boolean = true;
  sideBarRightOpen = false;

  notifications: NotificationModel = new NotificationModel();

  setSideBarOpen = (isOpen: boolean) => {
    this.sideBarOpen = isOpen;
  };

  setSideBarRightOpen = (isOpen: boolean) => {
    this.sideBarRightOpen = isOpen;
  };

  dialogSelectProfileOpen: boolean = false;

  setDialogSelectProfileOpen = (isOpen: boolean) => {
    this.dialogSelectProfileOpen = isOpen;
  };
  setLocale = (locale: string) => {
    this.locale = locale as APP_LOCALES;
  };

  constructor() {
    makeObservable(this, {
      locale: observable,
      setLocale: action.bound,
      notifications: observable,
      sideBarOpen: observable,
      sideBarRightOpen: observable,
      setSideBarOpen: action.bound,
      setSideBarRightOpen: action.bound,
      dialogSelectProfileOpen: observable,
      setDialogSelectProfileOpen: action.bound,
    });

    makePersistable(this, {
      name: "UiStore",
      properties: ["locale"],
      storage: window.localStorage,
    });
  }
}
