import { Theme, createStyles, fade } from "@material-ui/core/styles";

const useHeaderStyles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    toolBar: {
      borderBottom: "0px solid #E71D36",
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      cursor: "pointer",
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    notificationButton: {
      color: "#fff",
    },
    removeNotifications: {
      float: "right",
    },
    headerLink: {
      color: "#fff",
      marginRight: 25,
      cursor: "pointer",
      fontSize: "0.875rem",
      minWidth: 64,
      padding: "6px 8px",
      display: "inline-flex",
    },
    headerLinkIconSpan: {
      display: "inherit",
      marginLeft: -4,
      marginRight: 8,
    },
    headerLinkIcon: {
      fontSize: 20,
    },
    headerLinkSpan: {
      width: "100%",
      display: "inherit",
      alignItems: "inherit",
      justifyContent: "inherit",
    },
    notificationRoot: {
      minWidth: 300,
      maxWidth: 450,
    },
    notificationHeader: {
      flex: "0 0 auto",
      margin: 0,
      padding: theme.spacing(2),
    },
    notificationNoMessages: {
      flex: "0 0 auto",
      margin: 0,
      padding: theme.spacing(2),
    },
    notificationContent: {
      padding: theme.spacing(0),
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      flex: "1 1 auto",
      overflowY: "auto",
    },
    listItem: {
      width: "100%",
    },
  });

export default useHeaderStyles;
