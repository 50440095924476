/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  titleVECTOR: {
    id: "datagroups.title.vector",
    defaultMessage: "Datalagen",
  },
  datagroupsNew: {
    id: "datagroups.new",
    defaultMessage: "Nieuw",
  },
  datagroupsDatagroups: {
    id: "datagroups.datagroups",
    defaultMessage: "Datagroepen",
  },
  datagroupsSearchprompt: {
    id: "datagroups.searchprompt",
    defaultMessage: "Datagroep",
  },
  datagroupsGroupName: {
    id: "datagroups.group.name",
    defaultMessage: "Naam van de groep:",
  },
  datagroupsGroupNameUpdateTitle: {
    id: "datagroups.group.name.update.title",
    defaultMessage: "Bewerk naam van datagroep",
  },
  nameError: {
    id: "name.error",
    defaultMessage: "Alleen letters, cijfers, spaties, -, en _ zijn toegestaan.",
  },
  datagroupsSelectDatalayers: {
    id: "datagroups.select.datalayers",
    defaultMessage: "Selecteer datalagen:",
  },
  datagroupsCancel: {
    id: "datagroups.cancel",
    defaultMessage: "Annuleren",
  },
  datagroupsCreate: {
    id: "datagroups.create",
    defaultMessage: "Aanmaken",
  },
  datagroupsNewDatagroup: {
    id: "datagroups.new.datagroup",
    defaultMessage: "Nieuwe datagroep",
  },
  datagroupsSearch: {
    id: "datagroups.search",
    defaultMessage: "Zoeken",
  },
  datagroupsDetails: {
    id: "datagroups.details",
    defaultMessage: "Details datagroep:",
  },
  datagroupsNameGroup: {
    id: "datagroups.name.group",
    defaultMessage: "Groepnaam",
  },
  datagroupsDatagroup: {
    id: "datagroups.datagroup",
    defaultMessage: "datagroep",
  },
  datagroupsStillProcessingDatagroup: {
    id: "datagroups.still.processing.datagroup",
    defaultMessage: "De datagroep is nog aan het verwerken!",
  },
  datagroupsWrong: {
    id: "datagroups.wrong",
    defaultMessage: "Er is iets fout gegaan!",
  },
  datagroupsCreatedOn: {
    id: "datagroups.created.on",
    defaultMessage: "Aangemaakt op",
  },
  datagroupsModifiedOn: {
    id: "datagroups.modified.on",
    defaultMessage: ", bewerkt op",
  },
  datagroupsPublications: {
    id: "datagroups.publications",
    defaultMessage: "Publicaties",
  },
  datagroupsNoLinkedPublications: {
    id: "datagroups.no.linked.publications",
    defaultMessage: "Geen publicatie gekoppeld",
  },
  datagroupsPublicURLs: {
    id: "datagroups.public.urls",
    defaultMessage: "Publieke URL's",
  },
  datagroupsURL: {
    id: "datagroups.url",
    defaultMessage: "URL",
  },
  datagroupsCopy: {
    id: "datagroups.copy",
    defaultMessage: "copy",
  },
  datagroupsPublishAgain: {
    id: "datagroups.publish.again",
    defaultMessage: "Publiceer opnieuw",
  },
  datagroupsOverwriteStyle: {
    id: "datagroups.overwrite.style",
    defaultMessage: "Overschrijf stijl in gekoppelde publicatie(s)",
  },
  datagroupsUseDatalayers: {
    id: "datagroups.use.datalayers",
    defaultMessage: "Gebruik datalagen:",
  },
  datagroupsPublish: {
    id: "datagroups.publish",
    defaultMessage: "Publiceer",
  },
});
