/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  filemanagertitle: {
    id: "filemananger.title",
    defaultMessage: "Bestanden beheer",
  },
  filemanageralldatalayers: {
    id: "filemananger.alldatalayers",
    defaultMessage: "Alle datalagen",
  },
  filemanagerdeletetitle: {
    id: "filemananger.deletetitle",
    defaultMessage: "Bestand(en) verwijderen",
  },
  filemanagerdeleteconfirm: {
    id: "filemananger.deleteconfirm",
    defaultMessage: "Verwijderen",
  },
  filemanagerdeleteclose: {
    id: "filemananger.deleteclose",
    defaultMessage: "Annuleren",
  },
  filemanagerdeletemessage: {
    id: "filemananger.deletemessage",
    defaultMessage: "Weet u zeker dat u dit/deze bestand(en) wilt verwijderen?",
  },
  filemanageraddnewfiles: {
    id: "filemananger.addnewfiles",
    defaultMessage: "Nieuwe bestanden toevoegen",
  },
  filemanagergotodatalayer: {
    id: "filemananger.gotodatalayer",
    defaultMessage: "Ga naar datalaag detail",
  },
  filemanagerdeleteingroup: {
    id: "filemananger.deleteingroup",
    defaultMessage: "Selecteer bestanden om in groep te verwijderen",
  },
  filemanagerpopuptitle: {
    id: "filemananger.popuptitle",
    defaultMessage: "Details bestand",
  },
  filemanagerpopupclose: {
    id: "filemananger.popupclose",
    defaultMessage: "Sluiten",
  },
  filemanagerpopupsave: {
    id: "filemananger.popupsave",
    defaultMessage: "Opslaan",
  },
  filemanagerfilesaved: {
    id: "filemananger.filesaved",
    defaultMessage: "Bestand aangepast!",
  },
  filemanagertableactions: {
    id: "filemananger.tableactions",
    defaultMessage: "Acties",
  },
  filemanagertableselected: {
    id: "filemananger.tableselected",
    defaultMessage: "geselecteerd",
  },
  translateKey_mapValue: {
    id: "filemananger.translateKey_mapValue",
    defaultMessage: "Waarde",
  },
  translateKey_id: {
    id: "filemananger.translateKey_id",
    defaultMessage: "ID",
  },
  translateKey_dateCreated: {
    id: "filemananger.translateKey_dateCreated",
    defaultMessage: "Aangemaakt op",
  },
  translateKey_dateModified: {
    id: "filemananger.translateKey_dateModified",
    defaultMessage: "Bewerkt op",
  },
  translateKey_dateDeleted: {
    id: "filemananger.translateKey_dateDeleted",
    defaultMessage: "Verwijdert op",
  },
  translateKey_fileName: {
    id: "filemananger.translateKey_fileName",
    defaultMessage: "Bestandsnaam",
  },
  translateKey_location: {
    id: "filemananger.translateKey_location",
    defaultMessage: "Locatie",
  },
  translateKey_convertionTaskId: {
    id: "filemananger.translateKey_convertionTaskId",
    defaultMessage: "Datalaag ID",
  },
  translateKey_layerName: {
    id: "filemananger.translateKey_layerName",
    defaultMessage: "Naam v/d laag",
  },
  translateKey_fileMapping: {
    id: "filemananger.translateKey_fileMapping",
    defaultMessage: "Mapping",
  },
  translateKey_fileNameOnStorage: {
    id: "filemananger.translateKey_fileNameOnStorage",
    defaultMessage: "Bestandsnaam opslag",
  },
});
