import { observable, action, makeObservable, toJS } from "mobx";
import { TransferLayerListObjectType } from "@orbit/geo-core-shared";
import { createTransferLayer, fetchTransferLayer, fetchTransferLayers, pushTransferLayer, deleteTransferLayer, pushShareConfigTransferLayer } from "services/transfer";

export default class TransferLayerStore {
  transferLayers: TransferLayerListObjectType[] = [];
  activeTransferLayer: TransferLayerListObjectType | null;

  clearActiveTransferLayer = () => {
    this.activeTransferLayer = null;
  };

  fetchActiveTransferLayer = async (id: string) => {
    this.clearActiveTransferLayer();
    const activeLayer: TransferLayerListObjectType = await fetchTransferLayer(id);
    this.activeTransferLayer = activeLayer;
  };

  loadTransferLayers = async (includeShared?: boolean) => {
    this.transferLayers = [];
    const transferLayers: TransferLayerListObjectType[] = await fetchTransferLayers(includeShared);
    this.transferLayers = transferLayers;
  };

  updateTransferLayer = async (id, data) => {
    data.featureTypes = data.featureTypes.filter((featureType) => featureType.enabled);
    const activeLayer: TransferLayerListObjectType = await pushTransferLayer(id, data);
    this.activeTransferLayer = activeLayer;
  };

  updateTransferLayerShareConfig = async (id, data) => {
    const activeLayer: TransferLayerListObjectType = await pushShareConfigTransferLayer(id, data);
    this.activeTransferLayer = activeLayer;
  };

  deleteLayerWithId = async (id) => {
    await deleteTransferLayer(id);
    this.loadTransferLayers();
  };

  addTransferLayer = async ({
    type,
    wfsName,
    wfsURL,
    featureType,
    wfsUsername,
    wfsPassword,
    wfsOutputFormat,
    wfsDefaultCrs,
    wfsFlip,
    zoomLevel,
  }: {
    type: string;
    wfsName: string;
    wfsURL: string;
    featureType: any;
    wfsUsername: string;
    wfsPassword: string;
    wfsOutputFormat?: string;
    wfsDefaultCrs?: number;
    wfsFlip?: boolean;
    zoomLevel?: number;
  }) => {
    const newLayer: TransferLayerListObjectType = await createTransferLayer({
      type,
      name: wfsName,
      url: wfsURL,
      data: featureType,
      username: wfsUsername,
      password: wfsPassword,
      outputFormat: wfsOutputFormat,
      defaultCrs: wfsDefaultCrs,
      flipCoordinates: wfsFlip,
      zoomLevel,
    });
    this.transferLayers.push(newLayer);
  };

  constructor() {
    makeObservable(this, {
      transferLayers: observable,
      activeTransferLayer: observable,
      clearActiveTransferLayer: action.bound,
      fetchActiveTransferLayer: action.bound,
      loadTransferLayers: action.bound,
      addTransferLayer: action.bound,
    });
  }
}
