import { Theme, createStyles } from "@material-ui/core/styles";

const useDatagroupDetailStyles = (theme: Theme) =>
  createStyles({
    chipsList: {
      display: "block",
    },
    chips: {
      margin: theme.spacing(0.5),
    },
  });

export default useDatagroupDetailStyles;
