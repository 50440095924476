/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  titleVECTOR: {
    id: "datalayer.title.vector",
    defaultMessage: "Datalagen",
  },
  titleBITMAP: {
    id: "datalayer.title.bitmap",
    defaultMessage: "Afbeeldingen",
  },
  titleSingleBITMAP: {
    id: "datalayer.title.single.bitmap",
    defaultMessage: "Afbeelding",
  },
  titleCSV: {
    id: "datalayer.title.csv",
    defaultMessage: "Tabel data",
  },
  newVectorTitle: {
    id: "datalayer.new.vector.title",
    defaultMessage: "Nieuwe datalaag",
  },
  datalayersSingleDatalayer: {
    id: "datalayer.single.datalayer",
    defaultMessage: "Datalaag",
  },
  newBitmapTitle: {
    id: "datalayer.new.bitmap.title",
    defaultMessage: "Nieuwe afbeelding",
  },
  new: {
    id: "generic.new",
    defaultMessage: "Nieuw",
  },
  errors: {
    id: "generic.errors",
    defaultMessage: "errors",
  },
  zoomLevel: {
    id: "map.zoomLevel",
    defaultMessage: "Zoomlevel",
  },
  crs: {
    id: "map.crs",
    defaultMessage: "CRS",
  },
  cancel: {
    id: "generic.cancel",
    defaultMessage: "Annuleren",
  },
  process: {
    id: "generic.process",
    defaultMessage: "Verwerken",
  },
  datalayersLG: {
    id: "datalayers.LG",
    defaultMessage: "LG",
  },
  datalayersTableDetails: {
    id: "datalayers.table.details",
    defaultMessage: "Details datalaag:",
  },
  datalayersLayerName: {
    id: "datalayers.layer.name",
    defaultMessage: "Laagnaam",
  },
  datalayersDisplayName: {
    id: "datalayers.layer.display.name",
    defaultMessage: "Weergave naam",
  },
  datalayersDisplayNameUpdateTitle: {
    id: "datalayers.layer.display.name.title.update",
    defaultMessage: "Bewerk weergave naam van datalaag",
  },
  datalayersLayerId: {
    id: "datalayers.layer.layerid",
    defaultMessage: "Datalaag ID",
  },
  datalayersLayerIdUpdateTitle: {
    id: "datalayers.layer.layerid.title.update",
    defaultMessage: "Bewerk laagnaam ID van datalaag",
  },
  datalayersTableUpdateTitle: {
    id: "datalayers.layer.table.title.update",
    defaultMessage: "Bewerk titel van datalaag",
  },
  datalayersStillProcessing: {
    id: "datalayers.still.processing",
    defaultMessage: "De datalaag is nog aan het verwerken!",
  },
  datalayersSomethingWrong: {
    id: "datalayers.something.wrong",
    defaultMessage: "Er is iets fout gegaan!",
  },
  datalayersCreatedOn: {
    id: "datalayers.created.on",
    defaultMessage: "Aangemaakt op",
  },
  datalayersModifiedOn: {
    id: "datalayers.modified.on",
    defaultMessage: ", bewerkt op",
  },
  datalayersUpdate: {
    id: "datalayers.update",
    defaultMessage: "Bijwerken",
  },
  datalayersResetBbox: {
    id: "datalayers.reset.bbox",
    defaultMessage: "Kaart overzicht herstellen",
  },
  datalayersResetBboxError: {
    id: "datalayers.reset.bbox.error",
    defaultMessage: "Er is een fout opgetreden bij het herstellen van het kaart overzicht, probeer het later opnieuw.",
  },
  datalayersPublicURLS: {
    id: "datalayers.public.urls",
    defaultMessage: "Publieke URL's",
  },
  datalayersURL: {
    id: "datalayers.url",
    defaultMessage: "URL",
  },
  datalayersCopy: {
    id: "datalayers.copy",
    defaultMessage: "copy",
  },
  datalayersDatagroups: {
    id: "datalayers.datagroups",
    defaultMessage: "Datagroepen",
  },
  datalayersNoLinkedDatagroup: {
    id: "datalayers.no.linked.datagroup",
    defaultMessage: "Geen datagroep gekoppeld",
  },
  datalayersPublications: {
    id: "datalayers.publications",
    defaultMessage: "Publicaties",
  },
  datalayersNoLinkedPublication: {
    id: "datalayers.no.linked.publication",
    defaultMessage: "Geen publicatie gekoppeld",
  },
  datalayersLinkedTableData: {
    id: "datalayers.linked.table.data",
    defaultMessage: "Gekoppelde tabel data",
  },
  datalayersDownloadDatalayer: {
    id: "datalayers.download.datalayer",
    defaultMessage: "Datalaag downloaden",
  },
  datalayersFiles: {
    id: "datalayers.file.files",
    defaultMessage: "Bestanden",
  },
  datalayersFilesAttach: {
    id: "datalayers.file.attach",
    defaultMessage: "Koppelen aan kolommen",
  },
  datalayersFilesSave: {
    id: "datalayers.file.save",
    defaultMessage: "Koppeling opslaan",
  },
  datalayersFilesManage: {
    id: "datalayers.file.manage",
    defaultMessage: "Beheren",
  },
  datalayersFilesUpload: {
    id: "datalayers.file.upload",
    defaultMessage: "Uploaden",
  },
  datalayersFilesError: {
    id: "datalayers.file.error",
    defaultMessage: "Koppeling kan niet aangepast worden indien er bestanden zijn gekoppeld aan de datalaag.",
  },
  datalayersFilesTitle: {
    id: "datalayers.file.title",
    defaultMessage: "Bestanden uploaden voor datalaag",
  },
  datalayersFilesMapping: {
    id: "datalayers.file.mapping",
    defaultMessage: "Gelieve eerst de fileMapping in te stellen op de datalaag (zie bestanden koppelen)",
  },
  datalayersFilesStartUpload: {
    id: "datalayers.file.startUpload",
    defaultMessage: "Start upload",
  },
  datalayersFilesUploadType: {
    id: "datalayers.file.uploadType",
    defaultMessage: "Upload type",
  },
  datalayersFilesDrag: {
    id: "datalayers.file.drag",
    defaultMessage: "Klik op de onderstaande knop of sleep bestanden naar hier om te beginnen met verwerken.",
  },
  datalayersFilesSelect: {
    id: "datalayers.file.select",
    defaultMessage: "Selecteer bestanden",
  },
  datalayersFilesAppend: {
    id: "datalayers.file.append",
    defaultMessage: "Toevoegen (gewoon uploaden)",
  },
  datalayersFilesReindex: {
    id: "datalayers.file.reindex",
    defaultMessage: "Herindexeren (geen bestanden, enkel mapping)",
  },
  datalayersFilesUploadDone: {
    id: "datalayers.file.uploadDone",
    defaultMessage: "Upload voltooid!",
  },
  datalayersFilesEditorError: {
    id: "datalayers.file.editorError",
    defaultMessage: "Geen items of editor fout.",
  },
  datalayersFilesEditorFileName: {
    id: "datalayers.file.editorFileName",
    defaultMessage: "De key fileName is verplicht.",
  },
  datalayersFilesEditorFields: {
    id: "datalayers.file.editorFields",
    defaultMessage: "De key fields is verplicht.",
  },
  datalayersFilesEditorId: {
    id: "datalayers.file.editorId",
    defaultMessage: "De key id is verplicht bij het herindexeren.",
  },
  datalayersLocationIndex: {
    id: "datalayers.location.index",
    defaultMessage: "Locatie index (ES)",
  },
  datalayersESExists: {
    id: "datalayers.es.exists",
    defaultMessage: "Er bestaat al een locatie index voor deze datalaag. Klik op de knop om deze bij te werken.",
  },
  datalayersOverwriteModifiedLocations: {
    id: "datalayers.overwrite.modified.locations",
    defaultMessage: "Aangepaste locaties overschrijven",
  },
  datalayersUpdateES: {
    id: "datalayers.update.es",
    defaultMessage: "Locatie index bijwerken",
  },
  datalayersNoES: {
    id: "datalayers.no.es",
    defaultMessage: "Er bestaat nog geen locatie index voor deze datalaag. Klik op de knop om een index te genereren.",
  },
  datalayersCreateES: {
    id: "datalayers.create.es",
    defaultMessage: "Locatie index aanmaken",
  },
  datalayersUpdateDatalayer: {
    id: "datalayers.update.datalayer",
    defaultMessage: "Datalaag bijwerken",
  },
  datalayersDataAction: {
    id: "datalayers.data.action",
    defaultMessage: "Data actie",
  },
  datalayersTypeFilesError: {
    id: "datalayers.type.files.error",
    defaultMessage: "TO_MUCH_TYPE_FILES",
  },
  datalayersTypeFilesErrorReason: {
    id: "datalayers.type.files.error.reason",
    defaultMessage: "To much different types imported, only one type is supported",
  },
  datalayersWrongFileTypeError: {
    id: "datalayers.wrong.file.type.error",
    defaultMessage: "WRONG_FILE_TYPE",
  },
  datalayersWrongImportError: {
    id: "datalayers.wrong.import.error",
    defaultMessage: "Wrong file type imported, only",
  },
  datalayersWrongImportErrorReason: {
    id: "datalayers.wrong.import.error.reason",
    defaultMessage: "is supported",
  },
  datalayersGroupFilesError: {
    id: "datalayers.group.files.error",
    defaultMessage: "TO_MUCH_GROUP_FILES",
  },
  datalayersGroupFilesErrorReason: {
    id: "datalayers.group.files.error.reason",
    defaultMessage: "To much different groups imported, only one group is supported",
  },
  datalayersErrors: {
    id: "datalayers.errors",
    defaultMessage: "Errors",
  },
  datalayersAddShape: {
    id: "datalayers.add.shape",
    defaultMessage: "Shape toevoegen aan huidige geometrie",
  },
  datalayersOverwriteActiveGeometry: {
    id: "datalayers.overwrite.active.geometry",
    defaultMessage: "Huidige geometrie overschrijven",
  },
  datalayersLoadingData: {
    id: "datalayers.loading.data",
    defaultMessage: "loading data",
  },
  datalayersNone: {
    id: "datalayers.none",
    defaultMessage: "Geen",
  },
  datalayersFrom: {
    id: "datalayers.from",
    defaultMessage: "Van",
  },
  datalayersTo: {
    id: "datalayers.to",
    defaultMessage: "Naar",
  },
  datalayersLinkTableData: {
    id: "datalayers.link.table.data",
    defaultMessage: "Koppel tabel data aan datalaag",
  },
  datalayersDeleteLink: {
    id: "datalayers.delete.link",
    defaultMessage: "Verwijder link",
  },
  datalayersDeleteLinkDisabled: {
    id: "datalayers.delete.link.disabled",
    defaultMessage: "Kan link niet verwijderen indien een kolom aan bestanden is gekoppeld.",
  },
  datalayersNoneEnglish: {
    id: "datalayers.none.english",
    defaultMessage: "None",
  },
  datalayersDatalayer: {
    id: "datalayers.datalayer",
    defaultMessage: "datalaag",
  },
  datalayersSave: {
    id: "datalayers.save",
    defaultMessage: "Opslaan",
  },
  datalayersTable: {
    id: "datalayers.table",
    defaultMessage: "table",
  },
  datalayersNewTableDatalayer: {
    id: "datalayers.new.table.datalayer",
    defaultMessage: "Nieuwe tabel datalaag",
  },
  datalayersTableName: {
    id: "datalayers.table.name",
    defaultMessage: "Tabelnaam",
  },
  datalayersTableDataStillProcessing: {
    id: "datalayers.table.data.still.processing",
    defaultMessage: "De tabeldata is nog aan het verwerken!",
  },
  datalayersUpdateTable: {
    id: "datalayers.update.table",
    defaultMessage: "Tabel bijwerken",
  },
  datalayersAddRows: {
    id: "datalayers.add.rows",
    defaultMessage: "Rijen toevoegen aan huidige tabel",
  },
  datalayersOverwriteActiveTable: {
    id: "datalayers.overwrite.active.table",
    defaultMessage: "Huidige tabel overschrijven",
  },
  datalayersImageGeoTiffUrlLabel: {
    id: "datalayers.image.geotiff.url.label",
    defaultMessage: "GeoTIFF URL",
  },
  datalayersImageCreate: {
    id: "datalayers.image.create",
    defaultMessage: "Aanmaken",
  },
  datalayersImageSave: {
    id: "datalayers.image.save",
    defaultMessage: "Opslaan",
  },
  datalayersImageConfiguration: {
    id: "datalayers.image.configuration",
    defaultMessage: "Afbeelding configuratie",
  },
  datalayersImageErrorUrl: {
    id: "datalayers.image.error.url",
    defaultMessage: "Ongeldige URL",
  },
  datalayersImageErrorCreateUnknown: {
    id: "datalayers.image.error.create.unknown",
    defaultMessage: "Er liep iets mis bij het aanmaken van de afbeelding",
  },
  datalayersImageErrorUpdateUnknown: {
    id: "datalayers.image.error.update.unknown",
    defaultMessage: "Er liep iets mis bij het bijwerken van de afbeelding",
  },
  datalayersImageNoDataLabel: {
    id: "datalayers.image.nodata.label",
    defaultMessage: "Geen data kleur",
  },
  datalayersImageNoDataValuenone: {
    id: "datalayers.image.nodata.value.none",
    defaultMessage: "Niet van toepassing",
  },
  datalayersImageNoDataValue0: {
    id: "datalayers.image.nodata.value.black",
    defaultMessage: "Zwart",
  },
  datalayersImageNoDataValue255: {
    id: "datalayers.image.nodata.value.white",
    defaultMessage: "Wit",
  },
  datalayersBBOXCheckbox: {
    id: "datalayers.bbox.checkbox",
    defaultMessage: "BBOX van deze datalaag beschikbaar stellen in publicaties",
  },
  datalayersBBOXDialogTitle: {
    id: "datalayers.bbox.dialog.title",
    defaultMessage: "BBOX van deze datalaag uitschakelen in publicaties",
  },
  datalayersBBOXDialogContent: {
    id: "datalayers.bbox.dialog.content",
    defaultMessage:
      "Opgelet! Als de BBOX van deze datalaag uitschakeld, zullen alle publicaties waar deze BBOX ingesteld staat geen standaard zoom BBOX meer hebben. Weet je zeker dat je dit wil uitschakelen?",
  },
  datalayersBBOXDialogConfirm: {
    id: "datalayers.bbox.dialog.confirm",
    defaultMessage: "BBOX uitschakelen in publicaties",
  },
});
