import { Button, Grid, InputBase, InputLabel, MenuItem, Select, Slider, Theme, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import AddIcon from "@material-ui/icons/Add";
import { createStyles, withStyles } from "@material-ui/styles";
import { LayerListObjectType, crsTypes } from "@orbit/geo-core-shared";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import { ROUTE_DATALAYERS } from "routes/RouteList";
import { getConvertionTaskStatusColor, getConvertionTaskStatusLabel } from "../../../constants";
import { DataLayerType } from "../../../stores/DataLayerStore";
import { errorsType, groupedFilesType } from "../../../utils/file.utils";
import CustomDialogView from "../../../views/CustomDialog/CustomDialogView";
import FileUploader from "../../../views/upload/FileUploader";
import useDatasetsStyles from "./DatalayersStyles";
import messages from "./messages";
import ListView, { ListViewItem } from "containers/ListView/ListView";
import { toJS } from "mobx";
import DatalayerImageForm, { ImageLayerFormAction } from "./DatalayerImageForm";

export enum allowedTypes {
  VECTOR = ".shp,.shx,.dbf,.prj,.geojson,.json,.sld",
  BITMAP = ".tiff,.tfw,.jp2",
  CSV = ".csv",
}

export enum DATALAYER_OUTPUT_TYPES {
  DISPLAY_NAME = "displayName",
  LAYER_NAME = "layerName",
  LAYER_ID = "layerId",
}

const capitalizeFirstCharacter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const getFormattedListItem = (layers: LayerListObjectType[], nameOutputType: DATALAYER_OUTPUT_TYPES): ListViewItem[] => {
  return layers.map((layer) => ({
    id: layer.id,
    dateCreated: layer.dateCreated,
    dateModified: layer.dateModified,
    user: layer.user,
    name: layer[nameOutputType],
    avatarUrl: layer.avatarUrl,
    status: getConvertionTaskStatusLabel(layer.status),
    statusColor: getConvertionTaskStatusColor(layer.status),
  }));
};

const Datalayers: FunctionComponent<{
  classes: any;
  type: DataLayerType;
  intl: InjectedIntl;
}> = observer(({ classes, type, intl: { formatMessage } }: { classes: any; type: DataLayerType; intl: any }) => {
  const {
    dataLayerStore: { layers, loadData, deleteLayerWithId },
    uploadStore,
  } = useContext(StoresContext);

  const [allowedFileTypes, setAllowedFileTypes] = useState(allowedTypes[type]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [groupedFiles, setGroupedFiles] = useState<groupedFilesType>({});
  const [errors, setErrors] = useState<errorsType>([]);
  const [listItems, setListItems] = useState<ListViewItem[]>([]);
  const [metaData, setMetaData] = useState({});
  const [nameOutputType, setNameOutputType] = useState<DATALAYER_OUTPUT_TYPES>(DATALAYER_OUTPUT_TYPES.DISPLAY_NAME);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const clearFiles = async () => {
    setFiles([]);
    setErrors([]);
    setGroupedFiles({});
  };

  const uploadFiles = async () => {
    if (!files?.length) return;

    await uploadStore.upload(groupedFiles, metaData);
    clearFiles();
  };

  useEffect(() => {
    if (type) {
      loadData(type);
      setAllowedFileTypes(allowedTypes[type]);
    }
  }, [type]);

  useEffect(() => {
    setListItems(getFormattedListItem(layers, nameOutputType));
  }, [JSON.stringify(layers), nameOutputType]);

  async function handleDelete(activeLayerIdMenu: string) {
    await deleteLayerWithId(activeLayerIdMenu);
  }

  const handleChangeNameOutput = (event: React.ChangeEvent<{ value: DATALAYER_OUTPUT_TYPES }>) => {
    setNameOutputType(event.target.value);
  };

  return (
    <Fragment>
      <Grid container spacing={6}>
        <Grid item xs={8}>
          <Button
            className={classes.floatingButton}
            variant="contained"
            color="primary"
            disableElevation
            size="large"
            onClick={() => {
              openDialog();
            }}
            startIcon={<AddIcon />}
          >
            {formatMessage(messages.new)}
          </Button>
          <Typography variant="h4" gutterBottom>
            {type === DataLayerType.VECTOR ? formatMessage(messages.titleVECTOR) : formatMessage(messages.titleBITMAP)}
          </Typography>
          <ListView
            hasStatus={true}
            singleItemName={formatMessage(messages.datalayersSingleDatalayer)}
            hasDateModified={true}
            canDelete={true}
            path={ROUTE_DATALAYERS}
            title={type === DataLayerType.VECTOR ? formatMessage(messages.titleVECTOR) : formatMessage(messages.titleBITMAP)}
            searchprompt={type === DataLayerType.VECTOR ? formatMessage(messages.datalayersSingleDatalayer) : formatMessage(messages.titleSingleBITMAP)}
            items={listItems}
            handleDelete={handleDelete}
            leadingControls={
              <>
                {type === DataLayerType.VECTOR && (
                  <Select defaultValue={nameOutputType} onChange={handleChangeNameOutput} style={{ marginLeft: 20, height: 35 }}>
                    {Object.values(DATALAYER_OUTPUT_TYPES).map((outputType) => (
                      <MenuItem key={outputType} value={outputType}>
                        {formatMessage(messages[`datalayers${capitalizeFirstCharacter(outputType)}`])}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </>
            }
          />
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      <CustomDialogView
        open={dialogOpen}
        handleClose={() => {
          clearFiles();
          closeDialog();
        }}
        dialogTitle={type === DataLayerType.VECTOR ? formatMessage(messages.newVectorTitle) : formatMessage(messages.newBitmapTitle)}
        dialogContent={
          type === DataLayerType.BITMAP ? (
            <>
              <DatalayerImageForm action={ImageLayerFormAction.CREATE} closeDialog={closeDialog} />
            </>
          ) : (
            <Fragment>
              <FileUploader
                accept={allowedFileTypes}
                onChange={(newfiles: File[], groupedFiles: groupedFilesType, errors: errorsType) => {
                  setFiles(newfiles);
                  setGroupedFiles(groupedFiles);
                  if (type === DataLayerType.BITMAP) {
                    let zoomObj = {};
                    Object.keys(groupedFiles).forEach((key) => {
                      Object.keys(groupedFiles[key]).forEach((fileKey) => {
                        zoomObj[fileKey] = [8, 20];
                      });
                    });
                    setMetaData(zoomObj);
                  }
                  setErrors(errors);
                }}
                multiple={true}
              />
              {errors && errors.length > 0 && (
                <List subheader={<ListSubheader>{formatMessage(messages.errors)}</ListSubheader>}>
                  {errors.map((error) => (
                    <ListItem key={error.name}>
                      <ListItemText primary={error.name} secondary={error.reason} />
                    </ListItem>
                  ))}
                </List>
              )}
              {files &&
                files.length > 0 &&
                groupedFiles &&
                Object.keys(groupedFiles).map((key) => (
                  <List key={key} subheader={<ListSubheader>{key}</ListSubheader>}>
                    {Object.keys(groupedFiles[key]).map((filekey) => (
                      <ListItem key={filekey}>
                        <ListItemText primary={filekey} secondary={groupedFiles[key][filekey].reduce((accumulator, currentValue) => accumulator + " " + currentValue.name, "")} />
                        {type === DataLayerType.BITMAP && (
                          <ListItemSecondaryAction>
                            <div style={{ width: "200px", textAlign: "center" }}>
                              <Slider
                                value={metaData[filekey]}
                                min={0}
                                max={24}
                                onChange={(e, newValue) => {
                                  setMetaData({ ...metaData, [filekey]: newValue });
                                }}
                                valueLabelDisplay="auto"
                                aria-labelledby="range-slider"
                              />
                              <Typography id="range-slider" variant="caption" gutterBottom>
                                {formatMessage(messages.zoomLevel)}
                              </Typography>
                            </div>
                          </ListItemSecondaryAction>
                        )}
                        {["json", "geojson"].includes(key.toLowerCase()) && (
                          <ListItemSecondaryAction>
                            <InputLabel htmlFor="espg-select">{formatMessage(messages.crs)}</InputLabel>
                            <Select
                              defaultValue={crsTypes[0].crs}
                              fullWidth
                              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                setMetaData({ ...metaData, [filekey]: event.target.value });
                              }}
                              input={<BootstrapInput />}
                            >
                              {crsTypes.map((item) => (
                                <MenuItem key={item.crs} value={item.crs}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    ))}
                  </List>
                ))}
            </Fragment>
          )
        }
        dialogActions={
          type !== DataLayerType.BITMAP ? (
            <Fragment>
              <Button
                id="submit"
                onClick={() => {
                  clearFiles();
                  closeDialog();
                }}
              >
                {formatMessage(messages.cancel)}
              </Button>
              <Button
                id="submit"
                color="primary"
                variant="contained"
                disabled={!files?.length}
                onClick={() => {
                  uploadFiles();
                  closeDialog();
                }}
                disableElevation
              >
                {formatMessage(messages.process)}
              </Button>
            </Fragment>
          ) : null
        }
      />
    </Fragment>
  );
});

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }),
)(InputBase);

export default withStyles(useDatasetsStyles)(injectIntl(Datalayers));
