import { Container } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/styles";
import { observer } from "mobx-react-lite";
import { MapContext } from "react-map-gl";
import React, { FunctionComponent } from "react";
import { Route, Switch } from "react-router";
import { ROUTE_PUBLICATION_BASE, ROUTE_PUBLICATION_VIEW, ROUTE_PUBLICATION_VIEW_LAYERS, ROUTE_PUBLICATION_VIEW_SEARCH } from "routes/RouteList";
import PublicationViewer from "../partials/publication/PublicationViewer";
import PublicationMenu from "../partials/publication/PublicationMenu";
import usePublicationPageStyles from "./PublicationPageStyles";
import { ROUTE_PUBLICATION_VIEW_RELATIONS } from "../../routes/RouteList";
interface Props {
  classes: any;
}

const PublicationPage: FunctionComponent<Props> = observer(({ classes }) => {
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Switch>
        <Route exact path={[ROUTE_PUBLICATION_BASE]}>
          <PublicationMenu />
        </Route>
        <Route exact path={[ROUTE_PUBLICATION_VIEW, ROUTE_PUBLICATION_VIEW_SEARCH, ROUTE_PUBLICATION_VIEW_LAYERS, ROUTE_PUBLICATION_VIEW_RELATIONS]}>
          <PublicationViewer />
        </Route>
      </Switch>
    </div>
  );
});

export default withStyles(usePublicationPageStyles)(PublicationPage);
