import React, { FunctionComponent } from "react";
import { XGrid, GridColDef } from "@material-ui/x-grid";
import { Button } from "@material-ui/core";
import { InjectedIntl, injectIntl } from "react-intl";
import messages from "./messages";
import { observer } from "mobx-react-lite";

const SimpleTable: FunctionComponent<{ data?: any; autoHeight?: any; action?: any; intl: InjectedIntl }> = observer(
  ({ data, autoHeight = true, action, intl: { formatMessage }, ...options }) => {
    let columns: GridColDef[] = data?.length > 0 ? Object.keys(data[0]).map((key) => ({ field: key, width: 200, hide: key === "geom" })) : [];
    const rows = data.map((item, index) => ({ ...item, ...{ id: item.id ?? index } }));

    columns = columns.map((col) => {
      if (col.field === "action") {
        col.width = 100;
        col.renderCell = (params) => (
          <>
            <Button
              onClick={() =>
                action
                  ? action({
                      id: params.getValue("id"),
                      bbox: params.getValue("action"),
                      geom: params.getValue("geom"),
                      ogc_fid: params.getValue("ogc_fid"),
                    })
                  : null
              }
              variant="contained"
              color="primary"
            >
              {formatMessage(messages.tableView)}
            </Button>
          </>
        );
      }
      return col;
    });

    return (
      <div style={{ height: "100%", width: "100%" }}>
        <XGrid rows={rows} columns={columns} autoHeight={autoHeight} {...options} />
      </div>
    );
  },
);

export default injectIntl(SimpleTable);
