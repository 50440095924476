import { InitialViewStateProps } from "@deck.gl/core/lib/deck";
import { Fab, LinearProgress, Tooltip, Typography, withStyles } from "@material-ui/core";
import ZoomInIcon from "@material-ui/icons/Add";
import CaptureIcon from "@material-ui/icons/CameraAlt";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import ZoomOutIcon from "@material-ui/icons/Remove";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import { ViewMode } from "@nebula.gl/edit-modes";
import { EditableGeoJsonLayer } from "@nebula.gl/layers";
import buffer from "@turf/buffer";
import {
  BaseLayerListObjectType,
  createLayerId,
  LatLngType,
  MapLayerOrderItem,
  MapLayerType,
  MbtilesStyleInterface,
  UNIQUE_SEPARATOR,
  WfsTransferLayerListObject,
  WmsTransferLayerListObject,
} from "@orbit/geo-core-shared";
import DeckGL from "deck.gl";
import { StoresContext } from "index";
import { observer as hooksObserver, observer } from "mobx-react-lite";
import { Toolbox } from "@orbitgtbelgium/nebula-map-tools";
import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { FullScreen, FullScreenHandle, useFullScreenHandle } from "react-full-screen";
import { Layer, LayerProps, MapContext, ScaleControl, Source, StaticMap, MapRef } from "react-map-gl";
import WebMercatorViewport from "viewport-mercator-project";
import BaseLayerModel from "../../stores/models/BaseLayerModel";
import BaseLayerController from "./BaseLayerController";
import { BaseMapActionTypes, useBaseMap } from "./BaseMapContext";
import { useBaseMapStyles } from "./BaseMapStyles";
import DefaultPopup from "./DefaultPopup";
import editorConfiguration from "./editorConfiguration.json";
import { HiddenOrderLayers, HIDDEN_GROUP_PREFIX } from "./HiddenOrderLayers";
import LayerController, { LayerRoot } from "./LayerController";
import WfsLayerView from "./WfsLayerView";
import WmsLayerView from "./WmsLayerView";
import { throttle } from "lodash";
import { searchModel } from "containers/partials/publication/search/Sidebar";
import { InjectedIntl, injectIntl } from "react-intl";
import messages from "./messages";
import * as jwt from "jsonwebtoken";

const { API_URL } = window.env;

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 0,
    top: 0,
    position: "absolute",
    width: "100%",
    left: 0,
    margin: "0 auto",
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 0,
    backgroundColor: "secondary",
  },
}))(LinearProgress);

// TODO -> fix when zoom is 24 images are removed
// research and possible pr on mapbox
const ZOOM_MAX = 24;

export const selectedLayer: LayerProps = {
  type: "line",
  paint: {
    "line-width": {
      base: 2,
      stops: [
        [4, 0.4],
        [5, 1],
        [12, 3],
      ],
    },
    "line-color": "rgba(28, 251, 3, 1)",
  },
};

const logo = {
  img: "/img/MapLogo.png",
  url: "https://www.orbitgis.com/",
};

/**
 * will query the given map at x and y
 * and only return the features that are in the
 * orbit dataset -> layergroup / datalayer / wfs
 * @param mapRef
 * @param x
 * @param y
 */
const getFeaturesFromMap = (mapRef: MapRef, x: number, y: number) => {
  if (!mapRef || !x || !y) {
    return [];
  }
  return (
    mapRef
      ?.getMap?.()
      ?.queryRenderedFeatures?.([x, y])
      ?.filter?.((feature) => feature.source === "columba" || feature.source.includes("jsx-source-") || feature.id === undefined) || []
  );
};

export const baseLayerModel = new BaseLayerModel();

export interface MapProps {
  latLng: LatLngType;
  bounds?: number[];
  mapStyle?: string | MbtilesStyleInterface;
  classes?: any;
  baseLayers?: BaseLayerListObjectType[];
  dataFilter?: { [key: string]: string[] };
  mapTitle?: string;
  wfsLayers?: WfsTransferLayerListObject[];
  wmsLayers?: WmsTransferLayerListObject[];
  sortedLayers?: MapLayerOrderItem[];
}

const scaleControlStyle = {
  top: 10,
  left: 10,
};

/**
 * function that gives the correct layer index for every given id
 *
 * @param layerId
 * @param sortedLayers
 * @returns
 */
const getBeforeIdForLayer = (layerId: string, sortedLayers: { id: string; type: string }[]) => {
  let previousIndex = sortedLayers.findIndex((layer) => layer.id.toLowerCase() === layerId.toLowerCase());
  // id's of layergroup won't be found -> so we check if the previous index is -1
  // en then we do a find on the string LG
  if (previousIndex === -1) {
    previousIndex = sortedLayers.findIndex((layer) => layer.id.toLowerCase() === "LG".toLowerCase());
  }
  return HIDDEN_GROUP_PREFIX + Number(previousIndex + 1);
};

const BaseMap: FunctionComponent<MapProps> = hooksObserver(
  ({
    mapStyle,
    latLng: { lat, lng },
    bounds,
    baseLayers,
    dataFilter,
    mapTitle,
    wfsLayers = [],
    wmsLayers = [],
    sortedLayers = [],
    intl: { formatMessage },
    intl,
  }: {
    latLng: LatLngType;
    bounds?: number[];
    mapStyle?: string | MbtilesStyleInterface;
    classes?: any;
    baseLayers?: BaseLayerListObjectType[];
    dataFilter?: { [key: string]: string[] };
    mapTitle?: string;
    wfsLayers?: WfsTransferLayerListObject[];
    wmsLayers?: WmsTransferLayerListObject[];
    sortedLayers?: MapLayerOrderItem[];
    intl: InjectedIntl;
  }) => {
    const {
      enrichmentStore: { geoJson: enrichmentGeoJson, bbox },
      mapStore: { editableGeojson, setEditableGeojson },
      authStore: { token },
    } = useContext(StoresContext);

    const { activeStyle, setActiveStyle, maps, setActiveMap, activeMap, selectedGeomId, loading } = baseLayerModel;
    const classes = useBaseMapStyles();
    const [showPopup, setShowPopup] = React.useState(false);
    const [popup, setPopup] = React.useState<any>();
    const [latLng] = React.useState({ lat, lng });
    const [height, setHeight] = React.useState(0);
    const [width, setWidth] = React.useState(0);
    const [isCursorInsideMap, setIsCursorInsideMap] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [viewPort, setViewPort] = React.useState<InitialViewStateProps>({
      latitude: latLng.lat,
      longitude: latLng.lng,
      zoom: 10,
      bearing: 0,
      pitch: 0,
      maxZoom: ZOOM_MAX,
    });
    const mapRef = useRef<MapRef>();
    const deck = useRef(null);

    const { dispatch, state } = useBaseMap();
    const [activeFilter, setActiveFilter] = useState<any[]>(["==", ["id"], 0]);
    const handle = useFullScreenHandle();
    const [mode, setMode] = useState(() => ViewMode);
    const [modeConfig, setModeConfig] = useState({});

    // const throttleMouseHandler = useCallback(
    //   throttle(event => {
    //     const features = mapRef?.current
    //       ?.getMap?.()
    //       .queryRenderedFeatures?.([event?.srcEvent?.layerX || 0, event?.srcEvent?.layerY || 0])
    //       .filter(feature => feature.source.includes("jsx-source-") || feature.id === undefined);
    //     if (features?.length > 0) {
    //       document.body.style.cursor = "pointer";
    //       // console.log("Pointer")
    //     } else {
    //       document.body.style.cursor = "grab";
    //       // console.log("grab")
    //     }
    //   }, 100),
    //   []
    // );

    const handleMouseEnterMap = () => {
      setIsCursorInsideMap(true);
    };

    const handleMouseLeaveMap = () => {
      setIsCursorInsideMap(false);
      document.body.style.cursor = "auto";
    };

    const handleMouseDown = () => {
      setIsMouseDown(true);
    };

    const handleMouseUp = () => {
      setIsMouseDown(false);
    };

    const throttleMouseHandler = (event) => {
      if (isCursorInsideMap) {
        const features = mapRef?.current
          ?.getMap?.()
          .queryRenderedFeatures?.([event?.x || 0, event?.y || 0])
          .filter((feature) => feature.source === "columba" || feature.source.includes("jsx-source-") || feature.id === undefined);
        if (features?.length > 0) {
          document.body.style.cursor = "pointer";
        } else {
          if (isMouseDown) {
            document.body.style.cursor = "grabbing";
          } else {
            document.body.style.cursor = "grab";
          }
        }
      } else {
        document.body.style.cursor = "auto";
      }
    };

    useEffect(() => {
      // Add the event listener for onMouseMove when the component mounts
      window.addEventListener("mousemove", throttleMouseHandler);

      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener("mousemove", throttleMouseHandler);
      };
    }, [isCursorInsideMap, isMouseDown]);

    const [bufferGeojson, setBufferGeojson] = useState<null | GeoJSON>(null);
    const throttleBufferHandler = useCallback(
      throttle((geojson, bufferValue) => {
        if (geojson?.features?.length > 0 && !isNaN(Number(bufferValue))) {
          setBufferGeojson({
            type: "FeatureCollection",
            features: geojson.features.map((feature) => buffer(feature, Number(bufferValue), { units: "meters" })).filter((feature) => !!feature),
          });
        } else {
          setBufferGeojson(null);
        }
      }, 100),
      [],
    );

    const selectedFeatureIndexes: number[] = useMemo(() => Array.from(Array(editableGeojson?.features?.length || 0).keys()), [editableGeojson]);

    useEffect(() => {
      throttleBufferHandler(editableGeojson, searchModel.bufferValue);
    }, [setBufferGeojson, editableGeojson, searchModel.bufferValue]);

    const editableLayer = useMemo(() => {
      //@ts-ignore
      return new EditableGeoJsonLayer({
        id: "geojson-layer",
        data: editableGeojson,
        mode,
        modeConfig,
        selectedFeatureIndexes: selectedFeatureIndexes,
        pointType: editorConfiguration.pointType,
        pickable: editorConfiguration.pickable,
        pickingRadius: 15,
        stroked: editorConfiguration.stroked,
        filled: editorConfiguration.filled,
        extruded: editorConfiguration.extruded,
        autoHighlight: editorConfiguration.autoHighlight,
        lineWidthScale: editorConfiguration.lineWidthScale,
        lineWidthMinPixels: editorConfiguration.lineWidthMinPixels,
        getLineColor: editorConfiguration.getLineColor,
        getFillColor: editorConfiguration.getFillColor,
        getLineWidth: editorConfiguration.getLineWidth,
        getElevation: editorConfiguration.getElevation,
        highlightColor: editorConfiguration.highlightColor,
        onEdit: ({ updatedData }) => {
          setEditableGeojson(updatedData);
        },
      });
    }, [editableGeojson, mode, modeConfig]);

    /**
     * calculates the before id for the given id of the layer
     *
     * @param id
     * @returns
     */
    const getBeforeId = (id: string) => {
      const wmtsId = activeMap?.type === "wmts" ? createLayerId(activeMap?.id) : null;
      let sLayers = [...sortedLayers];

      let layers = [...(wmsLayers ? wmsLayers.map((wmsLayer) => createLayerId(wmsLayer.id)) : [])];

      if (wmtsId) {
        sLayers = [{ id: wmtsId, type: MapLayerType.WMTS, label: formatMessage(messages.mapWMTSBaselayer), visible: true }, ...sLayers];
        layers = [...layers, wmtsId];
      }
      const beforeId = getBeforeIdForLayer(id, sLayers);
      return beforeId;
    };

    const parentDiv = useCallback((node) => {
      if (node !== null) {
        setHeight(node.getBoundingClientRect().height);
        setWidth(node.getBoundingClientRect().width);
      }
    }, []);

    useEffect(() => {
      if (baseLayers && baseLayers.length > 0) {
        baseLayerModel.setMaps(baseLayers);
      } else {
        baseLayerModel.setMaps([]);
      }
    }, [baseLayers]);

    useEffect(() => {
      return () => {
        setEditableGeojson({ type: "FeatureCollection", features: [] });
        baseLayerModel.reset();
      };
    }, []);

    useEffect(() => {
      baseLayerModel.setBBox(bbox);
    }, [bbox]);

    useEffect(() => {
      setActiveFilter(["==", ["get", "ogc_fid"], selectedGeomId || 0]);
    }, [selectedGeomId]);

    useEffect(() => {
      if (baseLayerModel.bbox) {
        const [minLng, minLat, maxLng, maxLat] = baseLayerModel.bbox;
        // construct a viewport instance from the current state
        const vp = new WebMercatorViewport(viewPort);
        let { longitude, latitude, zoom } = vp.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: 200,
          },
        );

        setViewPort({
          ...viewPort,
          longitude,
          latitude,
          zoom,
        });
        baseLayerModel.setBBox(null);
      }
    }, [baseLayerModel.bbox, viewPort]);

    const zoomToBoundsHandler = useCallback(() => {
      if (!bounds || !width || !height) {
        return;
      }
      const mercatorViewport = new WebMercatorViewport({ width, height });

      const bound = mercatorViewport.fitBounds(
        [
          [bounds[0], bounds[1]],
          [bounds[2], bounds[3]],
        ],
        { padding: 20, offset: [0, -40] },
      );

      setViewPort({ ...viewPort, latitude: bound.latitude, longitude: bound.longitude, zoom: bound.zoom });
    }, [bounds]);

    /**
     * every time the bounds change we zoom to bounds of the bounds
     */
    React.useEffect(() => {
      zoomToBoundsHandler();
    }, [bounds]);

    useEffect(() => {
      if (state.geom) {
        const newFeature = {
          type: "Feature",
          properties: {},
          geometry: state.geom,
        };
        const newFeatures = [...editableGeojson.features, newFeature];
        //@ts-ignore
        setEditableGeojson({ ...editableGeojson, features: newFeatures });
      }
    }, [state.geom]);

    /**
     * when the mapstyle changes we need to reload all sources and layers
     *
     */
    React.useEffect(() => {
      async function loadStyle() {
        if (!mapStyle) {
          return;
        }
        /**
         * check if mapstyle is a string/url
         * if not we can set the active style
         */
        if (typeof mapStyle !== "string") {
          mapStyle.id = Math.random() + "";
          setActiveStyle(mapStyle);
          return;
        }
        /**
         * when the style is a url
         * we need to load the json of the style
         * and then we can set the style
         */
        const rawJSON = await fetch(mapStyle);
        const style = await rawJSON.json();
        setActiveMap(style);
      }
      loadStyle();
    }, [mapStyle]);

    function formatPopup(editableGeojson, lng, lat) {
      const data = editableGeojson.reduce((result, feature) => {
        // check to only render items that are in orbit data sources
        let newData = feature.properties;
        if (dataFilter?.[feature.sourceLayer]) {
          const filter = dataFilter[feature.sourceLayer];
          newData = dataFilter[feature.sourceLayer].reduce((result, item) => {
            result["data"] = filterMbtilesRelationalDataObject(filter, feature.properties["data"]);
            if (feature.properties[item]) {
              result[item] = feature.properties[item];
            }
            return result;
          }, {});
        }
        result.push({
          geom: feature.geometry,
          source: feature.source,
          properties: feature.properties,
          sourceLayer: feature.sourceLayer,
          title: feature.layer.id.split(UNIQUE_SEPARATOR)[1],
          data: newData,
          setSelectedGeom: () => {
            const id = feature?.properties?.ogc_fid;
            baseLayerModel.setSelectedGeomId(id);
          },
        });

        return result;
      }, []);
      dispatch({ type: BaseMapActionTypes.SET_SELECTED_ITEMS, selectedItems: data, activeIndex: 0 });
      setShowPopup(true);
      setPopup(
        <DefaultPopup
          {...{
            data: data.filter((d) => d.source !== "openmaptiles"),
            onClose: () => setShowPopup(false),
            canCloseTooltip: true,
            lat,
            lng,
          }}
        ></DefaultPopup>,
      );
    }

    const wmsLayersRef = useRef(wmsLayers);

    useEffect(() => {
      wmsLayersRef.current = wmsLayers;
    }, [wmsLayers]);

    // reroute all WMS through the API to avoid CORS issues, and we can insert auth there easier
    const transformReq = (url, resourceType) => {
      if (resourceType === "Tile") {
        if (url.includes("geo-core")) {
          return {
            url,
            headers: { Authorization: `Bearer ${token}` },
          };
        }
      }
    };

    return (
      <FullScreen handle={handle}>
        <div
          ref={parentDiv}
          key="fullscreen-wrapper"
          className="full-screenable-node"
          style={{ position: "relative", width: "100%", height: "100%", overflow: "hidden" }}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseEnter={handleMouseEnterMap}
          onMouseLeave={handleMouseLeaveMap}
        >
          <DeckGL
            ref={deck}
            layers={[editableLayer]}
            getCursor={mode !== ViewMode ? editableLayer.getCursor.bind(editableLayer) : () => ""}
            controller={true}
            ContextProvider={MapContext.Provider}
            initialViewState={viewPort}
            onViewStateChange={({ viewState }) => setViewPort(viewState)}
            width="100%"
            height="100%"
            // onHover={(info, event) => {
            //   if (mapRef?.current && mode === ViewMode) {
            //     throttleMouseHandler(event);
            //   }
            // }}
            maxZoom={24}
            onClick={(info, event) => {
              if (mapRef?.current && mode === ViewMode) {
                // only keep items that are rendered in our map
                // datalayers-datagroups-wfs
                const features = getFeaturesFromMap(mapRef?.current, info.x, info.y);

                const lng = info.coordinate?.[0];
                const lat = info.coordinate?.[1];
                formatPopup(features, lng, lat);
                baseLayerModel.setSelectedGeomId(features?.[0]?.properties?.ogc_fid);
              }
            }}
          >
            <StaticMap
              key={activeStyle?.id}
              ref={mapRef}
              width="100%"
              height="100%"
              disableTokenWarning={true}
              mapStyle={activeMap?.type === "vector" ? activeMap.styleUrl : "/config/empty-style.json"}
              transformRequest={transformReq}
            >
              <HiddenOrderLayers />
              {activeStyle?.sources &&
                Object.entries(activeStyle?.sources || {}).map(([key, sourceData]) => {
                  const layers = activeStyle?.layers.filter((layer) => layer.source === key) || [];
                  return (
                    <Source key={key} {...sourceData}>
                      {layers?.map((layer) => {
                        const id = createLayerId(key, layer.id);
                        const beforeId = getBeforeId(id);
                        return <Layer key={id} {...(layer as LayerProps)} id={id} beforeId={beforeId} />;
                      })}
                      {/** * selected layers ----------------------- * all layers have a duplicated layer that is used to show selected layers
                       * this is how it works in mapbox to show a border/selection */}
                      {selectedGeomId &&
                        layers
                          ?.filter(({ type }) => type !== "raster")
                          .map((layer) => (
                            <Layer
                              key={layer.id + "highlighted"}
                              {...{ ...layer, layout: {} }}
                              {...selectedLayer}
                              id={`${layer.id}-highlighted`}
                              filter={activeFilter}
                              beforeId={HIDDEN_GROUP_PREFIX + 1}
                            />
                          ))}
                    </Source>
                  );
                })}
              {/* 
          when the base layer is a wmts
          we have to create a raster source and layer
          the layer should be send to the back by given the activeStyle first layer
          only render when zoom is within the allowed zooms of the map/wmts
          */}

              {activeMap?.type === "wmts" && (
                <Source
                  {...{
                    id: activeMap?.id,
                    key: `"wmts-base-source${activeMap.title}"`,
                    type: "raster",
                    tiles: [activeMap.styleUrl],
                    tileSize: 256,
                  }}
                >
                  <Layer
                    {...{
                      id: createLayerId(activeMap?.id),
                      key: `"wms-base-layer"${activeMap.title}"`,
                      type: "raster",
                      source: `"wmts-base-source"${activeMap.title}`,
                      beforeId: HIDDEN_GROUP_PREFIX + 99,
                      minzoom: 0,
                      paint: {},
                      maxzoom: ZOOM_MAX,
                    }}
                  ></Layer>
                </Source>
              )}
              {wmsLayers && <WmsLayerView wmsLayers={wmsLayers} getBeforeId={getBeforeId} />}
              {wfsLayers && <WfsLayerView wfsLayers={wfsLayers} viewPort={viewPort} getBeforeId={getBeforeId} />}
              {bufferGeojson && (
                <Source id="bufferGeojsonSource" type="geojson" data={bufferGeojson}>
                  <Layer
                    {...{
                      id: "bufferGeojsonLayer",
                      type: "line",
                      paint: { "line-dasharray": [2, 1], "line-color": "#19b1c2" },
                    }}
                  ></Layer>
                </Source>
              )}
              {mapTitle && (
                <header className={classes.mapTitle}>
                  <Typography variant="h6" className={classes.titleBackground}>
                    {mapTitle}
                  </Typography>
                </header>
              )}
              <ScaleControl maxWidth={200} unit="metric" style={scaleControlStyle} />
              {loading && <BorderLinearProgress />}
            </StaticMap>
            {showPopup && popup}
          </DeckGL>
          <BaseLayerController activeMap={activeMap} maps={maps} setActiveMap={setActiveMap} />

          <Toolbox
            mode={mode}
            onSetMode={(m) => {
              setModeConfig({});
              setMode(m);
            }}
            modeConfig={modeConfig}
            onSetModeConfig={setModeConfig}
            geoJson={editableGeojson}
            onSetGeoJson={setEditableGeojson}
            onImport={setEditableGeojson}
          />
          <a href={logo.url} target="_blank">
            <img src={logo.img} alt="Logo" className={classes.logoStyle} />
          </a>
          <MapControls
            minZoom={activeMap?.minZoom ?? 10}
            maxZoom={24}
            zoomLevel={viewPort?.zoom || 10}
            zoomToBounds={zoomToBoundsHandler}
            setZoomLevel={(zoom) => setViewPort({ ...viewPort, zoom })}
            fullScreen={handle}
            intl={intl}
          />
        </div>
      </FullScreen>
    );
  },
);

const layerPrefix = (name) => name.replace(" ", "-").replace("_", "-").split("-")[0];

function groupLayers(reversedLayers) {
  const groups: { [k: string]: any } = {};
  for (let i = 0; i < reversedLayers.length; i++) {
    const layer = reversedLayers[i];

    const prefix = layerPrefix(layer.id);
    if (!groups[prefix]) {
      groups[prefix] = [];
    }
    groups[prefix].push(layer);
  }

  const treeData = Object.entries(groups).reduce((prevValue: any[], currentItem) => {
    const [prefix, layers] = currentItem;

    const newItem: { id: string; items: { id: string; visible: boolean }[] } = { id: prefix, items: [] };
    newItem.items = layers.map((layer) => ({ id: layer.id, visible: layer?.layout?.visibility === "visible" }));

    return [...prevValue, newItem];
  }, []);
  return treeData;
}

export const LayerControl = ({ style, updateStyle }: { style?: any | null; updateStyle: (done: any) => void }) => {
  const [groupedLayers, setGroupedLayers] = useState<LayerRoot[] | []>([]);

  useEffect(() => {
    if (!style?.layers) {
      return;
    }
    const grouped = groupLayers(style?.layers.filter((layer) => layer.type !== "raster"));
    setGroupedLayers(grouped);
  }, [style]);

  return (
    <LayerController
      data={groupedLayers}
      toggleLayer={(id, visible) => {
        const layer = style.layers.find((layer) => layer.id === id);
        if (layer) {
          layer.layout = {
            ...layer?.layout,
            ...{ visibility: visible ? "none" : "visible" },
          };
          updateStyle(JSON.parse(JSON.stringify(style)));
        }
      }}
      toggleGroup={(id, visible) => {
        const layers = style.layers.filter((layer) => layerPrefix(layer.id) === id);
        if (layers) {
          layers.forEach((layer) => {
            layer.layout = { visibility: visible ? "none" : "visible" };
          });
        }
        updateStyle(JSON.parse(JSON.stringify(style)));
      }}
    />
  );
};

const MapControls = hooksObserver(
  ({
    minZoom,
    maxZoom,
    zoomLevel = 10,
    setZoomLevel,
    fullScreen,
    zoomToBounds,
    onCaptureMap,
    intl: { formatMessage },
  }: {
    minZoom: number;
    maxZoom: number;
    zoomLevel: number;
    setZoomLevel: (zoomLevel: number) => void;
    setShowSidebar: (value: boolean) => void;
    fullScreen: FullScreenHandle;
    zoomToBounds: () => void;
    onCaptureMap: any;
    intl: InjectedIntl;
  }) => {
    const classes = useBaseMapStyles();

    return (
      <div style={{ right: 10, top: 10, position: "absolute" }}>
        <div className={classes.mapControls}>
          <Tooltip title={formatMessage(messages.mapZoomIn)} aria-label="zoom in">
            <Fab
              disabled={maxZoom <= Math.round(zoomLevel)}
              color="primary"
              size="small"
              style={{ color: "white", margin: 8 }}
              onClick={(event) => {
                setZoomLevel(zoomLevel + 1);
              }}
            >
              <ZoomInIcon />
            </Fab>
          </Tooltip>
          <Tooltip title={formatMessage(messages.mapZoomOut)} aria-label="zoom uit">
            <Fab disabled={minZoom >= Math.round(zoomLevel)} color="primary" size="small" style={{ color: "white", margin: 8 }} onClick={(e) => setZoomLevel(zoomLevel - 1)}>
              <ZoomOutIcon />
            </Fab>
          </Tooltip>
          <Tooltip title={formatMessage(messages.mapFullScreen)} aria-label="volledig scherm">
            <Fab disabled={false} color="primary" size="small" style={{ color: "white", margin: 8 }} onClick={fullScreen.active ? fullScreen.exit : fullScreen.enter}>
              {fullScreen.active ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </Fab>
          </Tooltip>
          {onCaptureMap && (
            <Tooltip title={formatMessage(messages.mapPrint)} aria-label="Print">
              <Fab disabled={false} color="primary" size="small" style={{ color: "white", margin: 8 }} onClick={(e) => onCaptureMap()}>
                <CaptureIcon />
              </Fab>
            </Tooltip>
          )}
          {zoomToBounds && (
            <Tooltip title={formatMessage(messages.mapZoomBoundsAddedItems)} aria-label="Bounds">
              <Fab color="primary" size="small" style={{ color: "white", margin: 8 }} onClick={(e) => zoomToBounds()}>
                <ZoomOutMapIcon />
              </Fab>
            </Tooltip>
          )}
          <Fab disabled={true} color="primary" size="small" style={{ color: "white", margin: 8 }}>
            {Math.round(zoomLevel * 100) / 100}
          </Fab>
        </div>
      </div>
    );
  },
);

export default injectIntl(BaseMap);

/**
 * Our mbtiles with relational data have got a data property
 * this is a json string with all relational fields
 * with this function we get the data and parse it to a json object
 * and then use the filter defined within the publication and filter
 * output only the fields within the filter
 * if data is empty we return an empty string array
 * @param filter
 * @param data
 */
const filterMbtilesRelationalDataObject = (filter: string[], data: string) => {
  if (data) {
    let nestedData = JSON.parse(data);
    nestedData = nestedData.map((nesteditem) =>
      filter.reduce((filterResult, currentFilter) => {
        if (nesteditem[currentFilter]) {
          filterResult[currentFilter] = nesteditem[currentFilter];
        }
        return filterResult;
      }, {}),
    );

    data = JSON.stringify(nestedData);
    return data;
  }

  return "[]";
};
