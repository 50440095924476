/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  mapWMTSBaselayer: {
    id: "map.wmts.baselayer",
    defaultMessage: "wmts base layer",
  },
  mapZoomIn: {
    id: "map.zoom.in",
    defaultMessage: "Zoom in",
  },
  mapZoomOut: {
    id: "map.zoom.out",
    defaultMessage: "Zoom uit",
  },
  mapFullScreen: {
    id: "map.full.screen",
    defaultMessage: "Volledig scherm",
  },
  mapPrint: {
    id: "map.print",
    defaultMessage: "Print",
  },
  mapZoomBoundsAddedItems: {
    id: "map.zoom.bounds.added.items",
    defaultMessage: "zoom to bounds of added items",
  },
  mapAddGeometry: {
    id: "map.add.geometry",
    defaultMessage: "Voeg geometrie toe aan editor",
  },
  mapViewRelationalData: {
    id: "map.view.relational.data",
    defaultMessage: "Bekijk relationele data",
  },
});
