import { action, makeObservable, observable } from "mobx";
import { deleteNotification, deleteNotifications, fetchNotifications } from "services/geo-core";

class NotificationModel {
  notifications: any[] = [];

  loadNotifications = async () => {
    this.notifications = await fetchNotifications();
  };

  addNotification = async (notification) => {
    this.notifications.push(notification);
  };

  removeNotification = async (id) => {
    const success = await deleteNotification(id);
    if (success) this.notifications = this.notifications.filter((notification) => notification.id !== id);
  };

  removeAllNotifications = async () => {
    const success = await deleteNotifications();
    if (success) this.notifications = [];
  };

  constructor() {
    makeObservable(this, {
      notifications: observable,
      loadNotifications: action.bound,
      addNotification: action.bound,
      removeNotification: action.bound,
      removeAllNotifications: action.bound,
    });
  }
}

export default NotificationModel;
