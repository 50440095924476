import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const drawerWidthMini = 57;

export const usePublicationViewerStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapContainer: {
      width: `calc(100% - ${drawerWidthMini}px)`,
      height: "100%",
      marginLeft: drawerWidthMini,
    },
    drawerContent: {
      padding: theme.spacing(2),
    },
    appbar: {
      top: 0,
      left: "auto",
      right: 0,
      position: "absolute",
      width: "100%",
      zIndex: 2000,
      flexShrink: 0,
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      background: "transparent",
      pointerEvents: "none",
    },
    title: {
      background: "white",
      color: "black",
      padding: 10,
      boxShadow: "0 1px 2px rgba(0,0,0,.1)",
    },
  }),
);

export const useStylesLarge = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawerMenu: {
      width: drawerWidthMini,
      height: "100%",
      maxHeight: "100%",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 2000,
      backgroundColor: "white",
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    },
    appBar: {
      transition: theme.transitions.create(["any"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: (props) => `calc(100% - ${props.drawerWidth}px)`,
      marginLeft: (props) => props.drawerWidth,
      transition: theme.transitions.create(["any"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: (props) => props.drawerWidth,
      flexShrink: 0,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    drawerPaper: {
      marginLeft: drawerWidthMini,
      width: (props) => props.drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
);

export const useStylesMini = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      overflow: "hidden",
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidthMini,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidthMini,
      transition: theme.transitions.create("margin-left", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: "hidden",
    },
    drawerClose: {
      marginLeft: 0,
      transition: theme.transitions.create("margin-left", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);

export default usePublicationViewerStyles;
