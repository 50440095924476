import { Button, Checkbox, Grid, ListItemIcon, TextField, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/styles";
import { FileTypes } from "@orbit/geo-core-shared";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import { ROUTE_DATAGROUPS } from "routes/RouteList";
import { deleteLayerGroup } from "services/geo-core";
import { DataLayerType } from "stores/DataLayerStore";
import { getLayerGroupStatusColor, getLayerGroupStatusLabel } from "../../../constants";
import CustomDialogView from "../../../views/CustomDialog/CustomDialogView";
import ListView from "../../ListView/ListView";
import useDatagroupsStyles from "./DatagroupsStyles";
import { InjectedIntl, injectIntl } from "react-intl";
import messages from "./messages";

const Datagroups: FunctionComponent<{
  classes: any;
  intl: InjectedIntl;
}> = observer(({ classes, intl: { formatMessage } }) => {
  const {
    dataGroupStore: { layergroups, loadLayergroupData, createLayergroup },
    dataLayerStore: { layers, loadData: loadMapData },
  } = useContext(StoresContext);

  const [selectedLayers, setSelectedLayers] = useState<string[]>([]);
  const [viewerName, setViewerName] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");

  const openDialog = () => {
    setDialogOpen(true);
  };

  useEffect(() => {
    loadLayergroupData();
    loadMapData(DataLayerType.VECTOR);
  }, [loadLayergroupData, loadMapData]);

  const closeDialog = () => {
    setDialogOpen(false);
    setViewerName("");
    setSelectedLayers([]);
    setShowError(false);
  };

  const toggleSelectedLayers = (layerId) => {
    selectedLayers.indexOf(layerId) !== -1 ? setSelectedLayers(selectedLayers.filter((value) => value !== layerId)) : setSelectedLayers(selectedLayers.concat([layerId]));
  };

  const createNewViewer = async () => {
    if (selectedLayers.length < 1 || viewerName === "") return;
    await createLayergroup(selectedLayers, viewerName);
  };

  async function handleDelete(activeLayerIdMenu: string) {
    await deleteLayerGroup(activeLayerIdMenu);
    loadLayergroupData();
    loadMapData(DataLayerType.VECTOR);
  }
  const AllowedCharacters = /^[a-zA-Z0-9\s\-_]*$/;
  const handleNameChange = (event: { target: { value: string } }) => {
    const { value } = event.target;
    if (!AllowedCharacters.test(value)) {
      setShowError(true);
    } else {
      setShowError(false);
    }
    setViewerName(value);
  };
  return (
    <Fragment>
      <Grid container spacing={6}>
        <Grid item xs={8}>
          <Button className={classes.floatingButton} variant="contained" color="primary" disableElevation size="large" onClick={openDialog} startIcon={<AddIcon />}>
            {formatMessage(messages.datagroupsNew)}
          </Button>
          <Typography variant="h4" gutterBottom>
            {formatMessage(messages.datagroupsDatagroups)}
          </Typography>
          <ListView
            hasStatus={true}
            singleItemName={formatMessage(messages.datagroupsSearchprompt)}
            hasDateModified={true}
            canDelete={true}
            path={ROUTE_DATAGROUPS}
            title={formatMessage(messages.datagroupsDatagroups)}
            searchprompt={formatMessage(messages.datagroupsSearchprompt)}
            items={layergroups.map((layergroup) => ({
              id: layergroup.id,
              dateCreated: layergroup.dateCreated,
              user: layergroup.user,
              dateModified: layergroup.dateModified,
              name: layergroup.name,
              avatarUrl: layergroup.avatarUrl,
              status: getLayerGroupStatusLabel(layergroup.status),
              statusColor: getLayerGroupStatusColor(layergroup.status),
            }))}
            handleDelete={handleDelete}
          />
        </Grid>
        <Grid item xs={4}>
          {/* <Typography variant="subtitle2" gutterBottom>
            Lorem Ipsum
          </Typography>
          <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non libero vitae massa vulputate gravida quis non sapien.
            Morbi a faucibus sapien. Donec ullamcorper pulvinar eros ac consectetur. Integer accumsan sollicitudin mi, a rutrum tortor
            ultricies nec. Nam a velit scelerisque, tempus tellus a, tincidunt ex. Aenean nunc magna, accumsan a leo sit amet, tincidunt
            pretium orci. Phasellus finibus, lectus quis gravida sollicitudin, ligula nunc tempus dui, at consectetur quam sem sit amet
            neque. Ut egestas sollicitudin risus, ut congue felis faucibus consequat. Ut tempus suscipit nunc, non rhoncus ante gravida
            vitae. Aliquam accumsan, dui quis facilisis cursus, diam magna suscipit dui, sed rhoncus dui elit quis nunc. Integer eget
            gravida velit.
          </Typography> */}
        </Grid>
      </Grid>
      <CustomDialogView
        key={"customDialog"}
        open={dialogOpen}
        handleClose={closeDialog}
        dialogTitle={formatMessage(messages.datagroupsNewDatagroup)}
        dialogContent={
          <Fragment>
            <Typography>{formatMessage(messages.datagroupsGroupName)}</Typography>
            <TextField
              error={showError}
              id="name"
              required
              value={viewerName}
              onChange={handleNameChange}
              fullWidth
              className={classes.nameField}
              helperText={showError ? formatMessage(messages.nameError) : ""}
            />
            <Typography>{formatMessage(messages.datagroupsSelectDatalayers)}</Typography>
            <TextField placeholder={formatMessage(messages.datagroupsSearch)} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} fullWidth />
            <List style={{ maxHeight: "300px", overflow: "scroll" }} className={classes.modalList}>
              {layers
                .filter((layer) => layer.status === "finished")
                .filter((layer) => layer.displayName.toLowerCase().includes(searchTerm))
                .filter((layer) => layer.fileType !== FileTypes.CSV)
                .map((layer) => {
                  return (
                    <ListItem key={layer.id} role={undefined} dense button onClick={() => toggleSelectedLayers(layer.id)}>
                      <ListItemIcon>
                        <Checkbox edge="start" checked={selectedLayers.indexOf(layer.id) !== -1} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": layer.id }} />
                      </ListItemIcon>
                      <ListItemText id={layer.id} primary={layer.displayName} />
                    </ListItem>
                  );
                })}
            </List>
          </Fragment>
        }
        dialogActions={
          <Fragment>
            <Button id="submit" onClick={closeDialog}>
              {formatMessage(messages.datagroupsCancel)}
            </Button>
            <Button
              disabled={selectedLayers.length === 0 || showError}
              id="submit"
              color="primary"
              variant="contained"
              onClick={() => {
                if (viewerName && viewerName !== "") {
                  createNewViewer();
                  closeDialog();
                } else {
                  setShowError(true);
                }
              }}
              disableElevation
            >
              {formatMessage(messages.datagroupsCreate)}
            </Button>
          </Fragment>
        }
      />
    </Fragment>
  );
});

export default withStyles(useDatagroupsStyles)(injectIntl(Datagroups));
