import { action, autorun, makeObservable } from "mobx";
import MapStore from "./MapStore";
import UiStore from "./UiStore";
import UploadStore from "./UploadStore";
import AuthStore from "./AuthStore";
import io from "socket.io-client";
import { ActionType, MessageObject, MessageTypes } from "@orbit/geo-core-shared";
import DataLayerStore from "./DataLayerStore";
import DataGroupStore from "./DataGroupStore";
import { v4 as uuidv4 } from "uuid";
import PublicationStore from "./PublicationStore";

export default class WebSocketStore {
  constructor(
    mapStore: MapStore,
    dataLayerStore: DataLayerStore,
    dataGroupStore: DataGroupStore,
    uiStore: UiStore,
    uploadStore: UploadStore,
    authStore: AuthStore,
    publicationStore: PublicationStore,
  ) {
    makeObservable(this, {
      connectToSocket: action.bound,
      disconnectFromSocket: action.bound,
    });

    this.mapStore = mapStore;
    this.dataLayerStore = dataLayerStore;
    this.dataGroupStore = dataGroupStore;
    this.uiStore = uiStore;
    this.uploadStore = uploadStore;
    this.authStore = authStore;
    this.publicationStore = publicationStore;
    this.browserIdentifier = uuidv4();

    autorun(() => {
      if (this.authStore.token) {
        this.connectToSocket();
      } else {
        this.disconnectFromSocket();
      }
    });
  }
  mapStore: MapStore;
  dataLayerStore: DataLayerStore;
  dataGroupStore: DataGroupStore;
  publicationStore: PublicationStore;
  uiStore: UiStore;
  uploadStore: UploadStore;
  authStore: AuthStore;
  browserIdentifier: string;
  socket;

  connectToSocket = () => {
    if (this.authStore.token) {
      this.socket = io(window.env.API_URL, {
        query: `auth_token=${this.authStore.token}&browserIdentifier=${this.browserIdentifier}`,
      });
      // Connection failed
      this.socket.on("error", function (err) {
        console.log(err.toString());
      });
      // Connection succeeded
      this.socket.on("success", function (data) {
        console.log("[Notifications socket connected]");
      });

      const handleSocket = ({ resourceId, updateProperties, action, type }: MessageObject) => {
        // console.log("handleSocket", { resourceId, updateProperties, action, type });
        if (action === ActionType.UPDATE) {
          if (type === MessageTypes.LAYER_GROUP) {
            this.dataGroupStore.updateLayergroup(resourceId, updateProperties);
            this.publicationStore.updateLayerGroup(resourceId, updateProperties);
          } else {
            this.dataLayerStore.updateLayer(resourceId, updateProperties);
            // this.uploadStore.removeFromTransformProgress(resourceName.toLowerCase());
          }

          /*this.uiStore.notifications.addNotification({
            id,
            message: '<MESSAGE>',
            title: '<TITLE>',
            resourceId,
            resourceName: '',
            url: "/",
            updateProperties,
            action,
            type,
            user
          });*/
        }
      };

      this.socket.on(MessageTypes.DATALAYER, handleSocket);
      this.socket.on(MessageTypes.CSV, handleSocket);
      this.socket.on(MessageTypes.LAYER_GROUP, handleSocket);
    }
  };

  disconnectFromSocket = () => {
    if (this.socket) {
      this.socket.removeAllListeners();
      this.socket.disconnect();
    }
  };
}
