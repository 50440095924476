// disabling flowtype to keep this example super simple
// It matches
/* eslint-disable flowtype/require-valid-file-annotation */

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ReorderIcon from "@material-ui/icons/Reorder";
import CircleIcon from "@mui/icons-material/Circle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { BaseLayerListObjectType, MapLayerOrderItem } from "@orbit/geo-core-shared";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

// a little function to help us with reordering the result
const reorder = (list: MapLayerOrderItem[], startIndex, endIndex): MapLayerOrderItem[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const reorderBase = (list: BaseLayerListObjectType[], startIndex, endIndex): BaseLayerListObjectType[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const SortLayers = observer(({ layers }: { layers: MapLayerOrderItem[] }) => {
  const {
    publicationStore: { setOrder },
  } = useContext(StoresContext);

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(layers || [], result.source.index, result.destination.index);

    setOrder(items);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(droppableProvided, droppableSnapshot) => (
          <List dense ref={droppableProvided.innerRef}>
            {layers.map((layer, index) => {
              const { id } = layer;
              return (
                <Draggable key={id} draggableId={id} index={index}>
                  {(draggableProvided, draggableSnapshot) => <SortItem layer={layer} draggableProvided={draggableProvided} layers={layers} />}
                </Draggable>
              );
            })}
            {droppableProvided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
});

const SortItem = observer(
  ({
    layer,
    draggableProvided,
    layers,
  }: {
    layer: MapLayerOrderItem;
    draggableProvided: any;
    layers: MapLayerOrderItem[];
  }) => {
    const { type, label, id } = layer;
    const {
      publicationStore: { saveLayerOrder },
    } = useContext(StoresContext);

    return (
      <ListItem key={id} ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps}>
        <ListItemIcon>
          <ReorderIcon />
        </ListItemIcon>
        <ListItemText primary={`[${type}] ${label}`} />
      </ListItem>
    );
  },
);

export const SortBaseLayers = observer(() => {
  const {
    publicationStore: { setSortedBaseLayers, sortedBaseLayers },
  } = useContext(StoresContext);

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorderBase(sortedBaseLayers || [], result.source.index, result.destination.index);

    setSortedBaseLayers(items);
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable2">
        {(droppableProvided, droppableSnapshot) => (
          <List dense ref={droppableProvided.innerRef}>
            {sortedBaseLayers.map((layer, index) => {
              const { id } = layer;
              return (
                <Draggable key={id} draggableId={id} index={index}>
                  {(draggableProvided, draggableSnapshot) => <SortBaseItem baselayer={layer} draggableProvided={draggableProvided} />}
                </Draggable>
              );
            })}
            {droppableProvided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
});

const SortBaseItem = observer(({ baselayer, draggableProvided }: { baselayer: BaseLayerListObjectType; draggableProvided: any }) => {
  const { title, id } = baselayer;
  return (
    <ListItem key={id} ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps}>
      <ListItemIcon>
        <ReorderIcon />
      </ListItemIcon>
      <ListItemText primary={`${title}`} />
    </ListItem>
  );
});
