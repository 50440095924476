import { observable, action, makeObservable } from "mobx";
import { BaseLayerListObjectType } from "@orbit/geo-core-shared";
import { createBaseLayer, fetchBaseLayer, fetchBaseLayers, updateBaseLayer } from "services/geo-core";

export default class BaseLayerStore {
  baseLayers: BaseLayerListObjectType[] = [];
  activeBaseLayer: BaseLayerListObjectType | null;

  clearActiveBaseLayer = () => {
    this.activeBaseLayer = null;
  };

  fetchActiveBaseLayer = async (id: string) => {
    this.clearActiveBaseLayer();
    const activeLayer: BaseLayerListObjectType = await fetchBaseLayer(id);
    this.activeBaseLayer = activeLayer;
  };

  loadBaseLayers = async () => {
    this.baseLayers = [];
    const baseLayers: BaseLayerListObjectType[] = await fetchBaseLayers();
    this.baseLayers = baseLayers;
  };

  updateBaseLayer = async (id: string, updatedProperties: any) => {
    try {
      const success = await updateBaseLayer(id, updatedProperties);
      if (success) {
        await this.fetchActiveBaseLayer(id);
      }
      return success;
    } catch (error) {
      console.error("Error updating base layer:", error.message);
      throw error;
    }
  };

  createBaseLayer = async (createProperties: any) => {
    try {
      const success = await createBaseLayer(createProperties);
      this.loadBaseLayers();
      return success;
    } catch (error) {
      console.error("Error creating base layer:", error.message);
      throw error;
    }
  };

  constructor() {
    makeObservable(this, {
      baseLayers: observable,
      activeBaseLayer: observable,
      clearActiveBaseLayer: action.bound,
      fetchActiveBaseLayer: action.bound,
      loadBaseLayers: action.bound,
      updateBaseLayer: action.bound,
    });
  }
}
