import { FileTypes } from "@orbit/geo-core-shared";
import CSVFileValidator from "csv-file-validator";
export type filesType = { [fieldname: string]: File[] } | File[];
export type groupedFilesType = { [fieldname: string]: File[] };
export type errorsType = { name: string; reason: string }[];

const config = {
  headers: [], // required
  isHeaderNameOptional: true, // default (optional)
};

export const groupByFilename = (files: filesType): groupedFilesType => {
  let groupedFiles: groupedFilesType = {};
  if (Array.isArray(files)) {
    groupedFiles = files.reduce((prev, item) => {
      const filename = item.name.replace(/\.[^/.]+$/, "");

      if (!prev[filename]) {
        prev[filename] = [];
      }

      prev[filename].push(item);

      return prev;
    }, {});
  }
  return groupedFiles;
};

const fileTypeValidator = {
  Shapefile: {
    type: FileTypes.SHP,
    extensions: ["shp", "shx", "dbf", "prj", "sld"],
    validator: (files) => {
      let result = files.length === 4 || files.length === 5;
      if (files.length === 4) {
        files.forEach((file) => {
          const extensionRegex = /(?:\.([^.]+))?$/;
          const extension = extensionRegex.exec(file.name);
          if (extension) {
            if (extension[1] === "sld") result = false;
          }
        });
      }
      return result;
    },
  },
  Geojson: {
    type: FileTypes.GEOJSON,
    extensions: ["geojson"],
    validator: (files) => files.length === 1,
  },
  Json: {
    type: FileTypes.GEOJSON,
    extensions: ["json"],
    validator: (files) => files.length === 1,
  },
  Csv: {
    type: FileTypes.CSV,
    extensions: ["csv"],
    validator: async (files) => {
      if (files.length > 1) {
        return false;
      }

      const { data, inValidMessages } = await CSVFileValidator(files[0], config);

      console.log({ data, inValidMessages });
      return inValidMessages.length === 0;
    },
  },
  JP2: {
    type: FileTypes.IMAGE,
    extensions: ["jp2"],
    validator: (files) => files.length === 1,
  },
  TIFF: {
    type: FileTypes.IMAGE,
    extensions: ["tiff", "tfw"],
    validator: (files) => {
      let result = files.length === 2;
      files.forEach((file) => {
        const extensionRegex = /(?:\.([^.]+))?$/;
        const extension = extensionRegex.exec(file.name);
        if (extension && !["tiff", "tfw"].includes(extension[1])) {
          result = false;
        }
      });

      return result;
    },
  },
};

export const getFileTypeForValidatorType = (validator: string): FileTypes => {
  return fileTypeValidator[validator]?.type;
};

export const groupByFileType = (files: filesType): groupedFilesType => {
  let groupedFiles: groupedFilesType = {};
  if (Array.isArray(files)) {
    groupedFiles = files.reduce((prev, item) => {
      const filename = item.name.replace(/\.[^/.]+$/, "");
      const extension = /(?:\.([^.]+))?$/;
      const regexresult = extension.exec(item.name);
      if (regexresult && regexresult[1]) {
        Object.entries(fileTypeValidator).forEach(([key, validator]) => {
          if (validator.extensions.includes(regexresult[1])) {
            if (!prev[key]) {
              prev[key] = [];
            }
            if (!prev[key][filename]) {
              prev[key][filename] = [];
            }
            prev[key][filename].push(item);
          }
        });
      }
      return prev;
    }, {});
  }
  return groupedFiles;
};

export const groupByFileTypeAndValidate = (files: filesType): { correctFiles: File[]; groupedFiles: groupedFilesType; errors: errorsType } => {
  let groupedFiles: groupedFilesType = {};
  const errors: errorsType = [];
  if (Array.isArray(files)) {
    groupedFiles = files.reduce((prev, item) => {
      let isValid = false;
      const filename = item.name.replace(/\.[^/.]+$/, "");
      const extension = /(?:\.([^.]+))?$/;
      const regexresult = extension.exec(item.name);
      if (regexresult && regexresult[1]) {
        Object.entries(fileTypeValidator).forEach(([key, validator]) => {
          if (validator.extensions.includes(regexresult[1])) {
            if (!prev[key]) {
              prev[key] = [];
            }
            if (!prev[key][filename]) {
              prev[key][filename] = [];
            }
            prev[key][filename].push(item);
            isValid = true;
          }
        });
      } else {
        errors.push({ name: item.name, reason: "Geen extensie gevonden!" });
      }
      if (!isValid) {
        errors.push({ name: item.name, reason: "Extensie niet toegelaten!" });
      }
      return prev;
    }, {});
  }
  const correctFiles: File[] = [];
  Object.entries(fileTypeValidator).forEach(([key, validator]) => {
    if (groupedFiles[key]) {
      Object.keys(groupedFiles[key]).forEach((fileGroup) => {
        if (!validator.validator(groupedFiles[key][fileGroup])) {
          errors.push({ name: fileGroup, reason: `${fileGroup} voldoet niet aan de eisen van een ${key}!` });
          delete groupedFiles[key][fileGroup];
        } else {
          groupedFiles[key][fileGroup].forEach((item) => {
            correctFiles.push(item);
          });
        }
      });
    }
  });
  return { correctFiles, groupedFiles, errors };
};
