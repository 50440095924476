import { makeStyles } from "@material-ui/core/styles";

export const useBaseMapStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    maxWidth: 500,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  logoStyle: {
    position: "absolute",
    bottom: "30px",
    right: "8px",
    height: "45px",
    opacity: 0.8,
    zIndex: 25,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  mapControls: {
    display: "flex",
    flexDirection: "column",
  },
  mapTitle: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
  },
  titleBackground: {
    boxShadow: "0 5px 5px rgba(0,0,0,.1)",
    background: "white",
    padding: 10,
  },
}));
