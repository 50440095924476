import { lighten } from "@material-ui/core/styles/colorManipulator";
import { Theme, createStyles } from "@material-ui/core/styles";

const useFileManagerStyles = (theme: Theme) =>
  createStyles({
    root: {
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    spacer: {
      flex: "1 1 100%",
    },
    actions: {
      color: theme.palette.text.secondary,
    },
    title: {
      flex: "0 0 auto",
    },
    actionExtra: {
      display: "flex",
    },
    button: {
      width: "260px",
      marginRight: "5px",
    },
    rotateArrow: {
      transform: "rotate(180deg)",
      marginRight: "15px",
    },
    tooltipSelect: {
      marginTop: "2px",
    },
  });

export default useFileManagerStyles;
