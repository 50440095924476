import { withStyles } from "@material-ui/styles";
import { observer } from "mobx-react-lite";
import React, { Fragment, FunctionComponent, useState, useContext, useEffect, useMemo } from "react";
import useDatalayerUploadStyles from "./DatalayerUploadStyles";
import FileUploader from "views/upload/FileUploader";
import { Button, FormControl, Grid, InputLabel, List, ListItem, MenuItem, Snackbar, Typography } from "@material-ui/core";
import SvelteJSONEditor from "views/Generic/json.editor";
import { Alert } from "@material-ui/lab";
import { StoresContext } from "index";
import { useParams } from "react-router";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { UPLOAD_TYPE } from "stores/UploadStore";
import { generateFormattedValue, getHtmlValue, mapValueSeperator } from "../files/FileManager";
import { toJS } from "mobx";
import { InjectedIntl, injectIntl } from "react-intl";
import messages from "./messages";

function getMenuItemLabel(uploadType: UPLOAD_TYPE, formatMessage): string {
  switch (uploadType) {
    case UPLOAD_TYPE.APPEND:
      return formatMessage(messages.datalayersFilesAppend);
    case UPLOAD_TYPE.REINDEX:
      return formatMessage(messages.datalayersFilesReindex);
    default:
      return "";
  }
}

const DatalayerUpload: FunctionComponent<{
  classes: any;
  intl: InjectedIntl;
}> = observer(({ classes, intl: { formatMessage } }) => {
  const {
    uploadStore: { uploadFiles },
    dataLayerStore: { layers, activeLayer, getActiveLayer, clearActiveLayer },
  } = useContext(StoresContext);

  const { id } = useParams<{ id: string }>();
  const [fileMapping, setFileMapping] = useState<{ json?: object; text?: string }>({ json: [] });
  const [hasError, setHasError] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [uploadType, setUploadType] = useState<UPLOAD_TYPE>(UPLOAD_TYPE.APPEND);
  const [fileList, setFileList] = useState<File[]>([]);
  const [customAlert, setCustomAlert] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const dropHandler = (ev, layer) => {
    ev.preventDefault();
    const droppedFiles: File[] = [];
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === "file") {
          const file: File = ev.dataTransfer.items[i].getAsFile();
          droppedFiles.push(file);
        }
      }
    } else {
      // @TODO: Blijkbaar komen we hiering indien de browser geen support heeft voor DataTransferItemList interface - zie https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        console.log("TODO... file[" + i + "].name = " + ev.dataTransfer.files[i].name);
      }
    }
    handleFileChange(droppedFiles, layer);
  };

  const dragOverHandler = (ev) => {
    //console.log('File(s) in drop zone');

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleFileChange = (files: File[], layer) => {
    if (!files) return;
    if (files.length > 0) {
      setFileList(files);
      files.map((file) => file.name);

      const formattedValue = generateFormattedValue(layer.fileMapping.map((item) => "0").join(mapValueSeperator), activeLayer?.fileMapping);

      setFileMapping({
        json: files.map((file) => ({
          fileName: file.name,
          fields: formattedValue,
          ...(uploadType === UPLOAD_TYPE.REINDEX && { id: 0 }),
        })),
      });
    }
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    getActiveLayer(id);
    return () => {
      clearActiveLayer();
    };
  }, [layers, id]);

  const handleUploadTypeChange = (event: SelectChangeEvent) => {
    setUploadType(event.target.value as UPLOAD_TYPE);
  };

  const handleFileUpload = async () => {
    const { success, message } = await uploadFiles(fileList, getHtmlValue(fileMapping), id, uploadType);
    if (success) {
      setOpen(true);
      setMessage(formatMessage(messages.datalayersFilesUploadDone));
      setFileMapping({ json: [] });
      setFileList([]);
      const item = document.getElementById("contained-button-file");
      item.value = null;
    } else {
      setOpen(true);
      setMessage(message);
    }
  };

  const validated = useMemo(() => {
    let returnval = true;
    try {
      if (fileList.length !== 0) {
        const useMapping = JSON.parse(getHtmlValue(fileMapping));
        if (Array.isArray(useMapping)) {
          if (useMapping.length === 0) {
            setCustomAlert(formatMessage(messages.datalayersFilesEditorError));
            returnval = false;
          }
          for (const file of useMapping) {
            const keys = Object.keys(file);
            if (!keys.includes("fileName")) {
              setCustomAlert(formatMessage(messages.datalayersFilesEditorFileName));
              returnval = false;
            }
            if (!keys.includes("fields")) {
              setCustomAlert(formatMessage(messages.datalayersFilesEditorFields));
              returnval = false;
            }
            if (uploadType === UPLOAD_TYPE.REINDEX && !keys.includes("id")) {
              setCustomAlert(formatMessage(messages.datalayersFilesEditorId));
              returnval = false;
            }
            // @TODO: need to see if we need validation on these, because we might not always link with id = 0, it might be a string like 'GHENT'
            // const fieldsKeys = Object.keys(file.fields);
            // if(!(fieldsKeys.includes('ogc_fid') || fieldsKeys.includes('id'))) {
            //   setCustomAlert('No ogc_fid or id key present.');
            //   returnval = false;
            // }
            // if(file.fields['ogc_fid'] === 0 || file.fields['id'] === 0) {
            //   setCustomAlert('Following keys cannot be zero: ogc_fid or id.');
            //   returnval = false;
            // }
          }
        } else {
          returnval = false;
        }
      }
    } catch (e) {
      returnval = false;
    }
    if (returnval) setCustomAlert("");
    return returnval;
  }, [fileMapping, fileList]);

  return (
    <Fragment>
      {!activeLayer?.fileMapping.length ? (
        <>
          <Typography variant="h4" gutterBottom>
            {formatMessage(messages.datalayersFilesTitle)}: {activeLayer?.displayName}
          </Typography>

          <Typography variant="body2" gutterBottom>
            {formatMessage(messages.datalayersFilesMapping)}
          </Typography>
        </>
      ) : (
        <>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={"success"}>
              {message}
            </Alert>
          </Snackbar>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button style={{ float: "right" }} disabled={hasError || !validated} onClick={handleFileUpload} variant="outlined">
                {formatMessage(messages.datalayersFilesStartUpload)}
              </Button>
              <span style={{ float: "right", marginRight: "5px", marginTop: "7px" }}>{customAlert}</span>
              <Typography variant="h4" gutterBottom>
                {formatMessage(messages.datalayersFilesTitle)}: {activeLayer?.displayName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {/* Currently disabled => Herindexing in bulk should be reviewed once it's necessary
              <FormControl fullWidth>
                <InputLabel id="typeLabel">{formatMessage(messages.datalayersFilesUploadType)}</InputLabel>
                <Select
                  sx={{marginBottom: 1}}
                  labelId="typeLabel"
                  id="demo-simple-select"
                  value={uploadType}
                  label="Upload type"
                  onChange={handleUploadTypeChange}
                >
                  {Object.values(UPLOAD_TYPE).map((uploadType) => (
                    <MenuItem key={uploadType} value={uploadType}>
                      {getMenuItemLabel(uploadType, formatMessage)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              <div className={classes.dropzone} id="dropzone" onDrop={(e) => dropHandler(e, activeLayer)} onDragOver={(e) => dragOverHandler(e)}>
                <div className={classes.centered}>
                  <Typography variant="caption" gutterBottom>
                    {formatMessage(messages.datalayersFilesDrag)}
                  </Typography>
                  <label htmlFor="contained-button-file">
                    <Button variant="outlined" component="span" className={classes.button}>
                      {formatMessage(messages.datalayersFilesSelect)}
                    </Button>
                  </label>
                </div>
              </div>
              <input
                className={classes.input}
                id="contained-button-file"
                type="file"
                multiple
                onChange={(event) => {
                  if (event.target.files) {
                    handleFileChange(Array.from(event.target.files), activeLayer);
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div style={{ height: "600px" }}>
                <SvelteJSONEditor
                  key="alerts"
                  content={fileMapping}
                  // @ts-ignore
                  onChange={(d, _, { contentErrors }) => {
                    setFileMapping(d);
                    setHasError(!(contentErrors?.validationErrors?.length === 0));
                  }}
                  mode="text"
                />
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </Fragment>
  );
});

export default withStyles(useDatalayerUploadStyles)(injectIntl(DatalayerUpload));
