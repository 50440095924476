import { Card, CardContent, Grid, makeStyles, Typography } from "@material-ui/core";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useBaseMap } from "../../../../views/Map/BaseMapContext";
import { InjectedIntl, injectIntl } from "react-intl";
import messages from "./messages";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export const SidebarRelations = observer(({ intl: { formatMessage } }: { intl: InjectedIntl }) => {
  const classes = useStyles();
  const {
    publicationStore: { searchFilter },
  } = useContext(StoresContext);

  const { state } = useBaseMap();
  return (
    <Grid item>
      <Typography variant="h5" gutterBottom>
        {formatMessage(messages.relationsRelationalData)}
      </Typography>
      <div className={classes.root}>
        <Grid container spacing={2}>
          {state.relationalItems.map((item) => (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  {Object.entries(item).map(([key, value]) => (
                    <li>
                      <b>{key}</b>:{value}
                    </li>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </Grid>
  );
});

injectIntl(SidebarRelations);
