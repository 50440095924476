import { Divider, Drawer, Icon, List, ListItem, ListItemIcon, Paper } from "@material-ui/core";
import CreateRoundedIcon from "@material-ui/icons/CreateRounded";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import LayersRoundedIcon from "@material-ui/icons/LayersRounded";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import clsx from "clsx";
import { StoresContext } from "index";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useContext, useEffect } from "react";
import { useState } from "react";
import { matchPath, Route, Switch, useHistory, useLocation, useParams } from "react-router";
import { ROUTE_PUBLICATION_VIEW, ROUTE_PUBLICATION_VIEW_LAYERS, ROUTE_PUBLICATION_VIEW_RELATIONS, ROUTE_PUBLICATION_VIEW_SEARCH } from "routes/RouteList";
import { createCombinedEnrichmentStyle } from "utils/enrichment";
import BaseMap from "views/Map/BaseMap";
import { useBaseMap } from "views/Map/BaseMapContext";
import { SidebarLayers } from "./layers/SidebarLayers";
import usePublicationViewerStyles, { useStylesLarge } from "./PublicationViewerStyles";
import { SidebarRelations } from "./relations/SidebarRelations";
import { SidebarSearch } from "./search/Sidebar";
import useEnrichment from "hooks/useEnrichment";
import useMapLocation from "hooks/useMapLocation";

export enum MenuVariants {
  MINI = "mini",
  LARGE = "large",
}
const SidebarWidthMap = {
  layers: 300,
  search: 800,
  relations: 500,
};

const PublicationViewer: FunctionComponent = observer(() => {
  const {
    publicationStore,
    enrichmentStore: { connectToSocket: connectEnrichmentSocket, geoJson: enrichmentGeoJson, name: enrichmentName },
    publicationStore: { activePublication, fetchActivePublicPublication, searchFilter, wfsLayers, wmsLayers, sortedLayers, sortedBaseLayers },
  } = useContext(StoresContext);

  let { push } = useHistory();
  let location = useLocation();

  const open: boolean = !!useRouteMatches(location.pathname, [ROUTE_PUBLICATION_VIEW_LAYERS, ROUTE_PUBLICATION_VIEW_SEARCH, ROUTE_PUBLICATION_VIEW_RELATIONS]);

  const drawerWidth = useLocationForWidth(SidebarWidthMap);

  const classes = usePublicationViewerStyles({ drawerWidth });
  const classesLarge = useStylesLarge({ drawerWidth });

  const { id } = useParams<{ id: string; enrichmentId?: string }>();
  const { enrichmentId } = useEnrichment();
  const { bbox } = useMapLocation();

  const { state, dispatch } = useBaseMap();

  const [mapStyleMerged, setMapStyleMerged] = useState();

  //const [wfsWithVisibleFeatureTypes, setWfsWithVisibleFeatureTypes] = useState(null)

  useEffect(() => {
    if (enrichmentId) {
      connectEnrichmentSocket(enrichmentId);
    }
  }, [enrichmentId]);

  useEffect(() => {
    fetchActivePublicPublication(id);
  }, [id]);

  function changeSidebar(route: string) {
    if (activePublication?.id) {
      push(route.replace(":id", activePublication?.id) + location.search);
    }
  }

  useEffect(() => {
    if (state.relationalItems.length > 0) changeSidebar(ROUTE_PUBLICATION_VIEW_RELATIONS);
  }, [state.relationalItems]);

  useEffect(() => {
    if (enrichmentGeoJson && activePublication?.style && enrichmentName) {
      const merged = createCombinedEnrichmentStyle(enrichmentGeoJson, enrichmentName, publicationStore.getPublicationActiveStyle());
      setMapStyleMerged(merged);
    }
  }, [enrichmentGeoJson, activePublication?.style, activePublication?.geoJsonStyle, enrichmentName]);

  /*useEffect(() => {
    if(activePublication?.transferLen meayer?.visible && activePublication?.transferLayer?.type === "wfs") {
      const transferLayer = activePublication?.transferLayer;
      transferLayer.featureTypes = transferLayer.featureTypes.filter(ft => ft.featureType.visible)
      setWfsWithVisibleFeatureTypes(transferLayer);
    }
  }, [activePublication])*/

  return (
    <>
      <Drawer
        className={classesLarge.drawer}
        variant="persistent"
        anchor="left"
        open={true}
        classes={{
          paper: classesLarge.drawerPaper,
        }}
        PaperProps={{ elevation: 10 }}
      >
        <div className={classes.drawerContent}>
          <Switch>
            <Route exact path={[ROUTE_PUBLICATION_VIEW_SEARCH]}>
              <SidebarSearch />
            </Route>
            <Route exact path={[ROUTE_PUBLICATION_VIEW_LAYERS]}>
              <SidebarLayers />
            </Route>
            <Route exact path={[ROUTE_PUBLICATION_VIEW_RELATIONS]}>
              <SidebarRelations />
            </Route>
          </Switch>
        </div>
      </Drawer>
      <main
        className={clsx(classesLarge.content, {
          [classesLarge.contentShift]: open,
        })}
      >
        <Paper elevation={open ? 0 : 10} className={classesLarge.drawerMenu}>
          <List>
            <ListItem button key={"orbit"} onClick={() => changeSidebar(ROUTE_PUBLICATION_VIEW)}>
              <ListItemIcon>
                <Icon className={"icon-logo-orbit-black"} />
              </ListItemIcon>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button key={"layers"} onClick={() => changeSidebar(ROUTE_PUBLICATION_VIEW_LAYERS)}>
              <ListItemIcon>
                <LayersRoundedIcon />
              </ListItemIcon>
            </ListItem>
            <ListItem button key={"Search"} onClick={() => changeSidebar(ROUTE_PUBLICATION_VIEW_SEARCH)}>
              <ListItemIcon>
                <SearchRoundedIcon />
              </ListItemIcon>
            </ListItem>
            <ListItem button key={"Relations"} onClick={() => changeSidebar(ROUTE_PUBLICATION_VIEW_RELATIONS)}>
              <ListItemIcon>
                <InfoRoundedIcon />
              </ListItemIcon>
            </ListItem>
            <ListItem disabled button key={"Edit"} onClick={() => console.log("no action")}>
              <ListItemIcon>
                <CreateRoundedIcon />
              </ListItemIcon>
            </ListItem>
            <ListItem disabled button key={"Print"} onClick={() => console.log("no action")}>
              <ListItemIcon>
                <PrintRoundedIcon />
              </ListItemIcon>
            </ListItem>
          </List>
          <Divider />
        </Paper>
        <div className={classes.mapContainer}>
          <BaseMap
            bounds={activePublication ? bbox || activePublication?.bbox : undefined}
            latLng={{
              lat: 50.98935,
              lng: 3.87118,
            }}
            baseLayers={sortedBaseLayers}
            mapStyle={mapStyleMerged || publicationStore.getPublicationActiveStyle()}
            dataFilter={searchFilter}
            mapTitle={activePublication?.name}
            wmsLayers={wmsLayers}
            wfsLayers={wfsLayers}
            sortedLayers={sortedLayers}
          />
        </div>
      </main>
    </>
  );
});

export default PublicationViewer;

const useLocationForWidth = (sidebarWidthMap: { [key: string]: number }, defaultValue = 0) => {
  let location = useLocation();
  const drawerWidth = Object.entries(sidebarWidthMap).reduce((acc, [key, value]) => {
    if (location.pathname.includes(key)) {
      acc = value;
    }
    return acc;
  }, defaultValue);

  return drawerWidth;
};

const useRouteMatches = (location, paths: string[]) => {
  return paths.find((path) =>
    matchPath(location, {
      path,
      exact: true,
      strict: false,
    }),
  );
};
