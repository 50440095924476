import React, { useContext, FunctionComponent, useEffect, Fragment, useState } from "react";
import { observer } from "mobx-react-lite";
import { StoresContext } from "index";
import { withStyles } from "@material-ui/styles";
import useBaseLayerStyles from "./BaseLayerStyles";
import { Typography, Grid, Button } from "@material-ui/core";
import { ROUTE_BASELAYERS } from "routes/RouteList";
import ListView from "containers/ListView/ListView";
import { green } from "@material-ui/core/colors";
import messages from "./messages";
import { InjectedIntl, IntlProvider, injectIntl } from "react-intl";
import BaseLayerDetail from "./BaseLayerDetail";
import BaselayerPopup from "./BaselayerPopup";
import { useParams } from "react-router";
import AddIcon from "@material-ui/icons/Add";
import CustomDialogView from "views/CustomDialog/CustomDialogView";
import { errorsType, groupedFilesType } from "utils/file.utils";
import { deleteBaselayer, deleteLayerGroup } from "services/geo-core";

const BaseLayers: FunctionComponent<{
  classes: any;
  intl: InjectedIntl;
}> = observer(({ classes, intl: { formatMessage } }) => {
  const {
    baseLayerStore: { baseLayers, loadBaseLayers },
    authStore: { canCreateBaselayer, canDeleteBaselayer },
  } = useContext(StoresContext);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const openDialog = () => {
    setDialogOpen(true);
  };
  const closeDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    loadBaseLayers();
  }, []);
  async function handleDelete(activeBaselayerId: string) {
    await deleteBaselayer(activeBaselayerId);
    await loadBaseLayers();
  }

  return (
    <Fragment>
      <Grid container spacing={6}>
        <Grid item xs={8} spacing={6}>
          <Button
            className={classes.floatingButton}
            variant="contained"
            color="primary"
            disabled={!canCreateBaselayer}
            disableElevation
            size="large"
            onClick={() => {
              openDialog();
            }}
            startIcon={<AddIcon />}
          >
            {formatMessage(messages.new)}
          </Button>
          <Typography variant="h4" gutterBottom>
            {formatMessage(messages.baselayersBaselayers)}
          </Typography>
          <ListView
            hasDateModified={false}
            singleItemName={formatMessage(messages.baselayersBaselayer)}
            hasStatus={false}
            canDelete={canDeleteBaselayer}
            handleDelete={handleDelete}
            path={ROUTE_BASELAYERS}
            title={formatMessage(messages.baselayersBaselayers)}
            searchprompt={formatMessage(messages.baselayersBaselayer)}
            items={baseLayers.map((baseLayer) => ({
              id: baseLayer.id,
              dateCreated: baseLayer.dateCreated,
              name: baseLayer.title,
              avatarUrl: baseLayer.avatarUrl,
              status: "",
              statusColor: green[500],
            }))}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" gutterBottom>
            {formatMessage(messages.baselayersBaselayerExplanationQuestion)}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {formatMessage(messages.baselayersBaselayerExplanation)}
          </Typography>
          <Typography variant="body2">{formatMessage(messages.baselayersBroughtBy)}</Typography>
        </Grid>
      </Grid>
      <CustomDialogView
        open={dialogOpen}
        handleClose={() => {
          closeDialog();
        }}
        dialogTitle={formatMessage(messages.newBaselayer)}
        dialogContent={
          <Fragment>
            <BaselayerPopup classes={classes} intl={formatMessage} action="create" closeDialog={closeDialog} />
          </Fragment>
        }
      />
    </Fragment>
  );
});

export default withStyles(useBaseLayerStyles)(injectIntl(BaseLayers));
