import { Button, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { FunctionComponent, ReactNode } from "react";
import useFileUploaderStyles from "./FileUploaderStyles";
import { groupedFilesType, errorsType, groupByFileTypeAndValidate } from "../../utils/file.utils";
import { toJS } from "mobx";
import { InjectedIntl, injectIntl } from "react-intl";
import messages from "./messages";
// import { toJS } from "mobx";

const FileUploader: FunctionComponent<{
  classes: any;
  accept?: string;
  multiple: boolean;
  children?: ReactNode;
  onChange: (files: File[], groupedFiles: groupedFilesType, errors: errorsType) => void;
}> = ({
  classes,
  children,
  accept,
  onChange,
  multiple = true,
  intl: { formatMessage },
}: {
  classes: any;
  accept?: string;
  multiple: boolean;
  children?: ReactNode;
  onChange: (files: File[], groupedFiles: groupedFilesType, errors: errorsType) => void;
  intl: InjectedIntl;
}) => {
  //const constraints = accept ? accept.toLowerCase().split(",") : [];
  //const regex = /(?:\.([^.]+))?$/;

  const dropHandler = (ev) => {
    ev.preventDefault();
    const droppedFiles: File[] = [];
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === "file") {
          const file: File = ev.dataTransfer.items[i].getAsFile();
          droppedFiles.push(file);
        }
      }
    } else {
      // @TODO: Blijkbaar komen we hiering indien de browser geen support heeft voor DataTransferItemList interface - zie https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        console.log("TODO... file[" + i + "].name = " + ev.dataTransfer.files[i].name);
      }
    }
    // valideren
    const { correctFiles, groupedFiles, errors } = groupByFileTypeAndValidate(droppedFiles);
    // console.log("correctFiles", toJS(correctFiles));
    // console.log("groupedFiles", toJS(groupedFiles));
    // console.log("errors", toJS(errors));
    onChange(correctFiles, groupedFiles, errors);
  };

  const dragOverHandler = (ev) => {
    //console.log('File(s) in drop zone');

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  };

  return (
    <div>
      {children}
      <div className={classes.dropzone} id="dropzone" onDrop={(e) => dropHandler(e)} onDragOver={(e) => dragOverHandler(e)}>
        <div className={classes.centered}>
          <Typography variant="caption" gutterBottom>
            {formatMessage(messages.uploadClickDrag)}
            {accept}
          </Typography>
          <label htmlFor="contained-button-file">
            <Button variant="outlined" component="span" className={classes.button}>
              {formatMessage(messages.uploadSelectFiles)}
            </Button>
          </label>
        </div>
      </div>
      <input
        accept={accept}
        className={classes.input}
        id="contained-button-file"
        type="file"
        multiple={multiple}
        onChange={(event) => {
          if (event.target.files) {
            const { correctFiles, groupedFiles, errors } = groupByFileTypeAndValidate(Array.from(event.target.files));
            console.log("groupedFiles", toJS(groupedFiles));
            onChange(correctFiles, groupedFiles, errors);
          }
        }}
      />
    </div>
  );
};

export default withStyles(useFileUploaderStyles)(injectIntl(FileUploader));
