import React, { FunctionComponent, useContext, useState, useEffect } from "react";
import { useParams } from "react-router";
import { StoresContext } from "../../../index";
import { observer } from "mobx-react-lite";
import { FormControl, InputLabel, Input, Typography, Select, MenuItem, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { BASELAYER_TYPES } from "../../../constants";
import messages from "./messages";
import { injectIntl } from "react-intl";

enum DialogAction {
  CREATE = "create",
  UPDATE = "update",
}

const BaselayerPopup: FunctionComponent<{
  classes: any;
  intl: any;
  action: DialogAction;
  closeDialog: () => void;
}> = observer(({ classes, intl: { formatMessage }, action, closeDialog }) => {
  const {
    baseLayerStore: { baseLayers, activeBaseLayer, fetchActiveBaseLayer, clearActiveBaseLayer, createBaseLayer, updateBaseLayer },
    authStore: { canEditBaselayer, canCreateBaselayer },
  } = useContext(StoresContext);
  const { id } = useParams<{ id: string }>();
  const [baseLayerType, setBaseLayerType] = useState(activeBaseLayer?.type || "");
  const [baseLayerStyleUrl, setBaseLayerStyleUrl] = useState(activeBaseLayer?.styleUrl || "");
  const [baseLayerAvatarUrl, setBaseLayerAvatarUrl] = useState(activeBaseLayer?.avatarUrl || "");
  const [baseLayerTitle, setBaseLayerTitle] = useState(activeBaseLayer?.title || "");
  const [newActiveBaseLayer, setNewActiveBaseLayer] = useState(activeBaseLayer || null);
  const [isFormValid, setIsFormValid] = useState(false); // Track form validity
  const [isDirty, setIsDirty] = useState(false);
  const [formErrors, setFormErrors] = useState({
    titleExist: "",
    avatarUrlValid: "",
    styleUrlValid: "",
  });
  const [updateBaselayerError, setUpdateBaselayerError] = useState("");
  const [createBaselayerError, setCreateBaselayerError] = useState("");
  const [loading, setLoading] = useState(false);
  const hasPermission = action === DialogAction.CREATE ? canCreateBaselayer : canEditBaselayer;

  useEffect(() => {
    if (!id) {
      return;
    }
    fetchActiveBaseLayer(id);
    return () => {
      clearActiveBaseLayer();
    };
  }, [baseLayers, id]);

  useEffect(() => {
    setNewActiveBaseLayer(activeBaseLayer);
  }, [activeBaseLayer]);

  useEffect(() => {
    setBaseLayerTitle(activeBaseLayer?.title || "");
    setBaseLayerType(activeBaseLayer?.type || "");
    setBaseLayerAvatarUrl(activeBaseLayer?.avatarUrl || "");
    setBaseLayerStyleUrl(activeBaseLayer?.styleUrl || "");
  }, [newActiveBaseLayer]);

  const handleSubmit = async (event: React.FormEvent) => {
    validateForm();
    event.preventDefault();
    setLoading(true);
    // Prepare the updated properties
    const baselayerProps = {
      title: baseLayerTitle,
      type: baseLayerType,
      avatarUrl: baseLayerAvatarUrl,
      styleUrl: baseLayerStyleUrl,
    };
    try {
      if (isFormValid) {
        if (action === DialogAction.CREATE) {
          await createBaseLayer(baselayerProps);
          setCreateBaselayerError("");
          closeDialog();
        }

        if (action === DialogAction.UPDATE) {
          await updateBaseLayer(id, baselayerProps);
          setUpdateBaselayerError("");
          setLoading(false);
        }
      }
    } catch (error) {
      if (action === DialogAction.CREATE) {
        setCreateBaselayerError(error.message);
        console.error("Error creating base layer:", error.message);
      }
      if (action === DialogAction.UPDATE) {
        setUpdateBaselayerError(error.message);
        console.error("Error updating base layer:", error.message);
      }
    }
  };

  // Function to check if a string is a valid URL
  const isValidUrl = (url: string) => {
    try {
      const parsedUrl = new URL(url);
      const protocolRegex = /^(https?):\/\//;
      return Boolean(parsedUrl && protocolRegex.test(url));
    } catch (error) {
      return false;
    }
  };
  // Function to validate the form
  const validateForm = () => {
    const isTitleExist = baseLayerTitle.trim() !== "";
    const isAvatarUrlValid = isValidUrl(baseLayerAvatarUrl);
    const isStyleUrlValid = isValidUrl(baseLayerStyleUrl);

    const isFormDirty =
      baseLayerTitle.trim() !== activeBaseLayer?.title.trim() ||
      baseLayerType !== activeBaseLayer?.type ||
      baseLayerAvatarUrl !== activeBaseLayer?.avatarUrl ||
      baseLayerStyleUrl !== activeBaseLayer?.styleUrl;

    const isFormValid = isTitleExist && isAvatarUrlValid && isStyleUrlValid && isFormDirty;

    setIsFormValid(isFormValid);
    setIsDirty(isFormDirty);
    setFormErrors({
      titleExist: isTitleExist ? "" : formatMessage(messages.baselayersDetailsErrorTitle),
      avatarUrlValid: isAvatarUrlValid ? "" : formatMessage(messages.baselayersDetailsErrorAvatarURL),
      styleUrlValid: isStyleUrlValid ? "" : formatMessage(messages.baselayersDetailsErrorStyleURL),
    });
  };

  useEffect(() => {
    if (action === DialogAction.UPDATE) {
      validateForm();
      setLoading(false);
    }
  }, [baseLayerTitle, baseLayerType, baseLayerAvatarUrl, baseLayerStyleUrl]);

  return (
    <form onSubmit={handleSubmit}>
      <FormControl component="fieldset" fullWidth className={classes.formControl} style={{ margin: "8px 0 32px" }}>
        <InputLabel htmlFor="baselayer_title">{formatMessage(messages.baselayersDetailsTitle)}</InputLabel>
        <Input
          id="baselayer_title"
          type="text"
          fullWidth
          disabled={!hasPermission}
          value={baseLayerTitle}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setBaseLayerTitle(event.target.value);
          }}
        />
        {!isFormValid && formErrors.titleExist !== "" && (
          <Typography variant="caption" color="error" style={{ marginTop: "2px" }}>
            {formErrors.titleExist}
          </Typography>
        )}
      </FormControl>

      <FormControl component="fieldset" fullWidth className={classes.formControl} style={{ marginBottom: "32px" }}>
        <InputLabel htmlFor="baselayer_avatarUrl">{formatMessage(messages.baselayersDetailsAvatarURL)}</InputLabel>
        <Input
          id="baselayer_avatarUrl"
          type="text"
          fullWidth
          disabled={!hasPermission}
          value={baseLayerAvatarUrl}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setBaseLayerAvatarUrl(event.target.value);
          }}
        />
        {!isFormValid && formErrors.avatarUrlValid !== "" && (
          <Typography variant="caption" color="error" style={{ marginTop: "2px" }}>
            {formErrors.avatarUrlValid}
          </Typography>
        )}
      </FormControl>
      <FormControl component="fieldset" fullWidth className={classes.formControl} style={{ marginBottom: "32px" }}>
        <InputLabel htmlFor="baselayer_styleUrl">{formatMessage(messages.baselayersDetailsStyleURL)}</InputLabel>
        <Input
          id="baselayer_styleUrl"
          type="text"
          fullWidth
          disabled={!hasPermission}
          value={baseLayerStyleUrl}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setBaseLayerStyleUrl(event.target.value);
          }}
        />
        {!isFormValid && formErrors.styleUrlValid !== "" && (
          <Typography variant="caption" color="error" style={{ marginTop: "2px" }}>
            {formErrors.styleUrlValid}
          </Typography>
        )}
      </FormControl>
      <FormControl component="fieldset" fullWidth className={classes.formControl} style={{ marginBottom: "64px" }}>
        <InputLabel htmlFor="baselayer_type">Type</InputLabel>
        <Select
          id="baselayer_type"
          disabled={!hasPermission}
          value={baseLayerType}
          onChange={(event: React.ChangeEvent<{ value: BASELAYER_TYPES }>) => {
            setBaseLayerType(event.target.value);
          }}
        >
          {Object.values(BASELAYER_TYPES).map((item: string) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        className={classes.button}
        onClick={() => {
          validateForm();
        }}
        fullWidth
        variant="contained"
        type="submit"
        color="primary"
        disabled={!hasPermission}
        style={{ marginBottom: "32px" }}
      >
        {action === DialogAction.UPDATE
          ? !isDirty
            ? formatMessage(messages.baselayersDetailsNoSave)
            : loading
              ? formatMessage(messages.baselayersDetailsSaving)
              : formatMessage(messages.baselayersDetailsSave)
          : formatMessage(messages.baselayersCreate)}
      </Button>

      {updateBaselayerError && <Alert severity="error">{updateBaselayerError}</Alert>}
      {createBaselayerError && <Alert severity="error">{createBaselayerError}</Alert>}
    </form>
  );
});

export default injectIntl(BaselayerPopup);
