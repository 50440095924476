import { Theme, createStyles } from "@material-ui/core/styles";

const useDatagroupMaputnikStyles = (theme: Theme) =>
  createStyles({
    card: {
      width: "100%",
      paddingTop: 64,
      "& button": {
        height: "100%",
      },
    },
    media: {
      width: "100%",
      height: "100%",
      border: 0,
    },
  });

export default useDatagroupMaputnikStyles;
