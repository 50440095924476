import React, { FunctionComponent } from "react";
import useCustomDialogStyles from "./CustomDialogStyles";
import { withStyles, useTheme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

interface titleProps {
  classes: any;
  onClose: any;
  children: any;
  id: any;
}

const DialogTitle = withStyles(useCustomDialogStyles)((props: titleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function PaperComponent(props) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

interface Props {
  classes: any;
  handleClose: any;
  open: any;
  dialogContent: any;
  dialogTitle?: any;
  dialogActions?: any;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  fullScreenSize?: Breakpoint;
}

const CustomDialogView: FunctionComponent<Props> = ({ classes, handleClose, open, dialogContent, dialogTitle, dialogActions, maxWidth = "sm", fullScreenSize = "sm" }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(fullScreenSize));

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={maxWidth}
      PaperComponent={PaperComponent}
    >
      {dialogTitle && (
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {dialogTitle}
        </DialogTitle>
      )}
      {dialogContent && (
        <DialogContent className={!fullScreen && classes.customDialog} dividers>
          {dialogContent}
        </DialogContent>
      )}
      {dialogActions && <DialogActions>{dialogActions}</DialogActions>}
    </Dialog>
  );
};

export default withStyles(useCustomDialogStyles)(CustomDialogView);
