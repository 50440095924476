import { Button, Hidden, IconButton, Toolbar, Tooltip, Typography } from "@material-ui/core";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import { withStyles } from "@material-ui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import useFileManagerStyles from "./FileManagerStyles";
import { InjectedIntl, injectIntl } from "react-intl";
import messages from "./messages";

const EnhancedTableToolbar = ({ classes, numSelected, buttonClick, notSelectedTooltip = "", extraAction = null, extraIcon = null, extraTooltip = "", intl: { formatMessage } }) => {
  return (
    <Toolbar>
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} {formatMessage(messages.filemanagertableselected)}.
          </Typography>
        ) : (
          notSelectedTooltip && (
            <Hidden smDown>
              <div className={classes.actionExtra}>
                <SubdirectoryArrowLeftIcon className={classes.rotateArrow} />
                <Typography variant="caption" className={classes.tooltipSelect}>
                  {notSelectedTooltip}
                </Typography>
              </div>
            </Hidden>
          )
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions && extraAction ? classes.actionExtra : null}>
        {numSelected > 0 && extraAction && (
          <React.Fragment>
            <Hidden smDown>
              <Button data-test-id="add-user-to-usergroup-btn" color="secondary" className={classes.button} onClick={extraAction}>
                {extraIcon}&nbsp;{extraTooltip}
              </Button>
            </Hidden>
            <Hidden mdUp>
              <Tooltip title={extraTooltip}>
                <IconButton aria-label={extraTooltip} onClick={extraAction}>
                  {extraIcon}
                </IconButton>
              </Tooltip>
            </Hidden>
          </React.Fragment>
        )}
        {numSelected > 0 ? (
          <Tooltip title={"delete"}>
            <IconButton data-test-id="user-delete-icon" aria-label={"delete"} onClick={buttonClick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    </Toolbar>
  );
};

export default withStyles(useFileManagerStyles)(injectIntl(EnhancedTableToolbar));
