import { BaseLayerListObjectType, MbtilesStyleInterface } from "@orbit/geo-core-shared";
import * as GeoJSON from "geojson";
import { action, makeObservable, observable, toJS } from "mobx";

/**
 * model used to control the layer of the maps
 * maplist is a json with al possible layers
 * when changing the activemapindex the map will be rerendered
 *
 */
//  const [tempStyle, setTempStyle] = React.useState<MbtilesStyleInterface | null>();
class BaseLayerModel {
  activeStyle: MbtilesStyleInterface | null;
  maps: BaseLayerListObjectType[] = [];
  activeMap: BaseLayerListObjectType | null;
  selectedGeomId: number | string | null;
  bbox: any;
  loading: boolean = false;

  setActiveStyle = (style: MbtilesStyleInterface) => {
    this.activeStyle = style;
  };

  setMaps = (maps: BaseLayerListObjectType[]) => {
    this.maps = maps || [];
    this.setActiveMap(maps?.[0]);
  };

  setActiveMap = (activemap) => {
    this.activeMap = this.maps.find((map) => JSON.stringify(activemap) === JSON.stringify(map)) ?? null;
  };

  setBBox = (bbox) => {
    this.bbox = this.bbox = bbox;
  };

  setLoading = (value) => {
    this.loading = value;
  };

  setSelectedGeomId = (selectedGeomId) => {
    this.selectedGeomId = selectedGeomId;
  };

  reset = () => {
    this.activeStyle = null;
    this.maps = [];
    this.activeMap = null;
    this.selectedGeomId = null;
    this.bbox = null;
  };

  constructor() {
    makeObservable(this, {
      activeStyle: observable,
      maps: observable,
      activeMap: observable,
      bbox: observable,
      selectedGeomId: observable,
      loading: observable,
      setLoading: action.bound,
      setActiveStyle: action.bound,
      setMaps: action.bound,
      setActiveMap: action.bound,
      setBBox: action.bound,
      setSelectedGeomId: action.bound,
      reset: action.bound,
    });
  }
}

export default BaseLayerModel;
