import { observable, action, makeObservable, toJS } from "mobx";
import { BaseLayerListObjectType, ConvertionTaskStatus, GeoFile, LayerListObjectType } from "@orbit/geo-core-shared";
import {
  fetchLayerData,
  fetchLayersData,
  deleteLayer,
  getFiles,
  removeFiles,
  createLocationIndex,
  updateLocationIndex,
  updateDataLayer,
  pushFileMapping,
  resetBboxOfDatalayer,
} from "services/geo-core";
export enum DataLayerType {
  VECTOR = "VECTOR",
  BITMAP = "BITMAP",
  CSV = "CSV",
}

const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

function reviver(key, value) {
  if (typeof value === "string" && dateFormat.test(value)) {
    return new Date(value);
  }

  return value;
}

var options = {
  weekday: "short",
  year: "numeric",
  month: "2-digit",
  day: "numeric",
};

interface Ictid {
  convertionTaskId: string;
  layerName: string;
}

export type CustomGeoFile = Ictid[] & GeoFile[];
export default class DataLayerStore {
  layers: LayerListObjectType[] = [];
  activeLayer: LayerListObjectType | null = null;
  table: { columns: any[]; rows: any[] } = { columns: [], rows: [] };
  baseLayers: BaseLayerListObjectType[];
  files: GeoFile[];
  amountOfFiles: number;
  esExists: boolean = false;
  esIndex: string = "";

  getActiveLayer = async (id: string) => {
    const { formattedLayer, table, baseLayers, esExists, esIndex } = await fetchLayerData(id);
    if (formattedLayer) this.activeLayer = formattedLayer;
    this.esExists = esExists;
    this.esIndex = esIndex;
    if (table) {
      table.columns = table.columns.map((col) => ({
        ...col,
        ...(col.type === "date" && { valueFormatter: ({ value }) => (value ? new Date(value).toLocaleString("nl-BE", options) : "") }),
      }));
      this.table = table;
    }
    if (baseLayers) {
      this.baseLayers = baseLayers;
    }
  };

  clearActiveLayer = () => {
    this.activeLayer = null;
    this.table = { columns: [], rows: [] };
    this.esExists = false;
    this.esIndex = "";
  };

  loadFiles = async (page: number, pageSize: number, convertionTaskId: string) => {
    const { files, amountOfFiles } = await getFiles(page, pageSize, convertionTaskId);
    this.files = files;
    this.amountOfFiles = amountOfFiles;
  };

  deleteFiles = async (files: string[]) => {
    await removeFiles(files);
  };

  createLocationIndex = async (id: string) => {
    const { success } = await createLocationIndex(id);
    if (success) {
      this.esExists = true;
    }
    return success;
  };

  updateLocationIndex = async (id: string, overwrite: boolean) => {
    const { success } = await updateLocationIndex(id, overwrite);
    return success;
  };

  updateFileMapping = async ({ id, fileMapping }: { id: string; fileMapping: string[] }) => {
    const { formattedLayer } = await pushFileMapping({
      convertionTaskId: id,
      fileMapping,
    });
    this.activeLayer = formattedLayer;
  };

  resetBoundingboxOfDatalayer = async (id: string): Promise<boolean> => {
    const { success } = await resetBboxOfDatalayer(id);
    return success;
  };

  constructor() {
    makeObservable(this, {
      layers: observable,
      files: observable,
      amountOfFiles: observable,
      activeLayer: observable,
      table: observable,
      baseLayers: observable,
      esExists: observable,
      getActiveLayer: action.bound,
      loadFiles: action.bound,
      clearActiveLayer: action.bound,
      deleteLayerWithId: action.bound,
      loadData: action.bound,
      deleteFiles: action.bound,
      addUploadedFilesToLayers: action.bound,
      updateLayer: action.bound,
      updateStyle: action.bound,
      createLocationIndex: action.bound,
      updateLocationIndex: action.bound,
      updateFileMapping: action.bound,
      updateImageStyle: action.bound,
      resetBoundingboxOfDatalayer: action.bound,
    });
  }

  addUploadedFilesToLayers(layers) {
    this.layers.push(...layers);
  }

  async loadData(type?: DataLayerType) {
    this.layers = [];
    const layersData: LayerListObjectType[] = await fetchLayersData(type);
    this.layers = layersData;
  }

  async updateStyle(style?: any) {
    if (!this.activeLayer) {
      return;
    }
    this.activeLayer.style = style;
  }

  async deleteLayerWithId(id) {
    try {
      await deleteLayer(id);
      this.layers = this.layers.filter((layer) => layer.id !== id);
    } catch (error) {
      console.error("Delete layer:", error.toString());
    }
  }

  async updateImageStyle({ newGeoTiffUrl, noDataColor }: { newGeoTiffUrl: string; noDataColor: string }) {
    if (!this.activeLayer) throw new Error("No active layer");
    const style = this.activeLayer?.style;
    if (!style) throw new Error("No style found");

    const currentTileUrl = style.sources?.["orbit-source"]?.tiles[0];
    if (!currentTileUrl) throw new Error("No tile url found");

    const newTileUrl = new URL(currentTileUrl);
    newTileUrl.searchParams.set("url", newGeoTiffUrl);
    if (noDataColor === "255" || noDataColor === "0") {
      newTileUrl.searchParams.set("nodata", noDataColor);
    } else {
      newTileUrl.searchParams.delete("nodata");
    }
    style.sources["orbit-source"].tiles[0] = decodeURIComponent(newTileUrl.toString());

    await updateDataLayer(this.activeLayer.id, { style: toJS(style) });
    await this.getActiveLayer(this.activeLayer.id);
  }
  updateLayer(id, updateProperties) {
    const dataLayer = this.layers.find((lg) => lg.id === id);
    if (dataLayer) {
      Object.entries(updateProperties || {}).forEach(([key, value]) => {
        dataLayer[key] = value;
      });
    }

    if (this.activeLayer && this.activeLayer.id === id) {
      if (updateProperties?.status) {
        this.activeLayer.status = updateProperties.status;
      }

      // Process after geosjon is finished (so reload data)
      if (updateProperties?.status === ConvertionTaskStatus.FINISHED) {
        this.getActiveLayer(id);
      }
    }
  }
}
