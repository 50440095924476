import { Card, CardActionArea, CardMedia } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import React, { Fragment, FunctionComponent, useContext, useEffect } from "react";
import useDatagroupMaputnikStyles from "./DatagroupMaputnikStyles";
import { useParams } from "react-router";
import { getBoundsZoom } from "../../../utils/map";

const DatagroupMaputnik: FunctionComponent<{
  classes: any;
}> = observer(({ classes }) => {
  const {
    dataGroupStore: { activeLayergroup, getActiveLayergroup, clearActiveLayergroup, layergroups },
    authStore: { token },
  } = useContext(StoresContext);

  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      return;
    }
    getActiveLayergroup(id);
    return () => {
      clearActiveLayergroup();
    };
  }, [layergroups, id]);

  return (
    <Fragment>
      <Card className={classes.card}>
        <CardActionArea>
          <CardMedia
            component="iframe"
            className={classes.media}
            src={`${window.env.MAPUTNIK_URL}?apiUrl=${window.env.API_URL}geo-core/layer-group/styles/&token=${token}&layerId=${id}${getBoundsZoom(
              activeLayergroup?.center,
              activeLayergroup?.bbox,
            )}`}
          />
        </CardActionArea>
      </Card>
    </Fragment>
  );
});

export default withStyles(useDatagroupMaputnikStyles)(DatagroupMaputnik);
