// /*
//  * Inputduration Messages
//  *
//  * This contains all the text for the Inputduration component.
//  */
import { defineMessages } from "react-intl";

export default defineMessages({
  layerLayers: {
    id: "layers.layers",
    defaultMessage: "Kaartlagen",
  },
});
