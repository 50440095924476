import { action, observable, makeObservable } from "mobx";
import axios from "axios";
import { groupedFilesType } from "../utils/file.utils";
import DataLayerStore from "./DataLayerStore";
import { ConvertionTaskActionTypes } from "@orbit/geo-core-shared";

export enum UPLOAD_TYPE {
  APPEND = "append",
  REINDEX = "reindex",
}
export default class UploadStore {
  constructor(dataLayerStore: DataLayerStore) {
    makeObservable(this, {
      isUploading: observable,
      uploadProgress: observable,
      transformProgress: observable,
      updateUploadProgress: action.bound,
      updateTransformProgress: action.bound,
      removeFromUploadProgress: action.bound,
      removeFromTransformProgress: action.bound,
      upload: action.bound,
    });

    this.dataLayerStore = dataLayerStore;
  }
  dataLayerStore: DataLayerStore;

  isUploading: boolean = false;

  uploadProgress: { [key: string]: number } = {};

  transformProgress: { [key: string]: number } = {};

  updateUploadProgress = async (file, progress) => {
    this.uploadProgress[file] = progress;
  };

  updateTransformProgress = async (file, progress) => {
    this.transformProgress[file] = progress;
  };

  removeFromUploadProgress = async (file) => {
    delete this.uploadProgress[file];
  };

  removeFromTransformProgress = async (file) => {
    delete this.transformProgress[file];
  };

  uploadFiles = async (files: File[], mapping: string, id: string, type: UPLOAD_TYPE) => {
    const url = `${window.env.API_URL}geo-core/file/bulk-upload/${id}/${type}`;
    // always need files if not reindexing
    if (files === undefined && type !== UPLOAD_TYPE.REINDEX) return;

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const formData = new FormData();

    try {
      if (type !== UPLOAD_TYPE.REINDEX && files !== undefined) {
        for (let file of files) {
          formData.append("geoFile[]", file);
        }
      } else {
        formData.append("geoFile[]", "");
      }

      formData.set("mapping", mapping);

      const {
        data: { data },
      } = await axios.post(`${url}`, formData, config);
      return data;
    } catch (error) {
      console.log("Upload files error ", error.toString());
    }
  };

  upload = async (groupedFiles: groupedFilesType, metaData?: object, action: ConvertionTaskActionTypes = ConvertionTaskActionTypes.CREATE, updateId: string = "") => {
    const url = `${window.env.API_URL}geo-core/upload-geoFile${updateId ? `/${updateId}` : ""}`;
    try {
      //for (let [, value] of Object.entries(files)) {
      for (let key of Object.keys(groupedFiles)) {
        for (let filekey of Object.keys(groupedFiles[key])) {
          const formData = new FormData();
          if (metaData) {
            formData.append("metaData", JSON.stringify(metaData));
          }
          if (action !== ConvertionTaskActionTypes.CREATE) {
            formData.append("action", action);
          }
          for (let file of groupedFiles[key][filekey]) {
            formData.append("geoFile[]", file);
          }
          const config = {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              if (percentCompleted === 100) {
                // this.updateTransformProgress(filekey.toLowerCase(), percentCompleted);
                this.removeFromUploadProgress(filekey.toLowerCase());
              } else {
                this.updateUploadProgress(filekey.toLowerCase(), percentCompleted);
              }

              //this.uploadProgress[filekey] = percentCompleted
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          const eventListener = (event) => {
            // Cancel the event as stated by the standard.
            event.preventDefault();
            // Chrome requires returnValue to be set.
            event.returnValue = "";
          };
          window.addEventListener("beforeunload", eventListener);
          const {
            data: { data },
          } = await axios[updateId ? "put" : "post"](`${url}`, formData, config);

          // if (Object.keys(metaData).length) await axios.put(`${url}`, { metaData });

          window.removeEventListener("beforeunload", eventListener);

          if (data.layers) {
            this.dataLayerStore.addUploadedFilesToLayers(data.layers);
          }
        }
      }
    } catch (error) {
      console.log("something went wrong with fetching ", error.toString());
    }
  };
}
