import React, { FunctionComponent, useContext } from "react";
import { history } from "./history";
import { IntlProvider } from "react-intl";
import { Router } from "react-router";
import { translationMessages } from "./i18n";
import AppRoute from "./routes/AppRoute";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { light } from "./layout/theme.js";
import { StoresContext } from "./index";
import { observer } from "mobx-react-lite";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";

import "./App.css";
import { LocalizationProvider } from "@material-ui/pickers";
const App: FunctionComponent<{}> = observer(() => {
  const {
    uiStore: { locale },
  } = useContext(StoresContext);

  return (
    <IntlProvider locale={locale} messages={translationMessages[locale]}>
      <Router history={history}>
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          <ThemeApp />
        </LocalizationProvider>
      </Router>
    </IntlProvider>
  );
});

const ThemeApp = () => {
  /*const {
    settingsStore: { darkMode }
  } = useContext(StoresContext);*/

  let theme = light;

  return (
    <MuiThemeProvider theme={theme}>
      <AppRoute />
    </MuiThemeProvider>
  );
};

export default App;
