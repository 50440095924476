import { observable, action, makeObservable } from "mobx";
import { fetchAnalyseConfigs } from "../services/geo-core";
import { AnalyseConfig } from "@orbit/geo-core-shared";

export default class AnalyseStore {
  analyseConfigs: AnalyseConfig[] = [];

  loadAnalyseConfigs = async () => {
    const configs = await fetchAnalyseConfigs();
    this.analyseConfigs = configs;
  };

  constructor() {
    makeObservable(this, {
      analyseConfigs: observable,
      loadAnalyseConfigs: action.bound,
    });
  }
}
