import * as React from "react";

// Initial State
const initialState: BaseMapState = {
  name: "Context Reducer Pattern",
  activeIndex: 0,
  selectedItems: [],
  relationalItems: [],
  geom: null,
};

// Creating Context
export const BaseMapContext = React.createContext<{
  state: BaseMapState;
  dispatch: React.Dispatch<BaseMapActions>;
}>({
  state: initialState,
  dispatch: () => {
    throw new Error("<BaseMapContextProvider /> missing from render tree");
  },
});

export const BaseMapContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(BaseMapContextReducer, initialState);

  return (
    <BaseMapContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </BaseMapContext.Provider>
  );
};

// Reducer
export const BaseMapContextReducer = (state: BaseMapState, action: BaseMapActions): BaseMapState => {
  switch (action.type) {
    case BaseMapActionTypes.SET_SELECTED_ITEMS:
      return {
        ...state,
        activeIndex: action.activeIndex,
        selectedItems: action.selectedItems,
        geom: null,
      };
    case BaseMapActionTypes.SET_ACTIVE_INDEX:
      return {
        ...state,
        activeIndex: action.activeIndex,
        geom: null,
      };
    case BaseMapActionTypes.SET_RELATIONAL_ITEMS:
      return {
        ...state,
        relationalItems: action.relationalItems,
        geom: null,
      };
    case BaseMapActionTypes.RESET:
      return initialState;
    case BaseMapActionTypes.SET_GEOM:
      return {
        ...state,
        geom: action.geom,
      };
    default:
      return state;
  }
};

export enum BaseMapActionTypes {
  RESET = "RESET",
  SET_ACTIVE_INDEX = "SET_ACTIVE_INDEX",
  SET_SELECTED_ITEMS = "SET_SELECTED_ITEMS",
  SET_RELATIONAL_ITEMS = "SET_RELATIONAL_ITEMS",
  SET_GEOM = "SET_GEOM",
}

// Types
type BaseMapActions =
  | {
      type: BaseMapActionTypes.SET_SELECTED_ITEMS;
      activeIndex: number;
      selectedItems: SelectedItem[];
    }
  | {
      type: BaseMapActionTypes.SET_ACTIVE_INDEX;
      activeIndex: number;
    }
  | {
      type: BaseMapActionTypes.SET_RELATIONAL_ITEMS;
      relationalItems: SelectedItem[];
    }
  | {
      type: BaseMapActionTypes.SET_GEOM;
      geom: any;
    }
  | { type: "RESET" };

type SelectedItem = { [key: string]: string };

type BaseMapState = {
  name: string;
  activeIndex: number;
  selectedItems: SelectedItem[];
  relationalItems: SelectedItem[];
  geom: any;
};

export const useBaseMap = () => React.useContext(BaseMapContext);
