import React, { useContext, FunctionComponent, useState, useRef, Fragment } from "react";
import { StoresContext } from "index";
import { MessageTypes } from "@orbit/geo-core-shared";
import useHeaderStyles from "./HeaderStyles";
import {
  IconButton,
  Badge,
  Popover,
  Box,
  List,
  ListItem,
  ListSubheader,
  LinearProgress,
  ListItemText,
  lighten,
  Divider,
  ListItemSecondaryAction,
  Typography,
  withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { ROUTE_BASE } from "routes/RouteList";
import { observer as hooksObserver } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { InjectedIntl, injectIntl } from "react-intl";
import messages from "./messages";

const useStyles = makeStyles({
  colorPrimary: {
    backgroundColor: lighten("#4caf50", 0.75),
  },
  barColorPrimary: {
    backgroundColor: "#4caf50",
  },
});

interface Props {
  classes: any;
  intl: InjectedIntl;
}

const NotificationView: FunctionComponent<Props> = hooksObserver(({ classes, intl: { formatMessage } }) => {
  const {
    uiStore: { notifications: { notifications, removeNotification, removeAllNotifications } },
    uploadStore: { uploadProgress, transformProgress },
  } = useContext(StoresContext);

  let { push } = useHistory();

  const [popoverAnchor, setPopoverAnchor] = useState(null);

  const progressClasses = useStyles();

  const handleNotificationClick = (notification) => {
    switch (notification.type) {
      case MessageTypes.DATALAYER:
      case MessageTypes.CSV:
        push(ROUTE_BASE + notification.url);
        break;
      case MessageTypes.LAYER_GROUP:
        console.log("layergroup", notification);
        break;
      default:
        console.log("TODO// volgende type nog binden:", notification.type, notification);
        break;
    }
  };

  const handlePopoverClick = (event) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  const open = Boolean(popoverAnchor);
  const id = open ? "simple-popover" : undefined;

  return (
    <Fragment>
      <IconButton aria-describedby={id} color="primary" onClick={handlePopoverClick} className={classes.notificationButton}>
        {(Object.keys(uploadProgress).length + Object.keys(transformProgress).length + notifications.length > 0 && (
          <Badge badgeContent={Object.keys(uploadProgress).length + Object.keys(transformProgress).length + notifications.length} color="secondary">
            <NotificationsIcon />
          </Badge>
        )) || <NotificationsIcon />}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={popoverAnchor}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.notificationWindow}
      >
        <Box className={classes.notificationRoot}>
          <Box className={classes.notificationHeader}>
            {notifications.length > 0 && (
              <IconButton className={classes.removeNotifications} edge="end" aria-label="delete" size="small" onClick={removeAllNotifications}>
                <ClearAllIcon />
              </IconButton>
            )}
            <Typography variant="h6">{formatMessage(messages.headerNotifications)}</Typography>
          </Box>
          <Box className={classes.notificationContent}>
            {Object.keys(uploadProgress).length > 0 && (
              <List
                dense
                aria-labelledby="uploaden-subheader"
                subheader={
                  <ListSubheader component="div" id="uploaden-subheader">
                    {formatMessage(messages.headerUpload)}
                  </ListSubheader>
                }
              >
                {Object.entries(uploadProgress).map(([key, value]) => (
                  <ListItem>
                    <ListItemText
                      primary={key}
                      secondaryTypographyProps={{ component: "span" }} // to avoid "div cannot be child of p" error
                      secondary={
                        <React.Fragment>
                          <LinearProgress
                            variant="determinate"
                            value={Math.round(value)}
                            classes={{
                              colorPrimary: progressClasses.colorPrimary,
                              barColorPrimary: progressClasses.barColorPrimary,
                            }}
                          />
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
            {Object.keys(uploadProgress).length > 0 && <Divider />}
            {Object.keys(transformProgress).length > 0 && (
              <List
                dense
                aria-labelledby="verwerken-subheader"
                subheader={
                  <ListSubheader component="div" id="verwerken-subheader">
                    {formatMessage(messages.headerProcess)}
                  </ListSubheader>
                }
              >
                {Object.entries(transformProgress).map(([key, value]) => (
                  <ListItem key={key}>
                    <ListItemText
                      primary={key}
                      secondaryTypographyProps={{ component: "span" }} // to avoid "div cannot be child of p" error
                      secondary={
                        <React.Fragment>
                          <LinearProgress color="secondary" />
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
            {Object.keys(transformProgress).length > 0 && <Divider />}
            {notifications.length > 0 && (
              <List dense component="nav">
                {notifications.map((notification) => (
                  <ListItem button onClick={() => handleNotificationClick(notification)}>
                    <ListItemText secondary={notification.message} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" size="small" onClick={() => removeNotification(notification.id)}>
                        <CloseIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
            {Object.keys(uploadProgress).length + Object.keys(transformProgress).length + notifications.length === 0 && (
              <Box className={classes.notificationNoMessages}>
                <Typography variant="body2">{formatMessage(messages.headerNoNewNotifications)}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Popover>
    </Fragment>
  );
});

export default withStyles(useHeaderStyles)(injectIntl(NotificationView));
