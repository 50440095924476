import { LatLngType, Location, MapListType } from "@orbit/geo-core-shared";
import { action, makeObservable, observable } from "mobx";
import { getLocation } from "../services/geo";
import BaseLayerModel from "./models/BaseLayerModel";

export default class MapStore {
  latLng: LatLngType = {
    lat: 50.98935,
    lng: 3.87118,
  };

  bounds: any = [
    [0, 0],
    [0, 0],
  ];

  /**
   * model used to control the layer of the maps
   * basemaps
   */
  baseLayers: BaseLayerModel = new BaseLayerModel();

  mapList: MapListType = {};

  activeMaps: string[] = [];

  zoomLevel: number = 14;

  editableGeojson: { type: string; features: GeoJSON.Feature[] } = { type: "FeatureCollection", features: [] };

  setEditableGeojson(editableGeojson: { type: string; features: GeoJSON.Feature[] }): void {
    this.editableGeojson = editableGeojson;
  }

  toggleActiveMapItem = (key: string) => {
    if (this.activeMaps.includes(key)) {
      this.activeMaps = this.activeMaps.filter((e) => e !== key);
    } else {
      this.activeMaps.push(key);
    }
  };

  setLatLng = (latLng: LatLngType) => {
    this.latLng = latLng;
  };

  setBounds = (bounds: any, pad = 0.3) => {
    if (bounds) bounds = bounds.pad(pad);
    this.bounds = bounds;
  };

  setZoomLevel = (zoomLevel: number) => {
    this.zoomLevel = zoomLevel;
  };

  constructor() {
    makeObservable(this, {
      baseLayers: observable,
      mapList: observable,
      activeMaps: observable,
      latLng: observable,
      bounds: observable,
      zoomLevel: observable,
      toggleActiveMapItem: action.bound,
      setLatLng: action.bound,
      setBounds: action.bound,
      setZoomLevel: action.bound,
      searchLatLongForLocation: action.bound,
      editableGeojson: observable,
      setEditableGeojson: action.bound,
    });
  }

  async searchLatLongForLocation(location: Location) {
    const result: any = await getLocation(location); // todo
    this.latLng = result;
  }
}
