import { Box, Button, FormControl, Input, InputLabel, MenuItem, Select, Typography, withStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useContext, useState } from "react";
import { injectIntl } from "react-intl";
import { useParams } from "react-router";
import { StoresContext } from "index";
import useDatasetsStyles from "./DatalayersStyles";
import messages from "./messages";
import { createDatalayer } from "services/geo-core";
import { DataLayerType } from "stores/DataLayerStore";

export enum ImageLayerFormAction {
  CREATE = "create",
  UPDATE = "update",
}

export enum NoDataColorTypes {
  WHITE = "255",
  BLACK = "0",
  NONE = "none",
}

const DatalayerImageForm: FunctionComponent<{
  classes: any;
  intl: any;
  action: ImageLayerFormAction;
  defaultImageLayerUrl?: string;
  defaultNoDataColor?: NoDataColorTypes;
  closeDialog?: () => void;
}> = observer(({ classes, intl: { formatMessage }, action, defaultNoDataColor = NoDataColorTypes.NONE, defaultImageLayerUrl = "", closeDialog }) => {
  const {
    dataLayerStore: { loadData, updateImageStyle },
  } = useContext(StoresContext);
  const { id } = useParams<{ id: string }>();
  const [imageLayerUrl, setImageLayerUrl] = useState(defaultImageLayerUrl);
  const [noDataColor, setNoDataColor] = useState(defaultNoDataColor || NoDataColorTypes.NONE);
  const [formErrors, setFormErrors] = useState({
    imageLayerValidUrl: "",
  });
  const [isError, setIsError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (defaultImageLayerUrl === imageLayerUrl && defaultNoDataColor === noDataColor) return;

    if (isValidUrl(imageLayerUrl)) {
      setLoading(true);
      try {
        if (id) {
          await updateImageStyle({ newGeoTiffUrl: imageLayerUrl, noDataColor });
        } else {
          await createDatalayer({ type: DataLayerType.BITMAP, data: { geoTiffUrl: imageLayerUrl } });
          try {
            await loadData(DataLayerType.BITMAP);
          } catch (er) {}
        }
        closeDialog?.();
      } catch (error) {
        setIsError(action === ImageLayerFormAction.CREATE ? formatMessage(messages.datalayersImageErrorCreateUnknown) : formatMessage(messages.datalayersImageErrorUpdateUnknown));
      }
      setLoading(false);
    } else {
      setFormErrors({
        imageLayerValidUrl: formatMessage(messages.datalayersImageErrorUrl),
      });
    }
  };

  // Function to check if a string is a valid URL
  const isValidUrl = (url: string) => {
    try {
      const parsedUrl = new URL(url);
      const protocolRegex = /^(https?):\/\//;
      return Boolean(parsedUrl && protocolRegex.test(url));
    } catch (error) {
      return false;
    }
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setImageLayerUrl(value);
    setFormErrors({ imageLayerValidUrl: !isValidUrl(value) ? formatMessage(messages.datalayersImageErrorUrl) : "" });
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl component="fieldset" fullWidth className={classes.formControl} style={{ marginBottom: "32px" }}>
        <InputLabel htmlFor="imageLayerUrl">{formatMessage(messages.datalayersImageGeoTiffUrlLabel)}</InputLabel>
        <Input id="imageLayerUrl" type="text" fullWidth value={imageLayerUrl} disabled={loading} onChange={handleUrlChange} />
        {formErrors.imageLayerValidUrl !== "" && (
          <Typography variant="caption" color="error" style={{ marginTop: "2px" }}>
            {formErrors.imageLayerValidUrl}
          </Typography>
        )}
      </FormControl>
      {action !== ImageLayerFormAction.CREATE && (
        <FormControl component="fieldset" fullWidth className={classes.formControl} style={{ marginBottom: 20 }}>
          <InputLabel htmlFor="baselayer_type">Geen data kleur</InputLabel>
          <Select
            id="imageLayerNoDataColor"
            disabled={loading}
            value={noDataColor}
            onChange={(event: React.ChangeEvent<{ value: NoDataColorTypes }>) => {
              setNoDataColor(event.target.value);
            }}
          >
            {Object.values(NoDataColorTypes).map((item: string) => (
              <MenuItem key={item} value={item}>
                {formatMessage(messages[`datalayersImageNoDataValue${item}`])}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {isError && <Alert severity="error">{isError}</Alert>}
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        {action === ImageLayerFormAction.CREATE && (
          <Button
            className={classes.button}
            disabled={loading}
            style={{ marginRight: 20 }}
            onClick={() => {
              closeDialog?.();
            }}
          >
            {formatMessage(messages.cancel)}
          </Button>
        )}
        <Button
          className={classes.button}
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading || (defaultImageLayerUrl === imageLayerUrl && defaultNoDataColor === noDataColor)}
          {...(action === ImageLayerFormAction.CREATE ? {} : { fullWidth: true, variant: "outlined" })}
        >
          {action === ImageLayerFormAction.CREATE ? formatMessage(messages.datalayersImageCreate) : formatMessage(messages.datalayersImageSave)}
        </Button>
      </Box>
    </form>
  );
});

export default withStyles(useDatasetsStyles)(injectIntl(DatalayerImageForm));
