import { Theme, createStyles } from "@material-ui/core/styles";

const useDatalayerUploadStyles = (theme: Theme) =>
  createStyles({
    input: {
      display: "none",
    },
    button: {
      marginTop: 20,
      display: "block",
    },
    dropzone: {
      height: 200,
      width: "100%",
      borderColor: "#CCC", //theme.palette.primary.light,
      borderWidth: 1,
      borderStyle: "dashed",
      borderRadius: 4,
      display: "grid",
      padding: 20,
      marginBottom: 1,
    },
    centered: {
      margin: "auto",
    },
  });

export default useDatalayerUploadStyles;
