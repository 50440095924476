import { Button, Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/styles";
import ListView from "containers/ListView/ListView";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import Papa from "papaparse";
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import { ROUTE_DATALAYERS } from "routes/RouteList";
import { deleteLayer } from "services/geo-core";
import SimpleTable from "views/Table/SimpleTable";
import { getConvertionTaskStatusColor, getConvertionTaskStatusLabel } from "../../../constants";
import { DataLayerType } from "../../../stores/DataLayerStore";
import { errorsType, groupedFilesType } from "../../../utils/file.utils";
import CustomDialogView from "../../../views/CustomDialog/CustomDialogView";
import FileUploader from "../../../views/upload/FileUploader";
import useDatasetsStyles from "./DatalayersStyles";
import messages from "./messages";

// const encodings = ["utf-8", "utf-16", "utf-32", "sjis", "shift_jis", "eucjp", "euc-jp", "jis", "iso-2022-jp"];

const DataTablelayers: FunctionComponent<{
  classes: any;
  type: DataLayerType;
  intl: InjectedIntl;
}> = observer(({ classes, type, intl: { formatMessage } }) => {
  const {
    dataLayerStore: { layers, loadData, deleteLayerWithId },
    uploadStore,
  } = useContext(StoresContext);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [groupedFiles, setGroupedFiles] = useState<groupedFilesType>({});
  const [csvData, setCsvData] = useState<{ meta: Object; data: any[] }>({ meta: {}, data: [] });
  const [fileEncoding] = useState<string>("utf-8");

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const clearFiles = async () => {
    setFiles([]);
    setCsvData({ meta: {}, data: [] });
  };

  const uploadFiles = async (metaData) => {
    await uploadStore.upload(groupedFiles, metaData);
    clearFiles();
  };

  useEffect(() => {
    loadData(DataLayerType.CSV);
  }, []);

  useEffect(() => {
    if (!files[0]) {
      return;
    }
    setCsvData({ meta: {}, data: [] });
    Papa.parse(files[0], {
      dynamicTyping: true,
      chunkSize: 3,
      header: true,
      endoding: "utf-8",
      complete: function (responses) {
        setCsvData(responses);
      },
    });
  }, [fileEncoding, files]);

  async function handleDelete(activeLayerIdMenu: string) {
    await deleteLayer(activeLayerIdMenu);
    deleteLayerWithId(activeLayerIdMenu);
  }

  return (
    <Fragment>
      <Grid container spacing={6}>
        <Grid item xs={8} spacing={6}>
          <Button
            className={classes.floatingButton}
            variant="contained"
            color="primary"
            disableElevation
            size="large"
            onClick={() => {
              openDialog();
            }}
            startIcon={<AddIcon />}
          >
            {formatMessage(messages.new)}
          </Button>
          <Typography variant="h4" gutterBottom>
            {formatMessage(messages.titleCSV)}
          </Typography>
          <ListView
            hasStatus={true}
            singleItemName={formatMessage(messages.titleCSV)}
            hasDateModified={true}
            canDelete={true}
            path={`${ROUTE_DATALAYERS}${formatMessage(messages.datalayersTable)}`}
            title={formatMessage(messages.titleCSV)}
            searchprompt={formatMessage(messages.titleCSV)}
            items={layers.map((layer) => ({
              id: layer.id,
              dateCreated: layer.dateCreated,
              user: layer.user,
              dateModified: layer.dateModified,
              name: layer.displayName,
              avatarUrl: layer.avatarUrl,
              status: getConvertionTaskStatusLabel(layer.status),
              statusColor: getConvertionTaskStatusColor(layer.status),
            }))}
            handleDelete={handleDelete}
          />
        </Grid>
        <Grid item xs={4}>
          {/* <Typography variant="subtitle2" gutterBottom>
            Lorem Ipsum
          </Typography>
          <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non libero vitae massa vulputate gravida quis non sapien.
            Morbi a faucibus sapien. Donec ullamcorper pulvinar eros ac consectetur. Integer accumsan sollicitudin mi, a rutrum tortor
            ultricies nec. Nam a velit scelerisque, tempus tellus a, tincidunt ex. Aenean nunc magna, accumsan a leo sit amet, tincidunt
            pretium orci. Phasellus finibus, lectus quis gravida sollicitudin, ligula nunc tempus dui, at consectetur quam sem sit amet
            neque. Ut egestas sollicitudin risus, ut congue felis faucibus consequat. Ut tempus suscipit nunc, non rhoncus ante gravida
            vitae. Aliquam accumsan, dui quis facilisis cursus, diam magna suscipit dui, sed rhoncus dui elit quis nunc. Integer eget
            gravida velit.
          </Typography> */}
        </Grid>
      </Grid>
      <CustomDialogView
        open={dialogOpen}
        handleClose={() => {
          clearFiles();
          closeDialog();
        }}
        fullScreenSize="xl"
        dialogTitle={formatMessage(messages.datalayersNewTableDatalayer)}
        dialogContent={
          <Fragment>
            {csvData.data.length === 0 && (
              <FileUploader
                accept={".csv"}
                onChange={(csvFiles: File[], groupedFiles: groupedFilesType, errors: errorsType) => {
                  setFiles(csvFiles);
                  setGroupedFiles(groupedFiles);
                }}
                multiple={false}
              />
            )}

            {csvData.data.length > 0 && <SimpleTable data={csvData.data} autoHeight={false} pagination pageSize={100} action={undefined} />}
          </Fragment>
        }
        dialogActions={
          <Fragment>
            <Button
              id="submit"
              onClick={() => {
                clearFiles();
                closeDialog();
              }}
            >
              {formatMessage(messages.cancel)}
            </Button>
            <Button
              id="submit"
              color="primary"
              variant="contained"
              onClick={() => {
                uploadFiles(csvData.meta);
                closeDialog();
              }}
              disableElevation
            >
              {formatMessage(messages.process)}
            </Button>
          </Fragment>
        }
      />
    </Fragment>
  );
});

export default withStyles(useDatasetsStyles)(injectIntl(DataTablelayers));
