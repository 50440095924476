import axios from "axios";
import { AuthType, LoginData, LogoutData, VerifyToken } from "@orbit/geo-core-shared";

const APP_ID = process.env.REACT_APP_ID;
const API_URL = window.env.API_URL;

let bearer: string = "";

export const getCurrentBearer = (): string => bearer;

export const login = async (username: string, password: string): Promise<LoginData | boolean> => {
  try {
    const {
      data: { data },
    } = await axios.post(API_URL + `login`, {
      email: username,
      appId: APP_ID,
      password,
    });
    const returnData = data as LoginData;
    linkTokenToAxios(returnData.token);
    return returnData;
  } catch (e) {
    console.log("Error: ", e.toString());
    return false;
  }
};

export const loginSSO = async ({ authType, sessionId }: { sessionId: string; authType: AuthType }): Promise<LoginData | boolean> => {
  try {
    const uninterceptedAxiosInstance = axios.create();
    const {
      data: { data },
    } = await uninterceptedAxiosInstance.get(`${API_URL}${authType === AuthType.ACMIDM ? "oauth2" : "oidc"}/${authType}/login/${APP_ID}/${sessionId}`);
    const returnData = data as LoginData;
    linkTokenToAxios(returnData.token);
    return returnData;
  } catch (e) {
    console.log("Error: ", e.toString());
    return false;
  }
};

export const validatetoken = async (token): Promise<VerifyToken> => {
  let {
    data: { data },
  } = await axios.get(API_URL + `verify/${token}`);
  const returnData = data as VerifyToken;
  if (returnData.verified) {
    linkTokenToAxios(token);
  }
  return returnData;
};

export const logout = async (): Promise<LogoutData> => {
  let {
    data: { data },
  } = await axios.post(API_URL + `logout`);
  linkTokenToAxios(null);
  return data as LogoutData;
};

export const linkTokenToAxios = (token: string | null) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  bearer = token || "";
};

export const setProfile = async (id) => {
  const {
    data: { data: { token, rolePermissions } },
  } = await axios.post(API_URL + "role", {
    roleId: id,
  });
  return { token: token, rolePermissions: rolePermissions[0] };
};
